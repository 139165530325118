import { Box, ListItemIcon, MenuItem, MenuList, Stack } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackConnectButton } from './SlackConnectButton';
import { SlackLogo, HubspotLogo } from '../../../icons/generalIcons';
import { ReactNode } from 'react';
import { HubspotConnectButton } from './HubspotConnectButton';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { CloudingoConnectButton } from './CloudingoConnectButton';

export const IntegrationsTab = () => {
  const { hubspotIntegration } = useFeatureToggle();
  return (
    <Stack gap="24px">
      <Typography variant="h2">Integrations</Typography>
      <MenuList
        sx={{
          '&.MuiList-root': {
            padding: 0,
          },
          '.MuiMenuItem-root': {
            padding: 2,
            gap: 2,
            borderBottom: `1px solid ${colors.grey[200]}`,
            margin: 0,
            minHeight: '94px',
          },
        }}
      >
        <IntegrationOption
          name="Slack"
          logo={<SlackLogo />}
          description="Add Salesforce Alerts to Monitor your GTM Playbooks"
          actionButton={<SlackConnectButton />}
        />

        {hubspotIntegration && (
          <IntegrationOption
            name="Hubspot"
            logo={<HubspotLogo />}
            description="Connect HubSpot to visualize funnels and manage campaign enrollments"
            actionButton={<HubspotConnectButton />}
          />
        )}
        <IntegrationOption
          name="Cloudingo"
          logo={
            <img
              src="https://asset.brandfetch.io/ido-ApkhlX/idpHL_OSQO.jpeg"
              width={40}
              height={40}
            />
          }
          description="Merge duplicates with Cloudingo"
          actionButton={<CloudingoConnectButton />}
        />
      </MenuList>
    </Stack>
  );
};

const IntegrationOption = ({
  name,
  logo,
  description,
  actionButton,
}: {
  name: string;
  logo: ReactNode;
  description: string;
  actionButton: ReactNode;
}) => {
  return (
    <MenuItem>
      <ListItemIcon>{logo}</ListItemIcon>
      <Box
        sx={{
          flex: '1',
          textWrap: 'wrap',
          gap: 0.5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4">{name}</Typography>
        <Typography variant="body" color={colors.grey[700]}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          '.MuiButton-root': {
            height: '32px', // TODO: Implement button tiny size
          },
        }}
      >
        {actionButton}
      </Box>
    </MenuItem>
  );
};
