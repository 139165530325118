import { Box, FormControl, FormControlLabel } from '@mui/material';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SlackFields } from './SlackFields';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import FieldLabel from '../../common/FieldLabel';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { Button, Checkbox, Typography, colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { SlackRecipientsSelect } from './SlackRecipientsSelect';
import { useSlackRecipients } from './useSlackRecipients';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { useGetSlackRecipientsLabels } from './useGetSlackRecipientsLabels';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { SlackConstant } from './utils';

interface SlackDealRoomActionProps {
  readonly?: boolean;
  action: SlackDealRoomAutomationAction;
  crmOrgId: string;
  onChange: (slackDealRoomAutomationAction: SlackDealRoomAutomationAction) => any;
  objectName: string;
}

export const SlackDealRoomAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackDealRoomActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);
  const { recipientWithLabelsArray } = useGetSlackRecipientsLabels({
    recipients: action.actionParams?.members ?? [],
  });
  const { storeChannelIdInSfField } = useFeatureToggle();
  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackDealRoomActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const setBeforeCallingFields = useCallback(() => {
    updateOnChange({
      fields: [...(action.actionParams?.fields || [])],
      channel: {
        ...action.actionParams?.channel,
        type: action.actionParams?.channel?.type ?? 'Public',
      },
    });
  }, [action.actionParams?.channel, action.actionParams?.fields, updateOnChange]);

  const { isLoading } = useSlackRecipients({
    objectName,
    crmOrgId,
    setBeforeCallingFields,
  });

  const handleDeleteChip = (chipToRemove: SlackRecipientStruct) => {
    const newRecipients = action.actionParams.members?.filter((item) => {
      if (chipToRemove.type === SlackConstant.Slack) {
        return item.value !== chipToRemove.value;
      } else {
        return !isEqual(
          (item.value as SlackFieldsStruct).fieldIds,
          (chipToRemove.value as SlackFieldsStruct).fieldIds,
        );
      }
    });
    updateOnChange({ members: newRecipients });
  };

  return (
    <>
      {(!productionOrg || slackStatus !== SlackConnectionStatus.Connected) && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected hasProduction={!!productionOrg} slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
          }}
        >
          <SlackNewMessageEditor
            placeholder="Set channel name. E.g., dealroom-{opportunity.name}"
            customOnKeyDown={(event) => {
              if (!(event.key.match(/[a-z0-9,]/) || event.key === '-' || event.key === '_')) {
                event.preventDefault();
              }
            }}
            infoTooltipTitle={
              'Channel names may only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less.'
            }
            hideEmoji={true}
            isRequired={true}
            label="Slack channel name"
            readonly={readonly}
            messageStruct={action.actionParams?.channel?.name}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({
                channel: {
                  ...action.actionParams?.channel,
                  name: newMsg,
                },
              });
            }}
            objectName={objectName}
          />

          <Box sx={{ mb: '20px', ml: '10px', mt: '-15px' }}>
            <FormControlLabel
              label={<Typography variant="caption">Make private</Typography>}
              control={
                <Checkbox
                  checked={action.actionParams?.channel?.type === 'Private'}
                  onChange={(value) => {
                    const isChecked = value.target.checked;
                    updateOnChange({
                      channel: {
                        ...action.actionParams?.channel,
                        type: isChecked ? 'Private' : 'Public',
                      },
                    });
                  }}
                />
              }
            />
          </Box>

          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
              maxWidth: '1148px',
            }}
          >
            <Box
              sx={{
                marginBottom: '3px',
                '& div': {
                  color: colors.grey[800],
                  fontSize: '12px',
                  fontWeight: '400',
                },
              }}
            >
              <FieldLabel label={'Slack channel members'} markLabelAsRequired={true} />
            </Box>

            <SlackRecipientsSelect
              customButtonText={'Add from Salesforce field'}
              objectName={objectName}
              crmOrgId={crmOrgId}
              isLoading={isLoading}
              onChange={(event, newValues) => {
                const newItems = newValues.map((el: any) => {
                  if (el.type === SlackConstant.Salesforce) {
                    return {
                      ...el,
                      type: SlackConstant.Salesforce,
                      value: {
                        fieldIds: el.value.fieldIds,
                      },
                    };
                  } else if (el.type === SlackConstant.Salesforce_Created_Slack_Channel) {
                    return {
                      ...el,
                      type: SlackConstant.Salesforce_Created_Slack_Channel,
                      value: {
                        fieldIds: el.value.fieldIds,
                      },
                    };
                  } else {
                    return {
                      ...el,
                      type: SlackConstant.Slack,
                      value: el.value,
                    };
                  }
                });
                updateOnChange({ members: newItems as SlackRecipientStruct[] });
              }}
              hideDisclaimer={true}
              readonly={!!readonly}
              recipientsNoChannels
              recipients={recipientWithLabelsArray}
              handleDeleteChip={handleDeleteChip}
            />
          </FormControl>

          <SlackNewMessageEditor
            placeholder="Type message body..."
            isRequired={false}
            label="Channel welcome message"
            readonly={readonly}
            messageStruct={action.actionParams?.message ?? { template: '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />

          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <FieldLabel label={'Additional fields'} />
            <SlackFields
              readonly={readonly}
              actionFields={action?.actionParams?.fields.map((f) => ({
                fieldIds: f.fieldIds,
                fieldData: f,
              }))}
              onChange={(_, fields) => {
                updateOnChange({ fields });
              }}
            />

            <Box>
              <Box>
                <NestedFieldsSelector
                  readonly={readonly}
                  crmOrgId={crmOrgId}
                  objectType={objectName}
                  customButtonStartIcon={<Plus color={colors.blue[500]} />}
                  useCustomButton
                  customButtonText="Add field"
                  nestedPath={[]}
                  customButtonSx={{
                    color: colors.blue[500],
                    border: 'none',
                    textTransform: 'unset',
                    marginTop: '6px',
                    p: '0',
                    '& span': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                    '&:hover': {
                      background: 'transparent',
                      color: colors.blue[600],
                    },
                  }}
                  onChange={(sweepField: NestedFieldsField) => {
                    const newItem = {
                      fieldIds: sweepField.fieldIds,
                      _fieldLabels: sweepField.fieldLabels,
                    };
                    const newFields = [
                      ...((action.actionParams?.fields as SlackFieldsStruct[]) || []),
                      newItem,
                    ];
                    updateOnChange({ fields: newFields });
                  }}
                />
              </Box>
            </Box>
          </FormControl>

          {storeChannelIdInSfField && (
            <Box>
              <Box sx={{ marginBottom: '3px' }}>
                <FieldLabel label={'Save Slack channel ID on the triggering record (Optional)'} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box
                  sx={{
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '4px',
                    background: colors.white,
                    maxWidth: '500px',
                    width: 'fit-content',
                  }}
                >
                  <NestedFieldsSelector
                    filterBy={(field: SweepField) => field?.fieldType === SweepFieldTypes.Text}
                    disableLookupItemsResolve
                    readonly={readonly}
                    crmOrgId={crmOrgId}
                    objectType={objectName}
                    placeholder="Choose field"
                    nestedPath={getEnrichedNestedPath(
                      action?.actionParams?.channelIdField?.fieldIds,
                    )}
                    onChange={(sweepField: NestedFieldsField) => {
                      const newItem = {
                        fieldIds: sweepField.fieldIds,
                      };
                      updateOnChange({ channelIdField: newItem });
                    }}
                  />
                </Box>
                {action?.actionParams?.channelIdField && (
                  <Button
                    onClick={() => {
                      updateOnChange({ channelIdField: undefined });
                    }}
                    variant="link"
                  >
                    Clear
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
