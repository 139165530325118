import { Tag, colors } from '@sweep-io/sweep-design';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutoTemplatesStruct, automationTemplates } from '../../constants/automationTemplates';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import {
  PageTemplateActionButton,
  PageTemplateCard,
  PageTemplateGrid,
  PageTemplateHeaderWrapper,
  getColorForTemplateCard,
} from '../pages/pages-templates';

interface AutomationsTemplatesListProps {
  crmOrgId: string;
  automationVariant: AutomationFormVariant;
  onSelectTemplate: (item: AutoTemplatesStruct) => void;
  createEmptyButton: JSX.Element;
}

export const AutomationsTemplatesList = ({
  automationVariant,
  crmOrgId,
  createEmptyButton,
  onSelectTemplate,
}: AutomationsTemplatesListProps) => {
  const listOfTemplates = automationTemplates[automationVariant];
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  return (
    <PageTemplateGrid>
      <PageTemplateCard
        stripeColor1={colors.grey[200]}
        actionsContent={createEmptyButton}
        description="Start from scratch"
        headerContent={<Tag label="Any object" color={colors.grey[200]} />}
        onCardClick={() => {
          (window as any).document.querySelector('#add-automation-button-wrapper button')?.click();
        }}
      />

      {listOfTemplates.map((item: AutoTemplatesStruct) => (
        <PageTemplateCard
          key={item.automation.name}
          stripeColor1={getColorForTemplateCard(item.automation.objectName)}
          actionsContent={
            <PageTemplateActionButton onClick={() => onSelectTemplate(item)}>
              Use template
            </PageTemplateActionButton>
          }
          description={item.automation.name || ''}
          headerContent={
            <PageTemplateHeaderWrapper
              firstItem={
                item.automation.objectName && (
                  <ObjectTypeChip
                    label={
                      objectTypesByName[item.automation.objectName]?.label ||
                      item.automation.objectName
                    }
                    objectType={item.automation.objectName}
                  />
                )
              }
              secondItem={item.icon}
            />
          }
          onCardClick={() => onSelectTemplate(item)}
        />
      ))}
    </PageTemplateGrid>
  );
};
