import { ChangeEvent } from 'react';
import { SlidersVertical } from '@sweep-io/sweep-design/dist/icons';
import { SearchInput } from '../../common/SearchInput';
import { SingleSelectFilter } from '../../common/single-select-filter/SingleSelectFilter';
import { SingleSelectFilterPopoverOption } from '../../common/single-select-filter/SingleSelectFilterPopover';
import { OPTION_ALL } from '../../common/single-select-filter/utils';
import { ConfigurationType } from '../dependencies/types';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import Box from '@mui/material/Box/Box';
import { useDispatch } from 'react-redux';
import { setUniversalSearchListIsOpen } from '../../../reducers/documentationReducer';

interface UniversalSearchInputProps {
  searchTxt: string;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClearButtonClick: () => void;
  filterOptions: SingleSelectFilterPopoverOption[];
  onSelectedFilterItem: (key: ConfigurationType) => void;
  selectedFilterValue?: string;
  isUniversalSearchListOpen?: boolean;
  menuOptions?: SingleSelectFilterPopoverOption[];
  selectedMenuOption?: string;
  onSelectMenuOption?: (selectedValue: string) => void;
}

export const UniversalSearchInput = ({
  onKeyDown,
  searchTxt,
  onChange,
  onClearButtonClick,
  filterOptions,
  menuOptions,
  selectedMenuOption,
  onSelectMenuOption,
  selectedFilterValue = OPTION_ALL.value,
  onSelectedFilterItem,
  isUniversalSearchListOpen,
}: UniversalSearchInputProps) => {
  const dispatch = useDispatch();
  const { isExpandedMode } = useExpandedMode();
  return (
    <Box maxWidth={isExpandedMode ? '348px' : '100%'}>
      <SearchInput
        onClearButtonClick={onClearButtonClick}
        endAdornment={
          isUniversalSearchListOpen && (
            <SingleSelectFilter
              options={filterOptions}
              selectedItemValue={selectedFilterValue}
              onSelectItem={(key) => onSelectedFilterItem(key as ConfigurationType)}
              icon={<SlidersVertical />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              menuOptions={menuOptions}
              selectedMenuOption={selectedMenuOption}
              onSelectMenuOption={onSelectMenuOption}
            />
          )
        }
        withFixedMagnifyingGlassIcon
        TextFieldProps={{
          onChange,
          value: searchTxt,
          placeholder: 'Search',
          onKeyDown,
          onFocus: () => dispatch(setUniversalSearchListIsOpen({ isOpen: true })),
        }}
        forceCloseButton={isUniversalSearchListOpen}
      />
    </Box>
  );
};
