import { useSelector } from 'react-redux';
import {
  selectParsedConfigurationItems,
  selectDependencyByType,
} from '../../../../reducers/documentationReducer';
import { selectParsedRules } from '../../../../reducers/parserReducer';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';
import { generateFieldId, ID_NAME_SEPARATOR } from '../../universal-search/utils';
import { ConfigurationType } from '../types';

export const useGetParsedDepedencies = ({
  configurationItemId,
  crmOrgId,
  type,
  name,
  objectName,
  isSupportedType,
}: {
  configurationItemId: string;
  crmOrgId: string;
  type: 'dependsOnIdsForRules' | 'usedByIdsForRules' | 'usedByIdsForFields';
  name: string;
  objectName: string;
  isSupportedType: boolean;
}) => {
  const _fieldId = generateFieldId({ id: configurationItemId, name, objectName });
  const configs = useSelector(selectParsedConfigurationItems(crmOrgId));
  const parsedRules = useSelector(selectParsedRules);

  const id = type === 'usedByIdsForFields' ? _fieldId : configurationItemId ?? '';
  const rulesIds = useSelector(selectDependencyByType(type))?.[crmOrgId]?.[id];

  if (!rulesIds || !isSupportedType) {
    return {} as ConfigurationItemMap;
  }

  const newDep = {} as ConfigurationItemMap;

  Object.keys(rulesIds).forEach((key) => {
    const configKey = key as ConfigurationType;
    const items = rulesIds[configKey];

    const newConfigItems = items.map((id) => {
      const [_id, _name] = id.split(ID_NAME_SEPARATOR);

      const ruleIdx =
        parsedRules?.findIndex((rule) => rule.id === _id && (_name ? rule.name === _name : true)) ??
        -1;
      if (ruleIdx !== -1 && parsedRules) return parsedRules[ruleIdx];

      const configItemWithIdAndNameIdx =
        configs?.findIndex(
          (config) => config.id === _id && (_name ? config.name === _name : true),
        ) ?? -1;

      if (configItemWithIdAndNameIdx !== -1) {
        return configs[configItemWithIdAndNameIdx];
      }
    });

    const withFilteredUndefined = newConfigItems.filter((item) => !!item);

    // it's a TS issue https://github.com/microsoft/TypeScript/issues/45097
    // @ts-ignore
    newDep[configKey] = withFilteredUndefined;
  });

  return newDep;
};
