import { useCallback } from 'react';
import { Tag, useUserInputsApiFacade } from '../../../../../apis/facades/useUserInputsApiFacade';
import { useDispatch } from 'react-redux';
import { setTagsList, addTag } from '../../../../../reducers/tagsReducer';
import { telemetry } from '../../../../../telemetry';

export const useTags = () => {
  const dispatch = useDispatch();
  const { get_tags, post_tag } = useUserInputsApiFacade();

  const fetchTags = useCallback(async () => {
    try {
      const tags = await get_tags();
      dispatch(setTagsList({ tags }));
    } catch (error) {
      telemetry.captureError(error);
    }
  }, [get_tags, dispatch]);

  const onAddTag = useCallback(
    async (tag: Omit<Tag, 'id'>) => {
      try {
        const tagId = await post_tag(tag);
        dispatch(addTag({ tag: { ...tag, id: tagId } }));
        return tagId;
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [post_tag, dispatch],
  );

  return { fetchTags, onAddTag };
};
