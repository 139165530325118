import { RoleGroupType, RoleLevel } from '@server/role-group-interface';

export const mockedSystemRoles = [
  {
    id: '1',
    name: 'Admin',
    type: RoleGroupType.SYSTEM,
    roles: {
      automations: RoleLevel.DEPLOY,
      assignments: RoleLevel.DEPLOY,
      rollups: RoleLevel.DEPLOY,
      dedupMatching: RoleLevel.DEPLOY,
      funnels: RoleLevel.DEPLOY,
      alerts: RoleLevel.DEPLOY,
    },
    description: 'Has full access. Can make edits and deployments within the entire system.',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdById: 'abs',
  },
  {
    id: '2',
    name: 'Alert Manager',
    type: RoleGroupType.SYSTEM,
    roles: {
      automations: RoleLevel.VIEWER,
      assignments: RoleLevel.VIEWER,
      rollups: RoleLevel.VIEWER,
      dedupMatching: RoleLevel.VIEWER,
      funnels: RoleLevel.VIEWER,
      alerts: RoleLevel.DEPLOY,
    },
    description:
      'Full access to alerts, including edits and deployments. Can view other parts of the system.',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdById: 'abs',
  },
  {
    id: '3',
    name: 'Collaborator',
    type: RoleGroupType.SYSTEM,
    roles: {
      automations: RoleLevel.REQUEST_TO_DEPLOY,
      assignments: RoleLevel.REQUEST_TO_DEPLOY,
      rollups: RoleLevel.REQUEST_TO_DEPLOY,
      dedupMatching: RoleLevel.REQUEST_TO_DEPLOY,
      funnels: RoleLevel.REQUEST_TO_DEPLOY,
      alerts: RoleLevel.REQUEST_TO_DEPLOY,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    createdById: 'abs',
    description: 'Can view, edit and save. Can request to deploy any element.',
  },
  {
    id: '4',
    name: 'Viewer',
    type: RoleGroupType.SYSTEM,
    roles: {
      automations: RoleLevel.VIEWER,
      assignments: RoleLevel.VIEWER,
      rollups: RoleLevel.VIEWER,
      dedupMatching: RoleLevel.VIEWER,
      funnels: RoleLevel.VIEWER,
      alerts: RoleLevel.VIEWER,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    createdById: 'abs',
    description:
      'Full access to alerts, including edits and deployments. Can view other parts of the system.',
  },
];
