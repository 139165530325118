import { useEffect, useRef, useState } from 'react';
import { Box, Fade, ListItemButton, MenuList, Popover, Stack } from '@mui/material';
import keyBy from 'lodash/keyBy';
import { Button, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { TagItem, TextFieldWithTags } from '../TextFieldWithTags';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';
import { dataTableVariants } from '../table/dataTableVariants';
import { DataTableVariant } from '../table/TableTypes';
import { CreateButton } from './CreateButton';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../../constants';
import { EmojiType } from '../../avatar/avatarTypes';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';

export interface InlineAutocompleteItem<T = any> {
  value: string;
  label: string;
  labelDecorator?: string;
  emoji?: EmojiType;
  imageUrl?: string;
  tagColor?: string;
  data?: T;
}

export type InlineAutocompleteVariants = 'tags' | 'users';

interface InlineAutocompleteProps<T> {
  items: InlineAutocompleteItem<T>[];
  selectedItemValues: string[];
  onSelectItem?: (item: InlineAutocompleteItem<T>) => void;
  onDeleteItem?: (item: InlineAutocompleteItem<T>) => void;
  onCreate?: (label: string) => void;
  placeholder?: string;
  createInProgress?: boolean;
  closeOnItemSelection?: boolean;
  emptyStateTxt?: string;
  withEmptyStateButtonTxt?: string;
  headerTxt: string;
  variant?: InlineAutocompleteVariants;
  inputTypographyVariant?: SweepTypographyVariants;
  clearSearchTextOnSelection?: boolean;
}

const getItemLabelWitLabelDecorator = (item: InlineAutocompleteItem) => {
  return `${item.labelDecorator ? item.labelDecorator + ' ' : ''}${item.label}`;
};

export function InlineAutocomplete<T = any>({
  items,
  selectedItemValues,
  onSelectItem,
  onDeleteItem,
  placeholder = 'Search',
  onCreate,
  createInProgress,
  closeOnItemSelection,
  emptyStateTxt,
  withEmptyStateButtonTxt,
  headerTxt,
  variant = 'tags',
  inputTypographyVariant,
  clearSearchTextOnSelection,
}: InlineAutocompleteProps<T>) {
  const [currentSearch, setCurrentSearch] = useState('');
  const itemsByKey = keyBy(items, 'value');

  const [isOpen, setIsOpen] = useState(false);
  const inlineTextFieldRef = useRef<HTMLDivElement>(null);

  const nonSelectedItems = items.filter((item) => !selectedItemValues.includes(item.value));

  const filteredItems = filterItemsBySearch(nonSelectedItems, currentSearch, 'label');
  const currentSearchHasExactMatch = items.some((item) => item.label === currentSearch);

  useEffect(() => {
    setCurrentSearch('');
  }, [isOpen]);

  useEffect(() => {
    if (!selectedItemValues.length) {
      setIsOpen(false);
    }
  }, [selectedItemValues.length]);

  const tags: TagItem[] = selectedItemValues.map((value) => ({
    value,
    label: getItemLabelWitLabelDecorator(itemsByKey[value]),
    onDelete: () => {
      onDeleteItem && onDeleteItem(itemsByKey[value]);
    },
    color: itemsByKey[value].tagColor,
  }));

  if (createInProgress) {
    tags.push({
      value: 'create-in-progress',
      label: 'Creating...',
      isLoader: true,
    });
  }

  return (
    <Box>
      {selectedItemValues.length === 0 && Boolean(withEmptyStateButtonTxt) && (
        <Box padding="0 16px" display="flex" gap={1} alignItems="center" ref={inlineTextFieldRef}>
          <Typography
            variant={dataTableVariants[DataTableVariant.default].fontVariant}
            color={colors.grey[500]}
            whiteSpace="nowrap"
          >
            {emptyStateTxt}
          </Typography>
          <Box className="add-to-group" sx={{ '& .MuiButton-root': { whiteSpace: 'nowrap' } }}>
            <Button variant="link" onClick={() => setIsOpen(true)}>
              {withEmptyStateButtonTxt}
            </Button>
          </Box>
        </Box>
      )}

      {(selectedItemValues.length > 0 ? true : !Boolean(withEmptyStateButtonTxt)) && (
        <TextFieldWithTags
          typographyVariant={inputTypographyVariant}
          placeholder={placeholder}
          ref={inlineTextFieldRef}
          tags={tags}
          hideDeleteButton
          readonly={isOpen || createInProgress}
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{
            width: '100%',
            opacity: isOpen ? 0 : 1,
            transition: isOpen ? 'opacity 2s' : '',
            input: {
              display: selectedItemValues.length ? 'none' : undefined,
            },
            '& .MuiInputBase-adornedStart': {
              paddingRight: selectedItemValues.length ? '12px' : '',
            },
            '& .MuiInputBase-root': {
              background: 'transparent',
              '&, &.Mui-disabled': {
                backgroundColor: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
              },

              '&:hover, &.Mui-focused': {
                background: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[300],
                },
              },
            },
          }}
        />
      )}

      <Popover
        open={isOpen}
        anchorEl={inlineTextFieldRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '-6px',
              width: '300px',
            },
          },
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
      >
        <Box padding={1}>
          <Box display="flex" flexDirection="column">
            <TextFieldWithTags
              value={currentSearch}
              tags={tags}
              onChange={(e) => {
                setCurrentSearch(e.target.value);
              }}
              autoFocus
              typographyVariant={inputTypographyVariant}
            />
            <Box p={1.5} pr={0.5} pl={0.5}>
              <Typography variant="caption-bold" color={colors.grey[700]}>
                {headerTxt}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <MenuList dense sx={{ p: 0 }}>
              {filteredItems.map((item) => (
                <ListItemButton
                  key={item.value}
                  onClick={() => {
                    closeOnItemSelection && setIsOpen(false);
                    onSelectItem && onSelectItem(item);
                    clearSearchTextOnSelection && setCurrentSearch('');
                  }}
                  sx={{ height: '40px', pl: 0.5 }}
                >
                  {variant === 'users' ? (
                    <Stack direction="row" alignItems="center" gap={1} width="100%">
                      <AvatarPicture
                        avatar={{ emoji: item?.emoji, imageUrl: item?.imageUrl }}
                        dimension={DIMENSION_AVATAR_VERY_SMALL}
                        isInactive={false}
                      />
                      <Typography variant="body">{item.label}</Typography>
                    </Stack>
                  ) : (
                    <Tag label={getItemLabelWitLabelDecorator(item)} color={item.tagColor} />
                  )}
                </ListItemButton>
              ))}
              {!filteredItems.length && (
                <Box p={1} pt={0}>
                  <Typography variant="caption" color={colors.grey[700]}>
                    No results found
                  </Typography>
                </Box>
              )}
            </MenuList>

            {currentSearch && !currentSearchHasExactMatch && variant !== 'users' && (
              <CreateButton
                onCreate={onCreate}
                currentSearch={currentSearch}
                setIsOpen={setIsOpen}
              />
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
