import { Box, FormControl } from '@mui/material';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SlackFields } from './SlackFields';
import { useCallback } from 'react';
import FieldLabel from '../../common/FieldLabel';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { useRunOnce } from '../../common/useRunOnce';
import { SlackConstant } from './utils';
import { SlackSelectDealroomType } from './SlackSelectDealroomType';

interface SlackRenameDealroomActionProps {
  readonly?: boolean;
  action: SlackRenameDealroomAutomationAction;
  crmOrgId: string;
  onChange: (SlackRenameDealroomAutomationAction: SlackRenameDealroomAutomationAction) => any;
  objectName: string;
}

export const SlackRenameDealroomAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackRenameDealroomActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);

  useRunOnce(() => {
    updateOnChange({
      fields: [...(action.actionParams?.fields || [])],
      channelRef: action.actionParams?.channelRef
        ? { ...action.actionParams?.channelRef }
        : {
            type: SlackConstant.Sweep_Created_Slack_Channel,
          },
    });
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackRenameDealroomActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  return (
    <>
      {(!productionOrg || slackStatus !== SlackConnectionStatus.Connected) && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected hasProduction={!!productionOrg} slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
          }}
        >
          <SlackSelectDealroomType
            readonly={readonly}
            value={action?.actionParams?.channelRef}
            crmOrgId={crmOrgId}
            objectName={objectName}
            onChange={(val) => {
              updateOnChange({ channelRef: val });
            }}
          />

          <SlackNewMessageEditor
            placeholder="Set channel name. E.g., dealroom-{opportunity.name}"
            customOnKeyDown={(event) => {
              if (!(event.key.match(/[a-z0-9,]/) || event.key === '-' || event.key === '_')) {
                event.preventDefault();
              }
            }}
            infoTooltipTitle={
              'Channel names may only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less.'
            }
            hideEmoji={true}
            isRequired={true}
            label="New Slack channel name"
            readonly={readonly}
            messageStruct={action.actionParams?.name}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ name: newMsg });
            }}
            objectName={objectName}
          />

          <SlackNewMessageEditor
            placeholder="Type message body..."
            isRequired={false}
            label="Message (optional)"
            readonly={readonly}
            messageStruct={action.actionParams?.message ?? { template: '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />

          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <FieldLabel label={'Additional fields'} />
            <SlackFields
              readonly={readonly}
              actionFields={action?.actionParams?.fields?.map((f) => ({
                fieldIds: f.fieldIds,
                fieldData: f,
              }))}
              onChange={(_, fields) => {
                updateOnChange({ fields });
              }}
            />

            <Box>
              <Box>
                <NestedFieldsSelector
                  readonly={readonly}
                  crmOrgId={crmOrgId}
                  objectType={objectName}
                  customButtonStartIcon={<Plus color={colors.blue[500]} />}
                  useCustomButton
                  customButtonText="Add field"
                  nestedPath={[]}
                  customButtonSx={{
                    color: colors.blue[500],
                    border: 'none',
                    textTransform: 'unset',
                    marginTop: '6px',
                    p: '0',
                    '& span': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                    '&:hover': {
                      background: 'transparent',
                      color: colors.blue[600],
                    },
                  }}
                  onChange={(sweepField: NestedFieldsField) => {
                    const newItem = {
                      fieldIds: sweepField.fieldIds,
                      _fieldLabels: sweepField.fieldLabels,
                    };
                    const newFields = [
                      ...((action.actionParams?.fields as SlackFieldsStruct[]) || []),
                      newItem,
                    ];
                    updateOnChange({ fields: newFields });
                  }}
                />
              </Box>
            </Box>
          </FormControl>

          <Typography variant="caption-bold">
            Note: this automation would work as expected only if the record has a dedicated deal
            room
          </Typography>
        </Box>
      )}
    </>
  );
};
