import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { SlackSelectDealroomType } from './SlackSelectDealroomType';
import { useRunOnce } from '../../common/useRunOnce';
import { SlackConstant } from './utils';

interface SlackArchiveDealroomActionProps {
  readonly?: boolean;
  action: SlackArchiveDealroomAutomationAction;
  crmOrgId: string;
  onChange: (slackDealRoomAutomationAction: SlackArchiveDealroomAutomationAction) => any;
  objectName: string;
}

export const SlackArchiveDealroomAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackArchiveDealroomActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);

  useRunOnce(() => {
    updateOnChange({
      channelRef: action.actionParams?.channelRef
        ? { ...action.actionParams?.channelRef }
        : {
            type: SlackConstant.Sweep_Created_Slack_Channel,
          },
    });
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackArchiveDealroomActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  return (
    <>
      {(!productionOrg || slackStatus !== SlackConnectionStatus.Connected) && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected hasProduction={!!productionOrg} slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
          }}
        >
          <SlackSelectDealroomType
            readonly={readonly}
            value={action?.actionParams?.channelRef}
            crmOrgId={crmOrgId}
            objectName={objectName}
            onChange={(val) => {
              updateOnChange({ channelRef: val });
            }}
          />
        </Box>
      )}
    </>
  );
};
