import uniq from 'lodash/uniq';
import { sortLexicographically } from '../../lib/sortLexicographically';
import {
  ParsedRuleWithParentType,
  RecordTypesLabelsByNameAndObjectName,
  SelectConfigurationItemProps,
} from './types';
import { DocumentationTabTypes } from '../../types/enums/DocumentationTabTypes';
import { LayoutData, ParsedRecordType, ParsedRule } from './ParserTypes';
import {
  USED_UNIQUE_BY_RT,
  getDataFromRecordTypeIdWithLeadingFieldName,
  getRulesByTab,
  ruleByObjectName,
} from '../parser/parserUtils';
import flatten from 'lodash/flatten';
import { ConfigurationType } from './dependencies/types';
import { getRecordTypeLabelsFromAutomation, getStepsFromAutomation } from '../Automations/helper';
import { getDataFromSFFieldName } from '../pages/rollups/rollupHelpers';
import { createStepId } from './selected-object/filters/utils';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { colors } from '@sweep-io/sweep-design';

export const getObjectApiNamesFromFunnelMap = (funnelsData?: FunnelsData) => {
  const funnels = Object.values(funnelsData ?? {});

  return uniq(
    sortLexicographically({
      items: funnels?.map((funnel) => funnel.recordType?.objectName) ?? [],
    }),
  );
};

export const getObjectApiNamesFromRecordTypes = (recordTypesData?: RecordTypesData) => {
  return flatten(Object.keys(recordTypesData ?? {}).map((key) => key.split('.')[0]));
};

export const getObjectRules = ({
  objectName,
  tab,
  parsedRules,
}: {
  objectName: string;
  tab: DocumentationTabTypes;
  parsedRules: ParsedRuleWithParentType[] | ParsedRule[]; //temporary two types
}) => {
  switch (tab) {
    case DocumentationTabTypes.FIELDS:
      return [];

    case DocumentationTabTypes.VALIDATION_RULES:
    case DocumentationTabTypes.APEX:
    case DocumentationTabTypes.SF_AUTOMATIONS:
    case DocumentationTabTypes.CPQ_DATA:
    case DocumentationTabTypes.LAYOUTS:
      return getRulesByTab(parsedRules, tab).filter(
        ruleByObjectName(objectName),
      ) as ParsedRuleWithParentType[];
    default:
      return [];
  }
};

export const OCCUPIED_SPACE_SINGLE_OBJECT_VIEW = '210px';
export const OCCUPIED_SPACE_MAIN_VIEW = '200px';
export const OCCUPIED_SPACE_SINGLE_OBJECT_VIEW_CANVAS = '340px';

export const createConfigurationItemFromSweepField = (
  sweepField: SweepFieldSummary,
): ConfigurationItem => ({
  id: sweepField.id ?? '',
  name: getDataFromSFFieldName(sweepField?.sfFieldName)?.[1] ?? '',
  label: sweepField.sweepFieldName ?? '',
  objectName: sweepField.objectName,
  type: sweepField.fieldType,
  filename: '',
});

export const createConfigurationItemFromRollupField = (
  id: string,
  sweepField: SweepField,
  usage?: number,
): ConfigurationItem => ({
  id,
  name: getDataFromSFFieldName(sweepField?.sfFieldName)?.[1] ?? '',
  label: sweepField.sweepFieldName ?? '',
  objectName: sweepField.objectName,
  type: sweepField.fieldType,
  description: sweepField.description,
  annotations: {
    changedBy: sweepField.updatedBy,
    changedAt: sweepField.updatedAt,
    createdAt: sweepField.createdAt,
    createdBy: sweepField.createdBy,
  },
  helpText: sweepField.helpText,
  isMandatory: sweepField.isRequired,
  formula: sweepField.properties.formula,
  usage,
  filename: '',
});

export const createConfigurationItemFromAutomation = (
  automation?: AutomationStructureNew,
): ConfigurationItem | undefined => {
  const stagesNames = automation
    ? getStepsFromAutomation(automation.automationDetails, 'stageName')
    : [];
  const recordTypeNames = getRecordTypeLabelsFromAutomation(automation);

  return automation
    ? {
        id: automation.automationId,
        name: automation.name,
        label: automation.name,
        annotations: {
          changedBy: automation.updatedById,
          changedAt: automation.updatedAt,
        },
        objectName: automation.objectName,
        type: automation.type,
        stagesNames,
        usedOnlyByRecordType: recordTypeNames,
        filename: '',
      }
    : undefined;
};

export const createConfigurationItemFromRecordType = (
  recordType?: ParsedRecordType,
): ConfigurationItem | undefined => {
  return recordType
    ? {
        id: recordType.objectApiName + '.' + recordType.name,
        name: recordType.name,
        label: recordType.label,
        annotations: {
          changedBy: recordType.annotations?.changedBy,
          changedAt: recordType.annotations?.changedAt,
        },
        objectName: recordType.objectApiName,
        type: ConfigurationType.recordTypes,
        link: recordType.link,
        filename: '',
      }
    : undefined;
};

export const findRuleIdx = ({
  rules,
  searchByName,
  name,
  objectName,
  id,
}: { rules: ParsedRule[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId'
>) =>
  rules?.findIndex((rule) =>
    searchByName
      ? rule.name === name && (objectName ? rule.objectApiNames.includes(objectName) : true)
      : rule.id === id &&
        rule.name === name &&
        (objectName ? rule.objectApiNames.includes(objectName) : true),
  ) ?? -1;

export const findLayoutIdx = ({
  layouts,
  searchByName,
  name,
  id,
}: { layouts: LayoutData[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId' | 'objectName'
>) =>
  layouts?.findIndex((layout) =>
    searchByName ? layout.name === name : layout.id === id && layout.name === name,
  ) ?? -1;

export const findConfigurationItemIdx = ({
  parsedConfigurationItems,
  searchByName,
  name,
  objectName,
  id,
}: { parsedConfigurationItems: ConfigurationItem[]; searchByName: boolean } & Omit<
  SelectConfigurationItemProps,
  'crmOrgId'
>) =>
  parsedConfigurationItems?.findIndex((configItem) => {
    let isFound: boolean;

    const isNameEqual = configItem.name === name;
    const isIdEqual = configItem.id === id;
    const isObjectNameEqual = objectName ? configItem.objectName === objectName : true;

    if (searchByName) {
      isFound = isNameEqual && isObjectNameEqual;
    } else {
      isFound = isIdEqual && isNameEqual && isObjectNameEqual; //BE returns 'MissingInternalId' as id when its missing from Salto so we need to be more specific
    }

    return isFound;
  }) ?? -1;

export const addLayoutProperties = (item: ConfigurationItem, layout?: LayoutData) => ({
  ...item,
  buttons: layout?.buttons ?? item.buttons,
  annotations: layout?.annotations ?? item.annotations,
  relatedLists: layout?.relatedLists ?? item.relatedLists,
  description: layout?.description ?? item.description,
  link: layout?.link ?? item.link,
});

export const getConfigurationItemWithRTAttribution = (
  item: ParsedRule,
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName,
): ConfigurationItem => ({
  ...item,
  usedOnlyByRecordType: item.usedByRecordTypes
    ?.filter((name) => name.match(USED_UNIQUE_BY_RT))
    .map((name) => getDataFromRecordTypeIdWithLeadingFieldName(name)?.recordTypeName)
    .filter((n) => n !== ''),

  usedOnlyByRecordTypeLabels: item.usedByRecordTypes
    ?.filter((name) => name.match(USED_UNIQUE_BY_RT))
    .map((name) => {
      const { recordTypeName, objectApiName } =
        getDataFromRecordTypeIdWithLeadingFieldName(name) ?? {};
      const label = recordTypeName
        ? recordTypeNamesUsedInCanvas?.[objectApiName]?.[recordTypeName]
        : '';
      return label;
    })
    .filter((n) => n !== ''),
  filename: '',
});

export const getFunnelsStepNames = ({
  funnelsData = {},
  recordTypesData = {},
}: {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
}) => {
  const stepNamesPerObject: { [objectApiName: string]: string[] } = {};

  Object.values(funnelsData).forEach((funnel) => {
    const objectApiName = funnel.funnelDetails.leadingObject.objectName;
    const recordTypeName = funnel.recordType?.name;
    const stepNames = funnel.funnelDetails.stages.map((stage) =>
      createStepId(recordTypeName, objectApiName, stage.stageName),
    );
    const oldSteps = stepNamesPerObject[objectApiName] ?? [];

    stepNamesPerObject[objectApiName] = uniq([...stepNames, ...oldSteps]);
  });

  Object.values(recordTypesData).forEach((rt) => {
    const objectApiName = rt.objectName;
    const rtStepNames =
      rt.leadingField?.values.map((step) => createStepId(rt?.name, objectApiName, step.fullName)) ??
      [];
    const oldSteps = stepNamesPerObject[objectApiName] ?? [];

    stepNamesPerObject[objectApiName] = uniq([...rtStepNames, ...oldSteps]);
  });

  return stepNamesPerObject;
};

export const getObjectNameColor = (objectName: string) => {
  const leadingColor = getObjectTypeColor(objectName)?.leadingColorName as keyof typeof colors;
  return (colors[leadingColor] as any)?.[100] ?? colors['blue'][100];
};
