import { BaseDialog } from '../../../common/dialogs/BaseDialog';
import { Box, Stack } from '@mui/material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import PaywallFunnelDeployImage from './paywallFunnelDeploy.svg?react';
import usePaywall from '../../../dashboard/paywall/usePaywall';
import { PaywallSource } from '../../../dashboard/paywall/paywallTypes';

const PaywallFunnelDeploy = ({ closeDialog }: { closeDialog: () => void }) => {
  const { ctaCallback } = usePaywall();

  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          display: 'flex',
          height: '311px',
          maxWidth: '745px',
          overflow: 'hidden',
          borderRadius: '20px',
        },
      }}
    >
      <Box sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}>
        <IconButton variant="flat" size="large" onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" height="100%" flex={1}>
        <Box sx={{ height: '100%', flex: '0 0 300px', overflow: 'hidden' }}>
          <PaywallFunnelDeployImage />
        </Box>
        <Stack flex={2} p={'36px 24px 24px 36px'} height="100%" justifyContent="space-between">
          <Stack
            gap={1}
            sx={{
              '.MuiTypography-h1-bold': {
                lineHeight: '32px',
              },
              '.MuiTypography-body': {
                lineHeight: '24px',
              },
            }}
          >
            <Box maxWidth="70%">
              <Typography variant="h1-bold" color={colors.night[900]}>
                Ready to deploy directly to Salesforce?
              </Typography>
            </Box>
            <Box maxWidth="85%">
              <Typography variant="body" color={colors.grey[900]}>
                With Sweep, you can deploy end-to-end business processes to any Salesforce
                environment in a click. Talk to a Sweep team member to activate your free trial.
              </Typography>
            </Box>
          </Stack>
          <Box alignSelf="flex-end">
            <Button onClick={() => ctaCallback(PaywallSource.FUNNEL_DEPLOY)}>Talk to us</Button>
          </Box>
        </Stack>
      </Box>
    </BaseDialog>
  );
};

export default PaywallFunnelDeploy;
