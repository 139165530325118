import useUserInfo from '../../../hooks/useUserInfo';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { AccountType } from '@server/account-type';
import { DateTime } from 'luxon';
import usePermission from '../../common/permissions/usePermission';
import { openIntercom, scheduleCall } from '../customerSupportHelper';
import { useCallback } from 'react';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { PAYWALL_EVENTS } from '../../../services/events';
import { useCookies } from 'react-cookie';
import { ACTIVATE_TRIAL_COOKIE_NAME } from '../paywall/paywallConsts';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { PaywallSource, PaywallBlockProps, PaywallState } from '../paywall/paywallTypes';

export enum PaywallAction {
  UPGRADE = 'UPGRADE', //NOT relevant for the "sales activated trial" feature
  START_TRIAL = 'START_TRIAL', //NOT relevant for the "sales activated trial" feature
  NONE = 'NONE',
  TALK_TO_SALES = 'TALK_TO_SALES', //relevant ONLY for the "sales activated trial" feature
}

const INTERCOM_MESSAGE = `Hi! I'm interested in learning more about upgrade options`;

const ACTIVE_PAYWALL_STATES = [PaywallState.TRIAL_EXPIRED, PaywallState.PRE_TRIAL];
const ONLY_UPGRADE_STATES = [PaywallState.TRIAL_EXPIRED, PaywallState.IN_TRIAL];

const getPaywallState = (type?: AccountType, trialEndDate?: string) => {
  switch (type) {
    case AccountType.Customer:
    case AccountType.Internal:
    case AccountType.POC:
      return PaywallState.CUSTOMER;
    case AccountType.Inactive:
      return PaywallState.TRIAL_EXPIRED;
    case AccountType.Free:
      if (!trialEndDate) {
        return PaywallState.PRE_TRIAL;
      }
      const trialEnd = DateTime.fromISO(trialEndDate);
      const now = DateTime.fromISO(new Date().toISOString());
      if (trialEnd >= now) {
        return PaywallState.IN_TRIAL;
      } else {
        return PaywallState.TRIAL_EXPIRED;
      }
    default:
      return PaywallState.TRIAL_EXPIRED;
  }
};

const calcDaysLeft = (trialEndDate: string) => {
  const trialEnd = DateTime.fromISO(trialEndDate);
  const now = DateTime.now();
  const diff = trialEnd.diff(now, ['days']).toObject();
  return Math.ceil(diff?.days ?? 0);
};

const getPaywallAction = ({
  isPaywallActive,
  salesActivatedTrial,
  isOnlyUpgradeAvailable,
  isAllowedStartTrial,
}: {
  isPaywallActive: boolean;
  salesActivatedTrial: boolean;
  isOnlyUpgradeAvailable: boolean;
  isAllowedStartTrial: boolean;
}) => {
  if (!isPaywallActive) {
    return PaywallAction.NONE;
  }
  if (salesActivatedTrial) {
    return PaywallAction.TALK_TO_SALES;
  }
  if (isOnlyUpgradeAvailable || !isAllowedStartTrial) {
    return PaywallAction.UPGRADE;
  }
  return PaywallAction.START_TRIAL;
};

const usePaywall = () => {
  const { salesActivatedTrial } = useFeatureToggle();
  const { startTrial } = useUserInfo();
  const user = useSelector(selectUserInfoData);
  const [isAllowedStartTrial] = usePermission(['create:users:account:trial']);
  const { trialEndDate, type } = user?.account ?? {};
  const openIntercomForUpgrade = useCallback(() => openIntercom(INTERCOM_MESSAGE), []);
  const sendBiEvent = useSendBiEvent();
  const [, , removeCookie] = useCookies();

  const paywallState = getPaywallState(type, trialEndDate);
  const daysLeft =
    paywallState === PaywallState.IN_TRIAL && trialEndDate ? calcDaysLeft(trialEndDate) : null;

  const isPaywallActive = ACTIVE_PAYWALL_STATES.includes(paywallState);
  const isOnlyUpgradeAvailable = ONLY_UPGRADE_STATES.includes(paywallState);

  const paywallAction = getPaywallAction({
    isPaywallActive,
    salesActivatedTrial,
    isOnlyUpgradeAvailable,
    isAllowedStartTrial,
  });

  const sendTrialClickMainEvent = useCallback(() => {
    sendBiEvent({
      name: PAYWALL_EVENTS.clickTrialMain,
    });
  }, [sendBiEvent]);

  const sendTrialActivateEvent = useCallback(
    (source: PaywallSource | string) => {
      sendBiEvent({
        name: PAYWALL_EVENTS.trialActivated,
        props: {
          source,
        },
      });
    },
    [sendBiEvent],
  );

  const removeTrialCookie = useCallback(() => {
    removeCookie(ACTIVATE_TRIAL_COOKIE_NAME, { path: '/' });
  }, [removeCookie]);

  const sendEncounterPaywallEvent = useCallback(
    (paywallBlockProps: PaywallBlockProps) => {
      const { source, objectName } = paywallBlockProps;
      sendBiEvent({
        name: PAYWALL_EVENTS.paywall,
        props: {
          trialStatus: paywallState,
          objectName,
          source,
        },
      });
    },
    [paywallState, sendBiEvent],
  );

  const startTrialWithEvent = useCallback(
    async (source: PaywallSource) => {
      await startTrial();
      sendTrialActivateEvent(source);
      removeTrialCookie();
    },
    [startTrial, sendTrialActivateEvent, removeTrialCookie],
  );

  const ctaCallback = (source?: PaywallSource) => {
    scheduleCall(user);
    sendBiEvent({
      name: PAYWALL_EVENTS.ctaClicked,
      props: {
        trialStatus: paywallState,
        source,
      },
    });
  };

  return {
    startTrial: startTrialWithEvent,
    daysLeft,
    isPaywallActive,
    paywallAction, //TODO can be deleted SWEEP-5282
    openIntercomForUpgrade, //TODO can be deleted SWEEP-5282
    sendTrialClickMainEvent, //TODO can be deleted SWEEP-5282
    sendEncounterPaywallEvent,
    ctaCallback,
    paywallState,
  };
};

export default usePaywall;
