import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import {
  Apex,
  ChatCenteredText,
  ChevronLeft,
  MoreActionsHorizontal,
  ViewInSalesforce,
} from '@sweep-io/sweep-design/dist/icons';
import { Box, Popover } from '@mui/material';
import isNil from 'lodash/isNil';
import { ConfigurationType, ConfigurationTypesWithIcon, DependenciesContentType } from './types';
import RuleChips from '../RuleChips';
import StyledTooltip from '../../common/StyledTooltip';
import { DotDivider } from '../../common/DotDivider';
import { useDependencies } from './hooks/useDependencies';
import { getChips } from './helpers';
import { configurationItemTypeToIcon } from './latest-deployed/templates/organisms/helpers/icons';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { InitialsAvatar } from '../InitialsAvatar';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { useState } from 'react';
import { StyledListItem } from '../../common/StyledListItem';

interface DependenciesHeaderProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  displayItemType: string;
  crmOrgId: string;
  historyLength?: number;
  stepNamesUsedInCanvas: string[];
  withCommentsButton: boolean;
}

export const DependenciesHeader = ({
  configurationItem,
  type,
  crmOrgId,
  historyLength,
  stepNamesUsedInCanvas,
  displayItemType,
  withCommentsButton,
}: DependenciesHeaderProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement>();
  const { userInputsComments, searchV2 } = useFeatureToggle();

  const displayCommentsButton = withCommentsButton && userInputsComments;
  const displayBackToDefaultViewButton = !withCommentsButton && userInputsComments;

  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });
  const { chips, RTchips } = getChips(
    configurationItem,
    stepNamesUsedInCanvas,
    objectTypesByName,
    type,
  );
  const { onGoBackInHistory, setContentType } = useDependencies(crmOrgId, {});

  const isField = type === ConfigurationType.fields || type === ConfigurationType.rollups;
  const { label, link, annotations, usage } = configurationItem ?? {};
  const { changedBy, changedAt } = annotations ?? {};

  return (
    <Box
      display="flex"
      borderBottom={`1px solid${colors.grey[200]}`}
      p="16px 20px"
      gap={1}
      alignItems="center"
    >
      {!displayBackToDefaultViewButton && historyLength && historyLength > 1 && (
        <IconButton variant="flat" size="tiny" onClick={onGoBackInHistory}>
          <ChevronLeft />
        </IconButton>
      )}

      {displayBackToDefaultViewButton && (
        <IconButton
          variant="flat"
          size="tiny"
          onClick={() => setContentType(DependenciesContentType.default)}
        >
          <ChevronLeft />
        </IconButton>
      )}

      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h3" whiteSpace="break-spaces" wordBreak="break-word">
            {label}
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            gap={0.5}
            alignItems="center"
            sx={{ svg: { path: { stroke: colors.grey[700] } } }}
          >
            {type && (
              <>
                {configurationItemTypeToIcon[type as ConfigurationTypesWithIcon]}
                <Typography variant="caption" color={colors.grey[700]}>
                  {displayItemType}
                </Typography>
              </>
            )}

            {!!chips?.length && (
              <>
                <DotDivider />
                <RuleChips
                  chips={chips}
                  differentColorChips={RTchips}
                  differentColor={colors.grey[200]}
                />
              </>
            )}

            {isField && !isNil(usage) && !isNaN(usage) && (
              <Box display="flex" alignItems="center" gap={0.5}>
                <DotDivider />
                <Typography variant="caption" color={colors.grey[700]}>
                  {usage}% usage
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {!userInputsComments && (
          <Box display="flex" gap={1} alignItems="center" ml={1}>
            {link && (
              <StyledTooltip placement="top" title="Open in Salesforce">
                <IconButton
                  variant="outlined"
                  size={'tiny'}
                  onClick={() => window.open(link, '_blank')}
                >
                  <ViewInSalesforce />
                </IconButton>
              </StyledTooltip>
            )}
            {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
          </Box>
        )}

        {displayCommentsButton && (
          <Box display="flex" gap={1} alignItems="center" ml={1}>
            {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}

            <StyledTooltip placement="top" title="Comments">
              <IconButton
                variant="outlined"
                size={'tiny'}
                onClick={() => setContentType(DependenciesContentType.comments)}
              >
                <ChatCenteredText />
              </IconButton>
            </StyledTooltip>

            {(!!link || searchV2) && (
              <IconButton
                variant="outlined"
                size={'tiny'}
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              >
                <MoreActionsHorizontal />
              </IconButton>
            )}

            <Popover
              open={Boolean(menuAnchorEl)}
              anchorEl={menuAnchorEl}
              onClose={() => setMenuAnchorEl(undefined)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box p={1} pb={0} minWidth="206px">
                {!!link && (
                  <StyledListItem
                    StartIcon={<ViewInSalesforce />}
                    onClick={() => {
                      window.open(link, '_blank');
                      setMenuAnchorEl(undefined);
                    }}
                    title={'Open in Salesforce'}
                  />
                )}

                {searchV2 && configurationItem.filename && (
                  <StyledListItem
                    StartIcon={<Apex />}
                    onClick={() => {
                      setContentType(DependenciesContentType.sourceCode);
                      setMenuAnchorEl(undefined);
                    }}
                    title={'View Source'}
                  />
                )}
              </Box>
            </Popover>
          </Box>
        )}
      </Box>
    </Box>
  );
};
