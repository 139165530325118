import { Box, FormControl } from '@mui/material';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SlackFields } from './SlackFields';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import FieldLabel from '../../common/FieldLabel';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { colors } from '@sweep-io/sweep-design';
import { SlackRecipientsSelect } from './SlackRecipientsSelect';
import { useSlackRecipients } from './useSlackRecipients';
import { SlackAddActionPanel } from './SlackAddActionPanel';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { SlackActionType } from '../../../types/enums/SlackActionType';
import { useGetSlackRecipientsLabels } from './useGetSlackRecipientsLabels';
import { SlackConstant } from './utils';

interface SlackNotificationActionProps {
  readonly?: boolean;
  action: SlackAutomationAction;
  crmOrgId: string;
  onChange: (slackAutomationAction: SlackAutomationAction) => any;
  objectName: string;
}

export const SlackNotificationAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackNotificationActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);
  const { recipientWithLabelsArray } = useGetSlackRecipientsLabels({
    recipients: action.actionParams?.recipients ?? [],
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const setBeforeCallingFields = useCallback(() => {
    updateOnChange({ fields: [...(action.actionParams?.fields || [])] });
  }, [action.actionParams?.fields, updateOnChange]);

  const { isLoading } = useSlackRecipients({
    objectName,
    crmOrgId,
    setBeforeCallingFields,
    addSweepSlackChannel: true,
  });

  const handleDeleteChip = useCallback(
    (chipToRemove: SlackRecipientStruct) => {
      const newRecipients = action.actionParams.recipients?.filter((item) => {
        if (chipToRemove.type === SlackConstant.Slack && item.type === SlackConstant.Slack) {
          return item.value !== chipToRemove.value;
        }
        if (
          chipToRemove.type === SlackConstant.Sweep_Created_Slack_Channel &&
          item.type === SlackConstant.Sweep_Created_Slack_Channel
        ) {
          return item.type !== SlackConstant.Sweep_Created_Slack_Channel;
        }
        if (
          chipToRemove.type === SlackConstant.Salesforce_Created_Slack_Channel &&
          item.type === SlackConstant.Salesforce_Created_Slack_Channel
        ) {
          return item.type !== SlackConstant.Salesforce_Created_Slack_Channel;
        }

        if (
          chipToRemove.type === SlackConstant.Salesforce &&
          item.type === SlackConstant.Salesforce
        ) {
          return !isEqual(
            (item.value as SlackFieldsStruct).fieldIds,
            (chipToRemove.value as SlackFieldsStruct).fieldIds,
          );
        }
        return true;
      });
      updateOnChange({ recipients: newRecipients });
    },
    [action, updateOnChange],
  );

  const _onChange = useCallback(
    (event: any, newValues: any) => {
      const newItems = newValues.map((el: any) => {
        switch (el.type) {
          case SlackConstant.Salesforce:
            return {
              ...el,
              type: SlackConstant.Salesforce,
              value: {
                fieldIds: el.value.fieldIds,
              },
            };
          case SlackConstant.Slack:
            return {
              ...el,
              type: SlackConstant.Slack,
              value: el.value,
            };
          case SlackConstant.Sweep_Created_Slack_Channel:
            return {
              ...el,
              type: SlackConstant.Sweep_Created_Slack_Channel,
            };
          case SlackConstant.Salesforce_Created_Slack_Channel:
            return {
              ...el,
              type: SlackConstant.Salesforce_Created_Slack_Channel,
            };
        }
      });
      updateOnChange({ recipients: newItems as SlackRecipientStruct[] });
    },
    [updateOnChange],
  );

  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
        }}
      >
        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
            maxWidth: '1148px',
          }}
        >
          <Box
            sx={{
              marginBottom: '3px',
              '& div': {
                color: colors.grey[800],
                fontSize: '12px',
                fontWeight: '400',
              },
            }}
          >
            <FieldLabel label={'Recipients'} markLabelAsRequired={true} />
          </Box>

          <SlackRecipientsSelect
            isAddTextFieldFromSf={true}
            customButtonText={'Add Dynamic Recipient'}
            objectName={objectName}
            crmOrgId={crmOrgId}
            isLoading={isLoading}
            onChange={_onChange}
            readonly={!!readonly}
            recipients={recipientWithLabelsArray}
            handleDeleteChip={handleDeleteChip}
            notConnected={!productionOrg || slackStatus !== SlackConnectionStatus.Connected}
          />
        </FormControl>

        <Box sx={{ position: 'relative' }}>
          <SlackNewMessageEditor
            isRequired={true}
            label="Message"
            readonly={readonly}
            messageStruct={action.actionParams?.message}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />
        </Box>

        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <FieldLabel label={'Attached Fields'} />
          <SlackFields
            readonly={readonly}
            actionFields={
              action?.actionParams?.fields?.map((f) => ({
                fieldIds: f.fieldIds,
                fieldData: f,
              })) ?? []
            }
            onChange={(fields: SlackFieldsStruct[]) => {
              updateOnChange({ fields: fields });
            }}
          />

          <Box>
            <Box>
              <NestedFieldsSelector
                readonly={readonly}
                crmOrgId={crmOrgId}
                objectType={objectName}
                customButtonStartIcon={<Plus color={colors.blue[500]} />}
                useCustomButton
                customButtonText="Add field"
                nestedPath={[]}
                customButtonSx={{
                  color: colors.blue[500],
                  border: 'none',
                  textTransform: 'unset',
                  marginTop: '6px',
                  p: '0',
                  '& span': {
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                  '&:hover': {
                    background: 'transparent',
                    color: colors.blue[600],
                  },
                }}
                onChange={(sweepField: NestedFieldsField) => {
                  const newItem = {
                    fieldIds: sweepField.fieldIds,
                    _fieldLabels: sweepField.fieldLabels,
                  };
                  const newFields = [
                    ...((action.actionParams?.fields as SlackFieldsStruct[]) || []),
                    newItem,
                  ];
                  updateOnChange({ fields: newFields });
                }}
              />
            </Box>
          </Box>
        </FormControl>

        <SlackAddActionPanel
          crmOrgId={crmOrgId}
          objectName={objectName}
          slackActions={action.actionParams?.actions}
          onAddAction={(type: SlackActionType) => {
            switch (type) {
              case SlackActionType.UPDATE_RECORD: {
                const currActions = action.actionParams?.actions || [];
                const newUpdateAction: UpdateRecordSlackAction = {
                  buttonText: '',
                  fields: [],
                  type: SlackActionType.UPDATE_RECORD,
                };
                const _currActions = [...currActions, newUpdateAction];
                updateOnChange({ actions: _currActions });
                break;
              }
              case SlackActionType.CREATE_RECORD: {
                const currActions = action.actionParams?.actions || [];
                const newCreateAction: CreateRecordSlackAction = {
                  buttonText: '',
                  fields: [],
                  type: SlackActionType.CREATE_RECORD,
                  objectTypeName: {
                    label: '',
                    objectType: '',
                  },
                };
                updateOnChange({ actions: [...currActions, newCreateAction] });
                break;
              }
              default:
                break;
            }
          }}
          onUpdateAction={(_action: SlackAction, index: number) => {
            const currActions = [...(action.actionParams?.actions || [])];
            currActions[index] = _action;
            updateOnChange({ actions: currActions });
          }}
          onDeleteAction={(index: number) => {
            const currActions = [...(action.actionParams?.actions || [])];
            currActions.splice(index, 1);
            updateOnChange({ actions: currActions });
          }}
          onReorderAction={(_actions: SlackAction[]) => {
            const currActions = [...(_actions || [])];
            updateOnChange({ actions: currActions });
          }}
        />
      </Box>
    </>
  );
};
