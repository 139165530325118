import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';
import { useMemo } from 'react';
import { HsPropertyTypes } from '../hubspot.types';
import { WorkflowWhenEventTypes } from '../types';

export interface HsEventOption {
  property: string;
  type: HsPropertyTypes;
  label: string;
}

interface HubspotEventSelectorProps {
  onChange: (field: SearchSelectItem<HsEventOption>) => void;
  value?: string;
  disabled?: boolean;
  eventType: WorkflowWhenEventTypes;
}

const optionsPerEventType: Record<WorkflowWhenEventTypes, HsEventOption[]> = {
  [WorkflowWhenEventTypes.AD_INTERACTION]: [
    // {
    //   property: 'hs_ad_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad',
    // },
    // {
    //   property: 'hs_ad_campaign_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad Campaign',
    // },
    // {
    //   property: 'hs_ad_group_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad Group',
    // },
    {
      property: 'hs_ad_network',
      type: HsPropertyTypes.ENUMERATION,
      label: 'Ad Network',
    },
    {
      property: 'hs_interaction_type',
      type: HsPropertyTypes.ENUMERATION,
      label: 'Interaction Type',
    },
    {
      property: 'hs_source',
      type: HsPropertyTypes.ENUMERATION,
      label: 'Network Placement',
    },
  ],
  [WorkflowWhenEventTypes.FORM_SUBMITTED]: [
    { property: 'hs_base_url', type: HsPropertyTypes.STRING, label: 'Base URL' },
    { property: 'hs_country', type: HsPropertyTypes.STRING, label: 'Country' },
    { property: 'hs_form_id', type: HsPropertyTypes.ENUMERATION, label: 'Form Name' },
    { property: 'hs_content_id', type: HsPropertyTypes.ENUMERATION, label: 'Page Name' },
    { property: 'hs_url_domain', type: HsPropertyTypes.STRING, label: 'Landing Page URL' },
  ],
  [WorkflowWhenEventTypes.EMAIL_REPLY]: [
    {
      property: 'hs_email_campaign_group_id',
      type: HsPropertyTypes.ENUMERATION,
      label: 'Email Campaign',
    },
  ],
};

export const HubspotEventSelector = ({
  onChange,
  value,
  disabled,
  eventType,
}: HubspotEventSelectorProps) => {
  const items: SearchSelectItem<HsEventOption>[] = useMemo(() => {
    const items = optionsPerEventType[eventType].map((option) => ({
      label: option.label,
      value: option.property,
      data: option,
    }));
    return items;
  }, [eventType]);

  return (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={onChange}
      SelectProps={{
        value,
      }}
      removeBorders
      disabled={disabled}
    />
  );
};
