import { orderBy } from 'lodash';
import { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../../../reducers/accountUsersReducer';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { SortOrder } from '../../../common/types';
import { selectCrmOrgHasManagePackage } from '../../../pages/environments/environmentsReducer';
import {
  NewDependencyProps,
  ConfigurationType,
  DependenciesContentType,
} from '../../dependencies/types';
import { ParsedRuleWithParentType } from '../../types';
import { onFieldClickProps } from '../../useDocumentation';
import { filterItemsByQuerySearch } from '../filters/filtersOptions';
import { ExpandedObjectDataTableElement } from './ExpandedObjectContent';
import { getColumns } from './tableColumns';
import { renderRows } from './tableRows';
import { translateItemsToDataTableElements } from './translateItemsToDataTableElements';
import { Utilization } from '../Utilization';

export const useExpandedModeCalculations = ({
  elements,
  objectName,
  documentationTabType,
  onFieldClick,
  activeItemId,
  handleSetConfigurationItem,
  crmOrgId,
}: {
  crmOrgId: string;
  elements: FieldMetadataRecordProperties[] | AutomationStructureNew[] | ParsedRuleWithParentType[];
  objectName: string;
  documentationTabType: DocumentationTabTypes;
  onFieldClick: (props: onFieldClickProps) => Promise<void>;
  activeItemId: string;
  handleSetConfigurationItem: (props: NewDependencyProps) => void;
}) => {
  const [searchStr, setSearchStr] = useState('');
  const [sortBy, setSortBy] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'label',
    sortOrder: SortOrder.ASC,
  });

  const isManagePackageInstalled = !!useSelector(selectCrmOrgHasManagePackage(crmOrgId));
  const users = useSelector(selectAccountUsers);

  const _elements = useMemo(
    () => translateItemsToDataTableElements(documentationTabType, elements, users),
    [documentationTabType, elements, users],
  );

  const _columns = getColumns(documentationTabType);
  const columns =
    documentationTabType === DocumentationTabTypes.FIELDS
      ? isManagePackageInstalled
        ? _columns
        : _columns.map((column) =>
            column.field === 'usage'
              ? {
                  ...column,
                  headerName: <Utilization columnName={column.headerName} />,
                }
              : column,
          )
      : _columns;

  const filteredBySearchStr = useMemo(() => {
    const itemSelector = columns.map((column) => column.field);
    return filterItemsByQuerySearch({
      itemProps: itemSelector,
      searchTxt: searchStr,
      items: _elements,
    }) as ExpandedObjectDataTableElement[];
  }, [_elements, columns, searchStr]);

  const rows = renderRows({
    items: orderBy(
      filteredBySearchStr,
      function (item) {
        const key = sortBy.sortBy as keyof ExpandedObjectDataTableElement;
        const value = item[key];
        if (typeof value === 'string') {
          if (!isNaN(new Date(value)?.getTime())) {
            return new Date(value);
          }

          return value.toLowerCase();
        }
        return value;
      }, //to sort using case insensitive and sort dates properly
      sortBy.sortOrder?.toLowerCase() as 'asc' | 'desc',
    ),
    activeItemId,
    isManagePackageInstalled,
  });

  const onRowClick = useCallback(
    (
      e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
      row: ExpandedObjectDataTableElement,
    ) => {
      const isField = row.parentType === ConfigurationType.fields;
      const isRollup = row.parentType === ConfigurationType.rollups;

      if (isField || isRollup) {
        onFieldClick({
          fieldName: row._sfApiName,
          objectName,
          crmOrgId,
          isRollup: !!row.isRollup,
          contentType: DependenciesContentType.default,
        });
        return;
      }

      handleSetConfigurationItem({
        id: row.id,
        dependencyType: row._type,
        parentType: row.parentType,
        name: row._sfApiName,
        objectName: objectName,
        clearHistory: true,
        contentType: DependenciesContentType.default,
      });
    },
    [crmOrgId, handleSetConfigurationItem, onFieldClick, objectName],
  );

  const onSortChange = useCallback(
    ({ sortBy, sortOrder }: { sortBy: string; sortOrder: SortOrder }) => {
      setSortBy({ sortBy, sortOrder: sortOrder });
    },
    [],
  );

  return { setSearchStr, columns, rows, searchStr, onRowClick, onSortChange, sortBy };
};
