//Product is preparing to have entire separate section for tags management,
//In preparation for that I'm storing them separately
import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../apis/facades/useUserInputsApiFacade';

export interface TagsState {
  tagsList: Tag[];
}

const initialState: TagsState = {
  tagsList: [],
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsList: (state, action: PayloadAction<{ tags: Tag[] }>) => {
      state.tagsList = action.payload.tags;
    },
    addTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      state.tagsList.push(action.payload.tag);
    },
  },
});

export const { setTagsList, addTag } = tagsSlice.actions;

export const selectTagsList = (state: RootState) => state.tags.tagsList;
export const selectTagById = (tagId: string) => (state: RootState) =>
  state.tags.tagsList.find((tag) => tag.id === tagId);

export default tagsSlice.reducer;
