import { colors, Typography } from '@sweep-io/sweep-design';
import { InfoDialog } from '../../dialogs/InfoDialog';
import { FlexBox } from '../../FlexBox';
import { Check } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { selectDefaultCreationEnvironment } from '../../../pages/environments/environmentsReducer';
import { useSelector } from 'react-redux';
import AssignPermissionSetButton from '../../assign-permission-set/AssignPermissionSetButton';
import { useRunOnceWhenTruthy } from '../../useRunOnceWhenTruthy';
import pluralize from 'pluralize';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { UI_EVENTS } from '../../../../services/events';
import { useRunOnce } from '../../useRunOnce';
import InstallMpButton from '../../install-managed-package/InstallMpButton';

const InstallItem = ({
  bulletNumber,
  text,
  complete,
  children,
}: {
  bulletNumber: number;
  text: string;
  complete?: boolean;
  children?: React.ReactNode;
}) => {
  const textDecoration: any = complete ? 'line-through' : 'none';

  return (
    <Box height="56px" display="flex" flexDirection="column" alignContent="center">
      <FlexBox alignItems="center" alignContent={'space-around'}>
        <FlexBox alignItems="center" gap={1}>
          <Box width="24px">
            {complete ? (
              <Check color={colors.blue[500]} />
            ) : (
              <Typography variant="body">{bulletNumber}.</Typography>
            )}
          </Box>
          <Typography variant="body" color={colors.grey[800]} textDecoration={textDecoration}>
            {text}
          </Typography>
        </FlexBox>
        {!complete && children}
      </FlexBox>
    </Box>
  );
};

export const CompleteSfSetupDialog = ({
  cancelDialog,
  entityName,
  onCompleteItemsCallback,
  title,
}: {
  cancelDialog: () => void;
  entityName: string;
  onCompleteItemsCallback: () => void;
  title?: string;
}) => {
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackageInstalled = crmOrg?.isManagedPackageInstalled;
  const isPermissionSetGroupAssigned = crmOrg?.isPermissionSetGroupAssigned;
  const sendBiEvent = useSendBiEvent();

  useRunOnceWhenTruthy(
    onCompleteItemsCallback,
    Boolean(isManagedPackageInstalled && isPermissionSetGroupAssigned),
  );

  const titleJsx = title ?? `Deploy your saved ${pluralize(entityName)} 🎉`;
  useRunOnce(() => {
    sendBiEvent({ name: UI_EVENTS.completeSetupOpen });
  });

  return (
    <InfoDialog
      handleClose={(e) => {
        e.stopPropagation();
        cancelDialog();
      }}
      open={true}
      PaperPropsSx={{ width: '592px', height: '320px' }}
      showCloseButton
      titleJsx={<Typography variant="h1">{titleJsx}</Typography>}
      dialogContentSx={{ padding: '0 24px' }}
    >
      <Typography variant="body" color={colors.grey[800]}>
        Complete the following 3 simple steps to see it live in Salesforce:
      </Typography>
      <FlexBox sx={{ marginTop: 3 }} flexDirection="column">
        <InstallItem bulletNumber={1} text="Connect to Salesforce" complete />
        <InstallItem
          bulletNumber={2}
          text="Install the Managed Package"
          complete={isManagedPackageInstalled}
        >
          <InstallMpButton text="Install" />
        </InstallItem>
        <InstallItem
          bulletNumber={3}
          text="Assign Sweep Permission Set Group"
          complete={isPermissionSetGroupAssigned}
        >
          <AssignPermissionSetButton />
        </InstallItem>
      </FlexBox>
    </InfoDialog>
  );
};
