import { environment } from '../environment';
import useUserInfo from './useUserInfo';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';

//This hook is exposing "startTrial" function on the "window" object, to be used by the E2E
const useTestsHelper = () => {
  const { startTrial } = useUserInfo();

  useRunOnceWhenTruthy(() => {
    (window as any).startTrial = startTrial;
  }, environment === 'development');

};

export default useTestsHelper;
