import { UserInputTypes } from '@server/user-inputs';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';
import { getRandomColorName } from '../../../../../lib/getRandomColorName';
import { InlineAutocompleteItem } from '../../../../common/InlineAutocomplete/InlineAutocomplete';
import { createUserInputData } from './utils';
import { useDependencies } from '../../hooks/useDependencies';
import { useSelector } from 'react-redux';
import { selectDependencyCustomNotesById } from '../../../../../reducers/documentationReducer';
import { useTags } from './useTags';

export const useConfigurationTags = (configurationItemId: string, crmOrgId: string) => {
  const { onAddTag } = useTags();
  const { updateDependencyTags } = useDependencies(crmOrgId, {});

  const customNotes = useSelector(selectDependencyCustomNotesById(configurationItemId));

  const onSelectItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes) => {
      const newCustomNotes = customNotes ? cloneDeep(customNotes) : createUserInputData();

      if (parentKey === UserInputTypes.Owner) {
        newCustomNotes[parentKey] = item.value;
      } else {
        newCustomNotes[parentKey].push(item.value);
      }

      updateDependencyTags({ [configurationItemId]: newCustomNotes });
    },
    [customNotes, configurationItemId, updateDependencyTags],
  );

  const onCreateTag = useCallback(
    async (name: string) => {
      const colorName = getRandomColorName();
      const tagId = await onAddTag({ name, color: colorName });

      if (tagId) {
        const newCustomNotes = cloneDeep(customNotes) ?? createUserInputData();
        newCustomNotes[UserInputTypes.Tags].push(tagId);

        updateDependencyTags({
          [configurationItemId]: newCustomNotes,
        });
      }
    },
    [configurationItemId, customNotes, onAddTag, updateDependencyTags],
  );

  const onDeleteItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes) => {
      const newCustomNotes = cloneDeep(customNotes);

      if (newCustomNotes) {
        switch (parentKey) {
          case UserInputTypes.Owner:
            newCustomNotes[parentKey] = '';
            break;
          default:
            newCustomNotes[parentKey] = newCustomNotes[parentKey].filter(
              (tagId) => tagId !== item.value,
            );
        }

        updateDependencyTags({ [configurationItemId]: newCustomNotes });
      }
    },
    [customNotes, configurationItemId, updateDependencyTags],
  );

  return { onSelectItem, onCreateTag, onDeleteItem };
};
