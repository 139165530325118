import { UserInputTypes } from '@server/user-inputs';
import { Tag } from '../../../../../apis/facades/useUserInputsApiFacade';
import { UserInfo } from '../../../../../types/UserInfoTypes';
import { colors } from '@sweep-io/sweep-design';
import { defaultEmojis } from '../../../../../constants/defaultEmojis';
import { getRandomColorName } from '../../../../../lib/getRandomColorName';

export const parseIdToValue = (tags: Tag[]) =>
  tags?.map((tag) => ({
    value: tag.id,
    label: tag.name,
    tagColor: (colors[tag.color as keyof typeof colors] as any)[100],
  }));

export const parseIdToValueAndNameToLabel = (users: UserInfo[]) =>
  users?.map((user) => {
    const color =
      defaultEmojis.find((emoji) => emoji.content == user.emoji?.content)?.sweepColor ??
      getRandomColorName();

    return {
      value: user.id ?? '',
      label: user.name ?? user.email ?? '',
      tagColor: (colors[color as keyof typeof colors] as any)[100],
      emoji: user.emoji,
      imageUrl: user.imageUrl,
    };
  });

export const createUserInputData = () => ({
  [UserInputTypes.Tags]: [],
  [UserInputTypes.Collaborators]: [],
  [UserInputTypes.Owner]: '',
});
