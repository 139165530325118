import { Box } from '@mui/material';
import { DependenciesSection } from './DependenciesSection';
import { DetailsSection } from './DetailsSection';
import { ConfigurationChatInputWrapper } from './configuration-chat/ConfigurationChatInputWrapper';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  SWEEP_ELEMENTS,
  excludedFromDetails,
  isFieldType,
  isSupported,
  prepareConfigItemMapStructure,
} from './helpers';
import isNil from 'lodash/isNil';
import { whatDoesItDoPossibleTypes } from './configuration-chat/configurationChatConsts';
import OpenAiSection from './OpenAiSection';
import { useDependencies } from './hooks/useDependencies';
import { selectOpenAiDescriptionForRule } from '../../../reducers/documentationReducer';
import {
  ConfigurationType,
  CONFIGURATION_ITEMS_FOR_DEPENDS_ON,
  CONFIGURATIONS_FOR_USED_BY,
} from './types';
import { LatestDeployedSection } from './latest-deployed/LatestDeployedSection';
import { SweepElementTypes } from '../types';
import { useGetUsedBySweepElements } from './hooks/useGetUsedBySweepElements';
import { useGetParsedDepedencies } from './hooks/useGetParsedDependencies';
import { UI_EVENTS } from '../../../services/events';
import { useGetRecordTypeDependencies } from './hooks/useGetRecordTypeDependencies';
import { useGetSweepElementsDependencies } from './hooks/useGetSweepElementsDependencies';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { AiChatMessages } from '../../common/ai-chat/AiChatMessages';
import { useConfigurationChat } from './configuration-chat/useConfigurationChat';
import { telemetry } from '../../../telemetry';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { TagsSection } from './user-inputs/tags/TagsSection';

interface DependenciesContentProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  crmOrgId: string;
  displayItemType: string;
}

export const DependenciesContent = ({
  configurationItem,
  type,
  crmOrgId,
  displayItemType,
}: DependenciesContentProps) => {
  const { userInputsTags } = useFeatureToggle();
  const sendBiEvent = useSendBiEvent();
  const ref = useRef<HTMLDivElement>();
  const { id, name, objectApiNames, objectName } = configurationItem ?? {};
  const openAiDescription = useSelector(selectOpenAiDescriptionForRule(id, crmOrgId));
  const isSweepElement = SWEEP_ELEMENTS.includes(type);

  const {
    handleSetConfigurationItem,
    getOpenAiDescriptionForRule,
    getConfigurationDependencies,
    getConfigurationUsedBy,
    getFieldConsumers,
  } = useDependencies(crmOrgId, {});

  const _getConfigurationUsedBy = useCallback(async () => {
    if (type && !isSweepElement) {
      switch (type) {
        case ConfigurationType.fields:
          if (name && objectName && id) {
            await getFieldConsumers({
              id,
              fieldName: name,
              objectName,
              crmOrgId,
            });
          } else {
            telemetry.captureError(new Error('missing values for getFieldConsumers'), {
              name,
              objectName,
            });
          }
          break;

        case ConfigurationType.recordTypes:
          break;

        default:
          await getConfigurationUsedBy({
            ruleType: type,
            ruleId: id,
            crmOrgId,
            objectName: objectName ?? objectApiNames?.[0] ?? '',
            configurationName: name,
          });
      }
    }
  }, [
    type,
    name,
    objectName,
    getConfigurationUsedBy,
    getFieldConsumers,
    id,
    crmOrgId,
    objectApiNames,
    isSweepElement,
  ]);

  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  const dependsOnForSweepElement = useGetSweepElementsDependencies(
    crmOrgId,
    id,
    type as SweepElementTypes,
  );

  const dependsOnItems = useGetParsedDepedencies({
    crmOrgId,
    configurationItemId: id,
    type: 'dependsOnIdsForRules',
    name,
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    isSupportedType: isSupported(CONFIGURATION_ITEMS_FOR_DEPENDS_ON, type),
  });

  const usedByItems = useGetParsedDepedencies({
    configurationItemId: id,
    name,
    crmOrgId,
    type: type === ConfigurationType.fields ? 'usedByIdsForFields' : 'usedByIdsForRules',
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    isSupportedType: isSupported(CONFIGURATIONS_FOR_USED_BY, type),
  });

  const {
    attributions: usedByRecordTypes,
    sweepElementsAttributions: usedRecordTypeBySweepElements,
  } = useGetRecordTypeDependencies({
    recordTypeName: name,
    objectName: objectName ?? objectApiNames?.[0] ?? '',
    crmOrgId,
    isSupportedType: type === ConfigurationType.recordTypes,
  });

  const usedBySweepElements = useGetUsedBySweepElements({
    crmOrgId,
    configurationId: id,
    isSupportedType: isSupported(CONFIGURATIONS_FOR_USED_BY, type),
  });

  const configurationChatObjectName = objectName ?? objectApiNames?.[0];
  const firstObjectApiName = objectName ?? objectApiNames?.[0];
  const isAiAllowed = type && whatDoesItDoPossibleTypes.includes(type);
  const isField = isFieldType(type);

  const { onConfirm, onConfirmForFields, isLoading, messages, error, disableInput } =
    useConfigurationChat();

  useEffect(() => {
    if (
      !openAiDescription &&
      id &&
      name &&
      type &&
      isAiAllowed &&
      !isNil(firstObjectApiName) &&
      !isSweepElement
    ) {
      getOpenAiDescriptionForRule({
        objectName: firstObjectApiName,
        ruleName: name,
        ruleType: type,
        ruleId: id,
        crmOrgId,
      });
    }
  }, [
    crmOrgId,
    openAiDescription,
    getOpenAiDescriptionForRule,
    name,
    firstObjectApiName,
    type,
    id,
    isAiAllowed,
    isSweepElement,
  ]);

  return (
    <>
      <Box p={2.5} overflow="auto" ref={ref} height="100%">
        {isAiAllowed && (
          <Box pb={3}>
            <OpenAiSection openAiDescription={openAiDescription} />
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {type && !excludedFromDetails.includes(type) && (
            <DetailsSection
              key={`details-${id}`}
              configurationItem={configurationItem}
              isField={isField}
              crmOrgId={crmOrgId}
            />
          )}

          {userInputsTags && (
            <TagsSection crmOrgId={crmOrgId} configurationItemId={configurationItem.id} />
          )}

          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`configurations-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            configurationItems={prepareConfigItemMapStructure(usedByRecordTypes)}
            sweepConfigurationItems={prepareConfigItemMapStructure(usedRecordTypeBySweepElements)}
            type={type}
            title="Configurations"
            isSupportedType={isSupported([ConfigurationType.recordTypes], type)}
            onChooseNewRule={handleSetConfigurationItem}
          />

          <DependenciesSection
            key={`dependsOn-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            type={type}
            configurationItems={prepareConfigItemMapStructure(
              isSweepElement ? dependsOnForSweepElement : dependsOnItems,
            )}
            title="What does this depend on?"
            fetchApi={isSweepElement ? undefined : getConfigurationDependencies}
            isSupportedType={isSupported(CONFIGURATION_ITEMS_FOR_DEPENDS_ON, type)}
            onChooseNewRule={handleSetConfigurationItem}
            crmOrgId={crmOrgId}
          />

          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`usedBy-${id}.${name}`}
            id={id}
            name={name}
            objectName={objectName ?? objectApiNames?.[0] ?? ''}
            configurationItems={prepareConfigItemMapStructure(usedByItems)}
            sweepConfigurationItems={prepareConfigItemMapStructure(usedBySweepElements)}
            type={type}
            title="Where is this used?"
            fetchApi={_getConfigurationUsedBy}
            isSupportedType={isSupported(CONFIGURATIONS_FOR_USED_BY, type)}
            onChooseNewRule={handleSetConfigurationItem}
          />

          {isSweepElement && (
            <LatestDeployedSection
              sweepElementType={type as SweepElementTypes}
              parentElementId={configurationItem.id}
              crmOrgId={crmOrgId}
            />
          )}

          <AiChatMessages
            scrollToBottom={scrollToBottom}
            messages={messages}
            isLoading={isLoading}
            errorMsg={error}
          />
        </Box>
      </Box>

      {type && name && (
        <ConfigurationChatInputWrapper
          onConfirm={(msg: string) => {
            sendBiEvent({
              name: UI_EVENTS.documentationAiEngage,
              props: { object: configurationChatObjectName, element: type },
            });

            if (type === ConfigurationType.fields || type === ConfigurationType.rollups) {
              onConfirmForFields({
                message: msg,
                crmOrgId,
                objectName: configurationChatObjectName ?? '',
                fieldName: name,
              });
              return;
            }

            onConfirm({
              message: msg,
              crmOrgId,
              objectName: configurationChatObjectName ?? '',
              configurationName: name,
              configurationKey: type,
            });
          }}
          isLoading={isLoading}
          configurationKey={type}
          itemType={displayItemType}
          disableInput={disableInput}
          messagesCount={messages.length}
        />
      )}
    </>
  );
};
