import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { Section } from './atoms/Section';
import { colors, Typography } from '@sweep-io/sweep-design';
import {
  RoleGroupInterface,
  RoleGroupType,
  RoleLevel,
  Sections,
} from '@server/role-group-interface';
import SweepSelect from '../../common/SweepSelect';
import { featureTranslations, roleTranslations } from './utils';
import { useRoleManagerContext } from './RoleManagerContext';

interface SingleRoleDetailsProps {
  role: RoleGroupInterface;
}

export const SingleRoleDetails = ({ role }: SingleRoleDetailsProps) => {
  const { updateRole } = useRoleManagerContext();
  const isEditable = role.type !== RoleGroupType.SYSTEM;

  return (
    <Stack>
      <Section headerTxt="Description">
        {!isEditable && (
          <Typography variant="body" color={colors.grey[800]}>
            {role.description}
          </Typography>
        )}

        {isEditable && (
          <TextField
            multiline
            rows={2}
            value={role.description ?? ''}
            onChange={(e) => updateRole('description', e.target.value)}
            placeholder="What is this role used for?"
          />
        )}
      </Section>

      <Section headerTxt="Permissions" withBorderBottom>
        <Box>
          {Sections.map((roleLevel) => {
            const groupValue = role.roles?.[roleLevel];

            return (
              <Box
                key={roleLevel}
                display="flex"
                alignItems="center"
                pl="2px"
                mt="6px"
                mb="12px"
                height="40px"
              >
                <Box minWidth="166px">
                  <Typography variant="caption">
                    {featureTranslations[roleLevel as keyof typeof featureTranslations]}
                  </Typography>
                </Box>

                <Box width="100%">
                  {isEditable && (
                    <SweepSelect
                      FormControlProps={{ sx: { width: '100%' } }}
                      SelectProps={{
                        value: groupValue ?? '',
                        placeholder: 'Select',
                        onChange: (event) => {
                          updateRole('roles', {
                            ...role.roles,
                            [roleLevel]: event.target.value as RoleLevel,
                          });
                        },
                      }}
                    >
                      {Object.keys(RoleLevel).map((roleKey) => (
                        <MenuItem
                          key={roleKey}
                          value={RoleLevel[roleKey as keyof typeof RoleLevel]}
                        >
                          {roleTranslations[roleKey as keyof typeof roleTranslations]}
                        </MenuItem>
                      ))}
                    </SweepSelect>
                  )}

                  {!isEditable && (
                    <Typography variant="caption">
                      {roleTranslations[groupValue as keyof typeof roleTranslations]}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Section>
    </Stack>
  );
};
