import { useConfigurationCanvas } from '../pages/configuration-canvas/public/useConfigurationCanvas';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import { RoutingTabElementsList } from './RoutingTabElementsList';
import { RoutingTabContent } from './RoutingTabContent';
import { ConfigurationCanvasLeftPanel } from '../pages/configuration-canvas-panel';
import { useCallback, useRef, useState } from 'react';
import MembersDialog from './MembersDialog';
import GroupsDialog from './GroupsDialog';
import TerritoriesDialog from './TerritoriesDialog';
import AssignmentsTemplatesDialog from './AssignmentsTemplatesDialog';
import {
  SingleAutomationDialog,
  SingleAutomationDialogRef,
} from '../Automations/SingleAutomationDialog';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { RoutingTabsInfo } from './routingTabsInfo';
import { useSelector } from 'react-redux';
import { useFeatureToggle } from '../common/useFeatureToggle';
import ScheduledAssignmentsEmptyStateDialog from './ScheduledAssignmentsEmptyStateDialog';
import { useRunOnce } from '../common/useRunOnce';
import { AutomationAssignmentsContextProvider } from './AutomationAssignmentsContextProvider';
import { AutomationScheduledAssignmentsContextProvider } from './AutomationScheduledAssignmentsContextProvider';

const useIsEmptyState = (routingTab?: RoutingTabs) => {
  const routeInfo = routingTab ? RoutingTabsInfo[routingTab] : undefined;
  const { selector } = routeInfo ?? {};
  const items = useSelector(selector ?? (() => undefined));
  return items?.length === 0;
};

const DRILLABLE_ROUTES = Object.keys(RoutingTabs).filter(
  (route) => !!RoutingTabsInfo[route as RoutingTabs]?.ContentComponent,
);

const RoutingPage = () => {
  const { assignmentsFte } = useFeatureToggle();
  const { appCrmOrgId } = useConfigurationCanvas();
  const { clearTab, changeTab, routingTab } = useRoutingTabs();
  const [automationInEdit, setAutomationInEdit] = useState<Partial<AutomationStructureNew>>();
  const SingleAutomationDialogRef = useRef<SingleAutomationDialogRef>(null);

  const isEmptyState = useIsEmptyState(routingTab);
  const isNoAssignments = useIsEmptyState(RoutingTabs.assignment);
  const isMenuNavigation = !routingTab;
  const isDrillableRoute = routingTab ? DRILLABLE_ROUTES.includes(routingTab) : false;
  const showElementsList = !isDrillableRoute || (assignmentsFte && isEmptyState);
  const disableOpenAnimation = !!routingTab;
  const transitionDuration = disableOpenAnimation ? 0 : undefined;
  const showEmptyStateDialog = assignmentsFte && isEmptyState && !automationInEdit;

  const closeSingleAutomationDialog = () => {
    setAutomationInEdit(undefined);
  };

  const goToTab = (tab: RoutingTabs) => {
    changeTab(tab, appCrmOrgId);
  };

  const goToRoutingHome = useCallback(() => {
    clearTab(appCrmOrgId);
  }, [appCrmOrgId, clearTab]);

  const fallbackAutomationFormVariant = AutomationFormVariant.ASSIGNMENT_RULES;
  const { onSaveOrCreate } = useAutomationApiPerType({
    automationVariant:
      (routingTab ? RoutingTabsInfo[routingTab].automationVariant : undefined) ||
      fallbackAutomationFormVariant,
  });

  //First Time Experience product: if entering "routing" and there are no assignments, open the templates dialog
  //(should not applicable for deep links, only menu navigation)
  useRunOnce(() => {
    if (assignmentsFte && isNoAssignments && isMenuNavigation) {
      changeTab(RoutingTabs.assignment, appCrmOrgId);
    }
  });

  const renderSingleAutomationDialog = () => {
    if (!automationInEdit) return null;
    return (
      <SingleAutomationDialog
        ref={SingleAutomationDialogRef}
        isOpen={!!automationInEdit}
        automation={automationInEdit}
        onSaveOrCreate={async (automationJson, crmOrgIds) => {
          const res = await onSaveOrCreate(automationJson, crmOrgIds, false);
          if (res?.isError) {
            return { isError: true };
          }
          closeSingleAutomationDialog();
          return res;
        }}
        onCancel={closeSingleAutomationDialog}
        crmOrgId={appCrmOrgId}
        objectName={automationInEdit.objectName ?? ''}
        isShowWideScreenPopup={false}
      />
    );
  };

  return (
    <>
      <ConfigurationCanvasLeftPanel
        sx={{
          '.configuration-canvas-panel-content': {
            overflow: 'hidden',
          },
        }}
      >
        {showElementsList && <RoutingTabElementsList goToTab={goToTab} />}
        {!showElementsList && <RoutingTabContent goToRoutingHome={goToRoutingHome} />}
      </ConfigurationCanvasLeftPanel>

      {routingTab === RoutingTabs.assignment && (
        <AutomationAssignmentsContextProvider>
          {showEmptyStateDialog && (
            <AssignmentsTemplatesDialog
              title={`Create your first Assignment`}
              closeDialog={goToRoutingHome}
              setAutomationInEdit={(automation: Partial<AutomationStructureNew>) => {
                setAutomationInEdit(automation);
              }}
              crmOrgId={appCrmOrgId}
              transitionDuration={transitionDuration}
            />
          )}
          {automationInEdit && renderSingleAutomationDialog()}
        </AutomationAssignmentsContextProvider>
      )}

      {routingTab === RoutingTabs.scheduled && (
        <AutomationScheduledAssignmentsContextProvider>
          {showEmptyStateDialog && (
            <ScheduledAssignmentsEmptyStateDialog
              closeDialog={goToRoutingHome}
              transitionDuration={transitionDuration}
              onCreate={(object: string) => setAutomationInEdit({ objectName: object })}
              crmOrgId={appCrmOrgId}
            />
          )}
          {automationInEdit && renderSingleAutomationDialog()}
        </AutomationScheduledAssignmentsContextProvider>
      )}
      {routingTab === RoutingTabs.members && (
        <MembersDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.groups && (
        <GroupsDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
      {routingTab === RoutingTabs.territories && (
        <TerritoriesDialog closeDialog={goToRoutingHome} transitionDuration={transitionDuration} />
      )}
    </>
  );
};

export default RoutingPage;
