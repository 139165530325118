import { useDispatch, useSelector } from 'react-redux';
import {
  setHubspotOrgs,
  selectHubspotOrgs,
  selectIsLoadingHsOrgs,
  addOrUpdateHubspotOrg,
  selectConnectedHubspotOrg,
  deleteHubspotOrg as deleteHubspotOrgAction,
  startLoadingHubspotFields,
  loadHubspotFields as loadHubspotFieldsAction,
  selectHubspotOrgsHasLoaded,
  startLoadingHubspotForms,
  loadHubspotForms as loadHubspotFormsAction,
  loadHubspotPages as loadHubspotPagesAction,
  loadHubspotEmails as loadHubspotEmailsAction,
  setLoadingHubspotFieldsError,
  setLoadingHubspotFormsError,
  setLoadingHubspotPagesError,
  startLoadingHubspotPages,
  startLoadingHubspotEmails,
  setLoadingHubspotEmailsError,
} from '../../../../reducers/hubspotReducer';
import { useHubspotApiFacade } from '../../../../apis/facades/useHubspotApiFacade';
import { useRunOnce } from '../../../common/useRunOnce';
import { useCallback } from 'react';
import { HubspotField } from './hubspot.types';
import { telemetry } from '../../../../telemetry';
import { HubspotEventComponentType } from '@server/hubspot';

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

export const useHubspot = () => {
  const dispatch = useDispatch();
  const api = useHubspotApiFacade();
  const hubspotOrgs = useSelector(selectHubspotOrgs);
  const connectedHubspotOrg = useSelector(selectConnectedHubspotOrg);
  const isLoading = useSelector(selectIsLoadingHsOrgs);
  const hasLoadedHubspotOrgs = useSelector(selectHubspotOrgsHasLoaded);

  useRunOnce(() => {
    if (!hubspotOrgs && !isLoading) {
      getHubspotOrgs();
    }
  });

  const createHubspotOrg = useCallback(async () => {
    const hsOrgs = await api.create_hubspot_org();
    dispatch(addOrUpdateHubspotOrg(hsOrgs));
    return hsOrgs;
  }, [api, dispatch]);

  const getHubspotOrgs = useCallback(async () => {
    try {
      const hsOrgs = await api.get_hubspot_orgs();
      dispatch(setHubspotOrgs(hsOrgs));
      return hsOrgs;
    } catch (e) {
      telemetry.captureError(e);
    }
  }, [api, dispatch]);

  const getHubspotOrg = useCallback(
    async (hsOrgId: string) => {
      try {
        const hsOrg = await api.get_hubspot_org(hsOrgId);
        dispatch(addOrUpdateHubspotOrg(hsOrg));
        return hsOrg;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [api, dispatch],
  );

  const getHubspotRedirectToOAuthUrl = useCallback(
    ({ hubspotOrgId }: { hubspotOrgId: string }) =>
      `${SERVER_URL}/hubspot-connector/redirect-to-oauth?data=${hubspotOrgId}`,
    [],
  );

  const deleteHubspotOrg = useCallback(
    async (hsOrgId: string) => {
      try {
        await api.delete_hubspot_org();
        if (hubspotOrgs?.length) {
          dispatch(deleteHubspotOrgAction({ hsOrgId }));
        }
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [api, dispatch, hubspotOrgs?.length],
  );

  const deleteConnectedHubspotOrg = useCallback(async () => {
    if (connectedHubspotOrg) {
      await deleteHubspotOrg(connectedHubspotOrg.id);
    }
  }, [connectedHubspotOrg, deleteHubspotOrg]);

  const loadHubspotFields = useCallback(
    async (hsOrgId: string, objectName: string) => {
      dispatch(startLoadingHubspotFields());
      try {
        let fields = await api.get_hubspot_fields(hsOrgId, objectName);
        fields = fields.map(
          ({ description, fieldType, groupName, label, name, options, type }) => ({
            description,
            fieldType,
            groupName,
            label,
            name,
            options,
            type,
          }),
        ) as HubspotField[];

        dispatch(loadHubspotFieldsAction(fields));
        return fields;
      } catch (e) {
        dispatch(setLoadingHubspotFieldsError(e));
        throw e;
      }
    },
    [api, dispatch],
  );

  const loadHubspotForms = useCallback(
    async (hsOrgId: string) => {
      dispatch(startLoadingHubspotForms());
      try {
        const forms = await api.get_event_components(hsOrgId, HubspotEventComponentType.FORM);
        dispatch(loadHubspotFormsAction(forms.components));
      } catch (e) {
        dispatch(setLoadingHubspotFormsError(e));
        throw e;
      }
    },
    [api, dispatch],
  );

  const loadHubspotPages = useCallback(
    async (hsOrgId: string) => {
      dispatch(startLoadingHubspotPages());
      try {
        const pages = await api.get_event_components(hsOrgId, HubspotEventComponentType.PAGE);
        dispatch(loadHubspotPagesAction(pages.components));
      } catch (e) {
        dispatch(setLoadingHubspotPagesError(e));
        throw e;
      }
    },
    [api, dispatch],
  );

  const loadHubspotEmails = useCallback(
    async (hsOrgId: string) => {
      try {
        dispatch(startLoadingHubspotEmails());
        const emails = await api.get_event_components(hsOrgId, HubspotEventComponentType.EMAIL);
        dispatch(loadHubspotEmailsAction(emails.components));
      } catch (e) {
        dispatch(setLoadingHubspotEmailsError(e));
        throw e;
      }
    },
    [api, dispatch],
  );

  return {
    hubspotOrgs,
    connectedHubspotOrg,
    hasLoadedHubspotOrgs,
    createHubspotOrg,
    getHubspotOrgs,
    getHubspotOrg,
    getHubspotRedirectToOAuthUrl,
    deleteConnectedHubspotOrg,
    loadHubspotFields,
    loadHubspotForms,
    loadHubspotPages,
    loadHubspotEmails,
  };
};
