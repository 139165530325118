import { DateTime } from 'luxon';
import { BooleanSelector } from '../../../../common/rule-builder/selectors/BooleanSelector';
import { DateSelector } from '../../../../common/rule-builder/selectors/DateSelector';
import { DatetimeSelector } from '../../../../common/rule-builder/selectors/DatetimeSelector';
import { TextFieldSelector } from '../../../../common/rule-builder/selectors/TextFieldSelector';
import { HsPropertyTypes, HsFilterOperatorsOperators, HsOption } from '../hubspot.types';
import { MultipleSelect } from './MultipleSelect';

interface HubspotFieldsOperatorSelectorProps {
  hsPropertyType: HsPropertyTypes;
  operator: HsFilterOperatorsOperators;
  value?: string | number | boolean | string[];
  onChange: (value: string | number | boolean | string[]) => void;
  disabled?: boolean;
  options: HsOption[];
}

export const HubspotFieldsValueSelector = ({
  value,
  onChange,
  operator,
  hsPropertyType,
  disabled,
  options,
}: HubspotFieldsOperatorSelectorProps) => {
  if (
    [HsFilterOperatorsOperators.IS_KNOWN, HsFilterOperatorsOperators.IS_UNKNOWN].includes(operator)
  ) {
    return null;
  }

  switch (hsPropertyType) {
    // case HsPropertyTypes.JSON:
    // case HsPropertyTypes.OBJECT_COORDINATES:
    // case HsPropertyTypes.PHONE_NUMBER:
    case HsPropertyTypes.STRING: {
      return (
        <TextFieldSelector
          initialValue={value as string}
          fullWidth
          removeBorders
          onChange={(data) => {
            onChange(data);
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.NUMBER: {
      return (
        <TextFieldSelector
          initialValue={value as number}
          fullWidth
          removeBorders
          selectorType="number"
          onChange={(value) => {
            onChange(parseInt(value));
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.BOOL: {
      return (
        <BooleanSelector
          initialValue={value ? 'true' : 'false'}
          removeBorders
          onChange={(_value: string) => {
            onChange(_value === 'true');
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.DATE: {
      return (
        <DateSelector initialValue={value as string} shouldBeDisabled={false} onChange={onChange} />
      );
    }
    case HsPropertyTypes.DATE_TIME: {
      return (
        <DatetimeSelector
          value={value ? DateTime.fromSQL(value as string) : undefined}
          shouldBeDisabled={Boolean(disabled)}
          onChange={(value) => {
            onChange(value?.toSQL({ includeOffset: false }) ?? '');
          }}
        />
      );
    }
    case HsPropertyTypes.ENUMERATION: {
      const _value = JSON.parse((value as string) || '[]');
      return <MultipleSelect value={_value} onChange={onChange} options={options} />;
    }
  }
};
