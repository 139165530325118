import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import {
  ObjectTypesAutocompleteItem,
  ObjectTypesAutocomplete,
} from '../common/ObjectTypesAutocomplete';
import uniqueId from 'lodash/uniqueId';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';

export const ActionObjectTypesAutocomplete = ({
  _action,
  crmOrgId,
  onChange,
  disabled,
}: {
  _action: UpdateOrCreateAutomationAction;
  crmOrgId: string;
  onChange: (action: AutomationAction) => any;
  disabled?: boolean;
}) => {
  const { searchSweepFields } = useSweepFields();

  const value = _action.actionParams?.objectTypeName?.objectType;

  const setRequiredFields = (requiredFields: SweepField[]) => {
    return requiredFields.map((field) => {
      const action: AutomationActionField = {
        _fieldIds: [field.id as string],
        _id: uniqueId(),
        fieldType: field.fieldType || '',
        _isMandatory: true,
        value: '',
        _fieldInfo: field,
      };
      return action;
    });
  };

  const { objectTypes, isLoading } = useObjectTypesWithFetch({ crmOrgId });

  return (
    <ObjectTypesAutocomplete
      objectTypes={objectTypes}
      onChange={async (event: any, newValue: ObjectTypesAutocompleteItem | null) => {
        const { sweepFields: _sweepFields } = await searchSweepFields({
          crmOrgId,
          objectType: newValue?.objectType ? [newValue?.objectType] : undefined,
          includeDescriptionValueSetNameRequired: true,
        });
        const requiredFields = newValue
          ? _sweepFields[newValue.objectType].fields.filter((field) => {
              const isUpdatableOrCreatableCondition =
                field.properties.updateable ||
                (field.properties.createable && !field.properties.defaultedOnCreate); //typo comes from the original record

              return field.properties._required && isUpdatableOrCreatableCondition;
            })
          : [];

        onChange({
          ..._action,
          actionParams: {
            ..._action.actionParams,
            objectTypeName: {
              objectType: newValue?.objectType ?? '',
              label: newValue?.label ?? '',
            },
            _fields: newValue ? setRequiredFields(requiredFields) : undefined,
            relationsRecords: undefined,
            relationsRecordsToClone: undefined,
          },
        });
      }}
      isLoading={isLoading}
      value={value}
      disabled={disabled}
    />
  );
};
