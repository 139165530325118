import { useSelector } from 'react-redux';
import {
  selectConnectedCrmOrgs,
  selectCrmOrg,
  selectDefaultCreationEnvironment,
} from '../../components/pages/environments/environmentsReducer';
import { selectShouldRefresh } from '../../reducers/global/globalReducer';
import { useCallback, useEffect } from 'react';
import { useFetchGlobal } from './useFetchGlobal';
import useObjectTypes from '../useObjectTypes';
import { useFeatureToggle } from '../../components/common/useFeatureToggle';
import { useParams } from 'react-router';
import useUserInfo from '../useUserInfo';
import { useTags } from '../../components/documentation/dependencies/user-inputs/tags/useTags';
import useSweepNavigate from '../../components/common/useSweepNavigate';
import { appRoutes } from '../../constants/appRoutes';

const useGlobalDataFetcherListener = () => {
  const { crmOrgId: paramsCrmOrgId } = useParams<{ crmOrgId: string }>();
  const { updateDefaultCreationCrmOrgId } = useUserInfo();
  const { navigate } = useSweepNavigate();

  const currentDefaultCreationEnvId = useSelector(selectDefaultCreationEnvironment)?.id;
  const crmOrgId = paramsCrmOrgId ?? currentDefaultCreationEnvId;
  const crmOrgs = useSelector(selectConnectedCrmOrgs);

  useEffect(() => {
    if (paramsCrmOrgId && paramsCrmOrgId !== currentDefaultCreationEnvId) {
      if (crmOrgs.length > 0) {
        updateDefaultCreationCrmOrgId(paramsCrmOrgId);
      } else {
        navigate(appRoutes.getStarted);
      }
    }
  }, [
    crmOrgs,
    currentDefaultCreationEnvId,
    navigate,
    paramsCrmOrgId,
    updateDefaultCreationCrmOrgId,
  ]);

  const crmOrg = useSelector(selectCrmOrg(crmOrgId));

  const shouldRefresh = useSelector(selectShouldRefresh(crmOrg?.id ?? ''));

  const { sfObjectTypes, userInputsTags } = useFeatureToggle();
  const { fetchGlobal } = useFetchGlobal();
  const { fetchTags } = useTags();
  const { fetchCrmOrgObjectTypes, fetchCrmOrgSalesforceObjects } = useObjectTypes({
    crmOrgId: crmOrgId ?? '',
  });

  //TODO - consider adding "ObjectTypes" to global response
  const fetchData = useCallback(
    (crmOrgId: string) => {
      fetchGlobal({ crmOrgId });
      fetchCrmOrgObjectTypes(crmOrgId);
      sfObjectTypes && fetchCrmOrgSalesforceObjects(crmOrgId);
    },
    //fetchGlobal has to be in the dep array, or it is called with outdated properties (SWEEP-4670)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchGlobal],
  );

  //run on mount and every crmOrgId change
  useEffect(() => {
    if (crmOrgId) {
      fetchData(crmOrgId);

      if (userInputsTags) {
        fetchTags();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmOrgId, fetchData, userInputsTags]);

  //run on every shouldRefresh
  useEffect(() => {
    if (crmOrgId && shouldRefresh) {
      fetchData(crmOrgId);
    }
  }, [crmOrgId, shouldRefresh, fetchData]);
};

export default useGlobalDataFetcherListener;
