import { Box, Stack } from '@mui/material';
import {
  ConfigurationToName,
  ConfigurationType,
  configurationTypeToIcon,
  DependenciesContentType,
  NewDependencyProps,
} from '../dependencies/types';
import { SearchListItem } from './types';
import { colors, Tag, Typography } from '@sweep-io/sweep-design';
import { Virtuoso } from 'react-virtuoso';
import { TruncatedTextTooltip } from '../../common/TruncatedTextTooltip';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { CenteredRowWithGap } from './atoms/CenteredRowWithGap';
import { WrapperWithDotDivider } from './molecules/WrapperWithDotDivider';
import { highlightMatch } from '../../../lib/highlightMatch';
import pluralize from 'pluralize';
import { SingleSelectFilterPopoverOption } from '../../common/single-select-filter/SingleSelectFilterPopover';
import { ClampedText } from '../../common/ClampedText';
import { DialogLoader } from '../../common/dialogs/DialogLoader';
import { OPTION_ALL } from '../../common/single-select-filter/utils';
import { Connect } from '@sweep-io/sweep-design/dist/icons/Connect';
import { useFeatureToggle } from '../../common/useFeatureToggle';

interface UniversalSearchListProps {
  configurationItems: SearchListItem[];
  onListItemClick: (props: NewDependencyProps) => void;
  activeItemId?: string;
  searchTxt: string;
  filterOptions: SingleSelectFilterPopoverOption[];
  onSelectedFilterItem: (key?: ConfigurationType) => void;
  selectedFilterValue?: string;
  isLoadingDependency?: boolean;
}

export const UniversalSearchList = ({
  configurationItems,
  onListItemClick,
  activeItemId,
  searchTxt,
  filterOptions,
  selectedFilterValue,
  onSelectedFilterItem,
  isLoadingDependency,
}: UniversalSearchListProps) => {
  const { searchV2 } = useFeatureToggle();
  const { isExpandedMode } = useExpandedMode();
  const itemsCount = configurationItems.length;
  const selectedFilter = filterOptions.find(
    (filterOption) => filterOption.value === selectedFilterValue,
  );

  if (!itemsCount) {
    return <></>;
  }

  return (
    <Stack height="100%">
      <DialogLoader visible={isLoadingDependency} variant="light" />

      <Box p={2} pt={0} display="flex" alignItems="center" gap={1}>
        {!!selectedFilter && selectedFilter.label !== OPTION_ALL.label && (
          <Tag
            size="medium"
            color={colors.grey[200]}
            label={selectedFilter.label}
            onDelete={() => onSelectedFilterItem(undefined)}
          />
        )}
        <Typography color={colors.grey[700]} variant="body">
          {itemsCount} {pluralize('result', itemsCount)}
        </Typography>
      </Box>

      <Virtuoso
        style={{ height: '100%', width: '100%' }}
        data={configurationItems}
        itemContent={(index, item) => {
          return (
            <Box
              key={item.id}
              p={1.5}
              pl={2}
              gap={'6px'}
              display="flex"
              flexDirection="column"
              sx={{
                width: '100%',
                background: activeItemId === item.id ? colors.grey[100] : 'initial',
                '&:hover': { background: colors.grey[100], cursor: 'pointer' },
              }}
              onClick={() =>
                onListItemClick({
                  id: item.id,
                  name: item.name,
                  dependencyType: item.type,
                  parentType: item.parentType,
                  objectName: item.objectName,
                  clearHistory: true,
                  contentType:
                    searchV2 && item.match
                      ? DependenciesContentType.sourceCode
                      : DependenciesContentType.default,
                })
              }
            >
              <CenteredRowWithGap gap={0.5}>
                {isExpandedMode && (
                  <Stack alignItems="center" pr={1}>
                    {configurationTypeToIcon[item.parentType] ?? <Connect />}
                  </Stack>
                )}

                <TruncatedTextTooltip variant="body" turnOffElipsis={isExpandedMode}>
                  {!!item.match ? item.label : highlightMatch(item.label, searchTxt)}
                </TruncatedTextTooltip>
                <Box>
                  <Tag
                    color={colors.grey[200]}
                    label={ConfigurationToName[item.parentType] ?? item.parentType}
                  />
                </Box>
              </CenteredRowWithGap>

              <CenteredRowWithGap ml={isExpandedMode ? 3.5 : 0}>
                <TruncatedTextTooltip
                  variant="caption"
                  color={colors.grey[700]}
                  turnOffElipsis={isExpandedMode}
                >
                  API Name: {!!item.match ? item.name : highlightMatch(item.name)}
                </TruncatedTextTooltip>

                {item.type && <WrapperWithDotDivider txt={item.type} />}

                {isExpandedMode && (
                  <>
                    {item.objectLabel && (
                      <WrapperWithDotDivider
                        txt={
                          <Box
                            sx={{ '& .MuiChip-label': { color: `${colors.grey[700]} !important` } }}
                          >
                            <Tag label={item.objectLabel} color={colors.grey[100]} />
                          </Box>
                        }
                      />
                    )}

                    {item.lastModifiedAtAndBy && (
                      <WrapperWithDotDivider txt={item.lastModifiedAtAndBy} />
                    )}

                    {item.createdAtAndBy && <WrapperWithDotDivider txt={item.createdAtAndBy} />}
                  </>
                )}
              </CenteredRowWithGap>

              {!!item.match && (
                <ClampedText
                  text={highlightMatch(item.match, searchTxt)}
                  typographyVariant="caption"
                  textColor={colors.grey[700]}
                />
              )}
            </Box>
          );
        }}
      />
    </Stack>
  );
};
