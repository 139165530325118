import * as yup from 'yup';

export const roleValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required').min(3),
  description: yup.string().nullable().notRequired(),
  roles: yup.object().shape({
    alerts: yup.string().required(),
    automations: yup.string().required(),
    dedupMatching: yup.string().required(),
    funnels: yup.string().required(),
    rollups: yup.string().required(),
    assignments: yup.string().required(),
  }),
});
