import { Typography } from '@sweep-io/sweep-design';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { DataTableVariant } from '../../../common/table/TableTypes';
import { ConfigurationType } from '../../dependencies/types';
import { ReactNode } from 'react';
import { RuleType } from '../../ParserTypes';
import { SearchResponse } from '../../universal-search/types';

export const TableTypography = ({
  children,
  variant = DataTableVariant.narrow,
}: {
  children: string | ReactNode;
  variant?: DataTableVariant;
}) => {
  return (
    <>
      {typeof children === 'string' ? (
        <Typography
          variant={dataTableVariants[variant].fontVariant}
          color={dataTableVariants[variant].fontColor}
          whiteSpace="break-spaces"
          wordBreak="break-all"
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </>
  );
};

export type ConfigurationItemMap = {
  [key in ConfigurationType | RuleType]: ConfigurationItem[];
};

export const prepareConfigItemMapStructure = (items?: ConfigurationItemMap | SearchResponse) => {
  if (!items) {
    return;
  }

  return Object.keys(items).reduce((acc: any, key) => {
    const itemsOfKey = items[key as keyof (ConfigurationItemMap | SearchResponse)];

    acc[key] = itemsOfKey.map((item: any) => ({
      id: item.id ?? '',
      lastModified: item.annotations?.changedAt,
      label: item.label,
      type: item.type,
      flowType: item.flowType,
      objectName: item.objectName ?? '',
      objectLabel: item.objectLabel,
      name: item.name,
    }));
    return acc as ConfigurationItemMap;
  }, {});
};
