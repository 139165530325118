import Box from '@mui/material/Box/Box';
import { DataTableRow, DataTableVariant } from '../../common/table/TableTypes';
import { NoResults } from '../NoResults';
import { Typography } from '@sweep-io/sweep-design';
import { useRef, useCallback } from 'react';
import { FunnelFieldLabels } from '../../../constants/fieldsManagementConsts';
import { Percentage } from '../../common/Percentage';
import { dataTableVariants } from '../../common/table/dataTableVariants';
import { ChevronButton } from '../configuration-item-tables/shared/ChevronButton';
import { Utilization } from './Utilization';
import { OCCUPIED_SPACE_SINGLE_OBJECT_VIEW } from '../helpers';
import { onFieldClickProps } from '../useDocumentation';
import { FieldLabelWithNameInTooltip } from '../shared/FieldLabelWithNameInTooltip';
import { VirtualScrollDataTable } from '../../common/table/VirtualScrollDataTable';
import { DependenciesContentType, SelectedDependency } from '../dependencies/types';

interface FieldsTableProps {
  fields: FieldMetadataRecordProperties[];
  onFieldClick: (props: onFieldClickProps) => void;
  isManagePackageInstalled: boolean;
  objectName: string;
  firstHistoryItem?: SelectedDependency;
  crmOrgId: string;
}

const fieldsColumns = [
  { field: 'label', headerName: 'Name', width: '38%' },
  { field: 'type', headerName: 'Type', width: '100px' },
  { field: 'usage', headerName: 'Utilization', width: '100px' },
  { field: '__actions', headerName: '', width: '30px' },
];

const renderRows = ({
  items,
  isManagePackageInstalled,
  activeFieldName,
}: {
  items?: FieldMetadataRecordProperties[];
  isManagePackageInstalled: boolean;
  activeFieldName?: string;
}) => {
  if (!items) return [];

  return items?.map((row) => ({
    id: row.id,
    label: (
      <FieldLabelWithNameInTooltip name={row.name} label={row.label} isRollup={!!row.isRollup} />
    ),
    name: row.name,
    isActive: activeFieldName === row.name,
    type: (
      <Typography
        variant={dataTableVariants[DataTableVariant.narrow].fontVariant}
        whiteSpace="break-spaces"
      >
        {FunnelFieldLabels[row.type as SweepFieldTypes] ?? row.type}
      </Typography>
    ),
    usage: (
      <Percentage
        preCalculated={row.usage}
        active={isManagePackageInstalled}
        typographyVariant="caption"
      />
    ),
    __actions: <ChevronButton />,
    isClickable: true,
  }));
};

export const FieldsTable = ({
  fields,
  objectName,
  onFieldClick,
  isManagePackageInstalled,
  firstHistoryItem,
  crmOrgId,
}: FieldsTableProps) => {
  const containerRef = useRef<any>(null);
  const _columns = isManagePackageInstalled
    ? fieldsColumns
    : fieldsColumns.map((column) =>
        column.field === 'usage'
          ? {
              ...column,
              headerName: <Utilization columnName={column.headerName} />,
            }
          : column,
      );

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      onFieldClick({
        fieldName: row.name,
        objectName,
        crmOrgId,
        isRollup: !!fields.find((field) => field.name === row.name)?.isRollup,
        contentType: DependenciesContentType.default,
      });
    },
    [onFieldClick, objectName, fields, crmOrgId],
  );

  const rows = renderRows({
    items: fields,
    isManagePackageInstalled,
    activeFieldName: firstHistoryItem?.name,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        flexDirection: 'column',
        height: `calc(100vh - ${OCCUPIED_SPACE_SINGLE_OBJECT_VIEW})`,
        overflowY: 'auto',
        overflowX: 'hidden',
        pr: 2,
        pl: 2,
        position: 'relative',
      }}
    >
      <VirtualScrollDataTable
        containerRef={containerRef}
        columns={_columns}
        rows={rows}
        actionableButtonsOnHover={true}
        TableEmptyStateComponent={<NoResults />}
        onRowClick={onRowClick}
        variant={DataTableVariant.narrow}
      />
    </Box>
  );
};
