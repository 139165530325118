import { DialogContent } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { AutomationsForm } from './AutomationsForm';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { telemetry } from '../../telemetry';
import useSendOpenAutomationEvent from './useSendOpenAutomationEvent';

export type SingleAutomationDialogRef = {
  isPristine: () => boolean;
};

export interface SingleAutomationDialogProps {
  automation?: Partial<AutomationStructureNew>;
  onSaveOrCreate: (
    automation: AutomationStructureNew,
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  onCancel: () => any;
  isOpen: boolean;
  readonly?: boolean;
  crmOrgId: string;
  objectName: ObjectTypeValues;
  filterFunnelIds?: string[];
  isShowWideScreenPopup?: boolean;
}

export const SingleAutomationDialog = forwardRef<
  SingleAutomationDialogRef,
  SingleAutomationDialogProps
>(
  (
    {
      isOpen,
      automation,
      onSaveOrCreate,
      onCancel: closeDialog,
      readonly,
      crmOrgId,
      objectName,
      filterFunnelIds,
      isShowWideScreenPopup,
    },
    ref,
  ) => {
    const [isPristine, setIsPristine] = useState(true);
    const { openConfirm } = useConfirm();
    const [isDoneLoadingFunnelsDataForObject, setIsDoneLoadingFunnelsDataForObject] =
      useState(false);

    const [recordTypesAndFunnelsDataForObject, setRecordTypesAndFunnelsDataForObject] =
      useState<ParsedRecordTypesAndFunnelsObjectData>();

    const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();

    useEffect(() => {
      const getData = async () => {
        setIsDoneLoadingFunnelsDataForObject(false);
        try {
          const parsedDataForObject = await getObjectFunnelsAndRecordTypes({
            crmOrgId,
            objectName,
          });

          setRecordTypesAndFunnelsDataForObject(
            parsedDataForObject ?? {
              name: objectName,
              label: objectName,
              recordTypes: [],
              funnels: [],
            },
          );
          setIsDoneLoadingFunnelsDataForObject(true);
        } catch (e) {
          setIsDoneLoadingFunnelsDataForObject(false);
          telemetry.captureError(e);
        }
      };
      getData();
    }, [crmOrgId, getObjectFunnelsAndRecordTypes, objectName]);

    const _onCancel = useCallback(async () => {
      if (isPristine) {
        closeDialog();
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          closeDialog();
        }
      }
    }, [isPristine, openConfirm, closeDialog]);

    useImperativeHandle(ref, () => {
      return {
        isPristine() {
          return isPristine;
        },
      };
    }, [isPristine]);

    useSendOpenAutomationEvent(automation);

    return (
      <DialogOnCanvas
        open={isOpen}
        onClose={_onCancel}
        blockFully={!isPristine && !isShowWideScreenPopup}
        forceWideScreen={isShowWideScreenPopup}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <AutomationsForm
            key={`${automation?.automationId}${automation?.versionId}`}
            objectName={objectName}
            readonly={readonly}
            crmOrgId={crmOrgId}
            onBackButtonClick={_onCancel}
            onPristineChange={setIsPristine}
            onSaveOrCreate={async (automation: AutomationStructureNew, crmOrgIds: string[]) => {
              return onSaveOrCreate(automation, crmOrgIds);
            }}
            initialAutomationsJson={automation}
            recordTypesAndFunnelsDataForObject={recordTypesAndFunnelsDataForObject}
            filterFunnelIds={filterFunnelIds}
            isDoneLoading={isDoneLoadingFunnelsDataForObject}
          />
        </DialogContent>
      </DialogOnCanvas>
    );
  },
);
