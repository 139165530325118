import { useCallback, useState } from 'react';
import { useCrmOrgsApiFacade } from '../../../../apis/facades/useCrmOrgsApiFacade';
import { useSweepFieldsApiFacade } from '../../../../apis/facades/useSweepFieldsApiFacade';
import colors from '@sweep-io/sweep-design/dist/colors';
import {
  SlackActionButtonTextField,
  customButtonSx,
  showOnlyUpdatable,
  SlackActionRow,
} from './common';
import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import FieldLabel from '../../../common/FieldLabel';
import { SweepObjectSelector } from '../../../common/sweep-object-field-selectors/SweepObjectSelector';
import uniqueId from 'lodash/uniqueId';
import { SelectorValueTypes } from '../../../../types/enums/SelectorValueTypes';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { SlackActionType } from '../../../../types/enums/SlackActionType';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../../common/fieldsSelectors/NestedFieldsSelector';
import SweepSelect from '../../../common/SweepSelect';
import { SingleRecordTypeSelector } from '../SingleRecordTypeSelector';
import { SlackFields } from '../SlackFields';
import { useRunOnce } from '../../../common/useRunOnce';

export const SlackActionCreate = ({
  crmOrgId,
  objectName,
  onUpdateAction,
  action,
  idx,
  buttonAction,
}: {
  crmOrgId: string;
  objectName: string;
  onUpdateAction: (action: CreateRecordSlackAction, index: number) => any;
  action: CreateRecordSlackAction;
  idx: number;
  buttonAction: React.ReactNode;
}) => {
  const { get_sweepFields } = useSweepFieldsApiFacade();
  const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();
  const [recordTypesList, setRecordTypesList] = useState<ShortParserRecordType[]>([]);
  const [relationShipFields, setRelationShipFields] = useState<SweepFieldObjectReferenceDto[]>([]);
  const showRecordTypeSelector = recordTypesList.length > 1;
  const showRelationShipFieldsSelector = relationShipFields.length > 1;

  const loadRecordTypes = useCallback(
    async (objectName: string) => {
      const { recordTypes } = await getObjectFunnelsAndRecordTypes({
        crmOrgId,
        objectName,
      });

      const _recordTypes = recordTypes.filter((rt) => rt.name !== '__MASTER__');
      setRecordTypesList(_recordTypes);
      return _recordTypes;
    },
    [crmOrgId, getObjectFunnelsAndRecordTypes],
  );

  const loadRelationShipFields = useCallback(
    async (_objectName: string) => {
      const fields = await get_sweepFields({
        objectType: [_objectName],
        crmOrgId,
        includeDescriptionValueSetNameRequired: true,
      });
      const isCreateable = (filedId: string) => {
        const elem = fields?.sweepFields[0]?.fields?.find((el) => el.id === filedId);
        return elem?.properties?.createable;
      };
      const relationShipFields =
        fields.sweepFields[0].references?.filter((f) => f.objectNames.includes(objectName)) || [];
      setRelationShipFields(relationShipFields.filter((el) => isCreateable(el.fieldId)));
      return { fields, relationShipFields };
    },
    [crmOrgId, get_sweepFields, objectName],
  );

  useRunOnce(() => {
    if (action.objectTypeName.objectType) {
      loadRecordTypes(action.objectTypeName.objectType);
    }
    if (action.relationshipField) {
      loadRelationShipFields(action.objectTypeName.objectType);
    }
  });
  const addingFieldsIsDisabled = !action.objectTypeName.objectType;
  const plusIconColor = addingFieldsIsDisabled ? colors.grey[500] : colors.blue[500];

  return (
    <>
      <SlackActionRow>
        {buttonAction}
        <Box>
          <FieldLabel label="Object" markLabelAsRequired />
          <SweepObjectSelector
            crmOrgId={crmOrgId}
            value={action.objectTypeName.objectType}
            onChange={async (value) => {
              setRecordTypesList([]);
              const newAction: CreateRecordSlackAction = {
                buttonText: action.buttonText,
                fields: [],
                type: SlackActionType.CREATE_RECORD,
                objectTypeName: { objectType: value, label: value },
              };

              onUpdateAction(newAction, idx);
              const [recordTypes, { fields, relationShipFields }] = await Promise.all([
                loadRecordTypes(value),
                loadRelationShipFields(value),
              ]);
              const requiredFields = fields.sweepFields[0].fields.filter((field) => {
                const isUpdatableOrCreatableCondition =
                  field.properties.updateable ||
                  (field.properties.createable && !field.properties.defaultedOnCreate);
                return field.properties._required && isUpdatableOrCreatableCondition;
              });

              let relationshipField: [string] | undefined = undefined;
              if (relationShipFields.length > 0) {
                if (relationShipFields.length === 1) {
                  relationshipField = [relationShipFields[0].fieldId];
                } else {
                  relationshipField = [''];
                }
              }

              let recordType: ShortParserRecordType | undefined = undefined;
              if (recordTypes.length > 0) {
                if (recordTypes.length === 1) {
                  recordType = recordTypes[0];
                } else {
                  recordType = {
                    label: '',
                    name: '',
                  };
                }
              }

              const newFields: AutomationActionField[] = requiredFields.map((f) => {
                const newField: AutomationActionField = {
                  _fieldIds: [f.id || ''],
                  _isMandatory: f.isRequired,
                  _id: uniqueId(),
                  fieldType: f.fieldType,
                  value: '',
                  valueType: SelectorValueTypes.LITERAL,
                };
                return newField;
              });

              onUpdateAction(
                {
                  ...newAction,
                  type: SlackActionType.CREATE_RECORD,
                  fields: newFields,
                  objectTypeName: {
                    objectType: value,
                    label: value, // TODO:
                    recordType,
                  },
                  relationshipField,
                },
                idx,
              );
            }}
          />
        </Box>
        {showRecordTypeSelector && (
          <Box>
            <FieldLabel label="Record Type" markLabelAsRequired />
            <SingleRecordTypeSelector
              recordTypesList={recordTypesList}
              crmOrgId={crmOrgId}
              objectName={action.objectTypeName.objectType}
              recordType={action.objectTypeName.recordType}
              onChange={(recordType) => {
                onUpdateAction(
                  {
                    ...action,
                    objectTypeName: {
                      ...action.objectTypeName,
                      recordType,
                    },
                  },
                  idx,
                );
              }}
            />
          </Box>
        )}
        {showRelationShipFieldsSelector && (
          <Box>
            <FieldLabel label="Relationship field" markLabelAsRequired />
            <SweepSelect
              SelectProps={{
                placeholder: 'Relationship field',
                value: action.relationshipField?.[0] ?? '',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const value = event.target.value as string;
                  onUpdateAction(
                    {
                      ...action,
                      relationshipField: [value],
                    },
                    idx,
                  );
                },
              }}
            >
              {relationShipFields.map((field) => (
                <MenuItem key={field.fieldId} value={field.fieldId}>
                  {field.refName}
                </MenuItem>
              ))}
            </SweepSelect>
          </Box>
        )}
        <SlackActionButtonTextField action={action} idx={idx} onUpdateAction={onUpdateAction} />
      </SlackActionRow>
      <SlackActionRow>
        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <FieldLabel label={'Fields to create'} markLabelAsRequired={true} />
          <SlackFields
            actionFields={action.fields.map((f) => ({
              isRequired: f._isMandatory,
              fieldIds: f._fieldIds,
              fieldData: f,
            }))}
            onChange={(_, fields) => {
              onUpdateAction({ ...action, fields }, idx);
            }}
          />
          <Box>
            <NestedFieldsSelector
              filterBy={showOnlyUpdatable}
              disableLookupItemsResolve={true}
              crmOrgId={crmOrgId}
              objectType={action.objectTypeName.objectType}
              customButtonStartIcon={<Plus color={plusIconColor} />}
              useCustomButton
              customButtonText="Add field"
              nestedPath={[]}
              customButtonSx={customButtonSx}
              onChange={(sweepField: NestedFieldsField) => {
                if (action.type === SlackActionType.CREATE_RECORD) {
                  onUpdateAction(
                    {
                      ...action,
                      fields: [
                        ...action.fields,
                        {
                          _fieldIds: sweepField.fieldIds,
                          _isMandatory: false,
                          _id: uniqueId(),
                          fieldType: sweepField.fieldType,
                          value: '',
                          valueType: SelectorValueTypes.LITERAL,
                        },
                      ],
                    },
                    idx,
                  );
                }
              }}
              readonly={addingFieldsIsDisabled}
            />
          </Box>
        </FormControl>
      </SlackActionRow>
    </>
  );
};
