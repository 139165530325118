import {
  GetStartedActionIdDerived,
  GetStartedDto,
  GetStartedSection,
  RequireSfConnectionType,
  GetStartedSectionId,
  GetStartedBox,
} from './getStartedTypes';
import {
  Automations,
  Binoculars,
  Settings,
  Rule,
  SlackLogo,
  Users,
  Rocket,
  Funnels,
  Chats,
  Target,
} from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import DocumentationNavigate from './svgs/documentationNavigate.svg?react';
import AutomationsNavigate from './svgs/automationsNavigate.svg?react';
import SlackNavigate from './svgs/slackNavigate.svg?react';
import RollupNavigate from './svgs/rollupNavigate.svg?react';
import AssignmentsNavigate from './svgs/assignmentsNavigate.svg?react';
import DedupeNavigate from './svgs/dedupeNavigate.svg?react';
import VisualizeNavigate from './svgs/visualizeNavigate.svg?react';
import OptimizeNavigate from './svgs/optimizeNavigate.svg?react';
import ChangeFeedNavigate from './svgs/changeFeedNavigate.svg?react';
import setupManagedPackage from './svgs/setupManagedPackage.png';
import setupPermissionSet from './svgs/setupPermissionSet.png';
import { GET_STARTED_EVENTS } from '../../../services/events';
import { STORYLANE_URLS } from '../../common/url-consts';
import { ActionStatus, GetStartedActionId } from '@server/get-started';
import { appRoutes } from '../../../constants/appRoutes';
import RoutingBox from './svgs/routingBox.svg?react';
import AlertsBox from './svgs/alertsBox.svg?react';
import DedupBox from './svgs/dedupBox.svg?react';
import AutomationsBox from './svgs/automationsBox.svg?react';

export const DefaultDerivedStatus: { [actionId in GetStartedActionIdDerived]: ActionStatus } = {
  [GetStartedActionIdDerived.setupManagedPackage]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionIdDerived.setupPermissionSet]: ActionStatus.NOT_COMPLETED,
};

export const DefaultUserLevelStatus: GetStartedDto = {
  [GetStartedActionId.documentationNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.automationsNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.slackNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.rollupsNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.assignmentsNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.dedupeNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.visualizeNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.optimizeNavigate]: ActionStatus.NOT_COMPLETED,
  [GetStartedActionId.changeFeedNavigate]: ActionStatus.NOT_COMPLETED,
};

const imgStyle = { width: '400px', height: '230px', borderRadius: '8px' };

//Note: the order matters! (defined by product)
export const SECTIONS: GetStartedSection[] = [
  {
    id: GetStartedSectionId.DOCUMENTATION,
    adminOnly: false,
    label: 'Access AI-Powered Salesforce Documentation',
    icon: <Binoculars />,
    iconBgColor: colors.gum[300],
    actions: [
      {
        id: GetStartedActionId.documentationNavigate,
        label: '',
        text: (
          <>
            Uncover dependencies, solve complexities, and access field utilization tracking so you
            can see what's happening in your Salesforce environment instantly. Click any element for
            details on how it's used and what it connects to. It's like a map of your Salesforce,
            always at your fingertips.
          </>
        ),
        img: <DocumentationNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.documentationNavigate,
        tourUrl: STORYLANE_URLS.documentation,
        tourBiEvent: GET_STARTED_EVENTS.documentationTakeTour,
        buttonText: 'Get Started',
      },
    ],
  },
  {
    id: GetStartedSectionId.AUTOMATIONS,
    adminOnly: false,
    label: 'Create your first custom automation',
    icon: <Automations />,
    iconBgColor: colors.seafoam[100],
    actions: [
      {
        id: GetStartedActionId.automationsNavigate,
        text: (
          <>
            Whether you want to update a close date, create a task, or generate renewal
            opportunities to ensure future revenue, Sweep makes it easy to set up Automations that
            improve your efficiency.
          </>
        ),
        img: <AutomationsNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.automationsNavigate,
        tourUrl: STORYLANE_URLS.automations,
        tourBiEvent: GET_STARTED_EVENTS.automationsTakeTour,
        buttonText: 'Create Automation',
      },
    ],
  },
  {
    id: GetStartedSectionId.SLACK,
    adminOnly: false,
    label: 'Create your first custom Slack Alert',
    icon: <SlackLogo />,
    iconBgColor: colors.mint[100],
    actions: [
      {
        id: GetStartedActionId.slackNavigate,
        text: (
          <>
            It's simple to create Slack Alerts with Sweep. Turn key pipeline events like stage
            changes, record updates, important dates, and time in stage into actionable
            notifications with just a few clicks. Keep the entire team updated in real time.
          </>
        ),
        img: <SlackNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.slackNavigate,
        tourUrl: STORYLANE_URLS.slack,
        tourBiEvent: GET_STARTED_EVENTS.slackTakeTour,
        buttonText: 'Create Alert',
      },
    ],
  },
  {
    id: GetStartedSectionId.ROLLUP,
    adminOnly: false,
    label: 'Create your first rollup',
    icon: <Rule />,
    iconBgColor: colors.lilac[300],
    actions: [
      {
        id: GetStartedActionId.rollupsNavigate,
        text: (
          <>
            Create rollups for any object relationship, whether master-detail or lookup, eliminating
            mid-configuration frustration. Now you can focus on extracting valuable insights, not
            navigating technical hurdles.
          </>
        ),
        img: <RollupNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.rollupsNavigate,
        tourUrl: STORYLANE_URLS.rollups,
        tourBiEvent: GET_STARTED_EVENTS.rollupsTakeTour,
        buttonText: 'Create Rollup',
      },
    ],
  },
  {
    id: GetStartedSectionId.ASSIGNMENTS,
    adminOnly: false,
    label: 'Create your first assignment rule',
    icon: <Users />,
    iconBgColor: colors.gum[300],
    actions: [
      {
        id: GetStartedActionId.assignmentsNavigate,
        text: (
          <>
            Easily create and manage assignment rules, groups, and territories for any Salesforce
            object to automatically route the right record to the right team.
          </>
        ),
        img: <AssignmentsNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.assignmentNavigate,
        tourUrl: STORYLANE_URLS.routing,
        tourBiEvent: GET_STARTED_EVENTS.assignmentTakeTour,
        buttonText: 'Create Rule',
      },
    ],
  },
  {
    id: GetStartedSectionId.DEDUPE,
    adminOnly: false,
    label: 'Create your first Dedupe or Matching rule',
    icon: <Target />,
    iconBgColor: colors.blush[300],
    actions: [
      {
        id: GetStartedActionId.dedupeNavigate,
        text: (
          <>
            Avoid hours of data cleanup by creating deduplication and matching rules. Design
            multiple rules for any combination of leads, contacts, and accounts. Configure easily to
            include tie breakers, filters, and timing control to support your data needs.
          </>
        ),
        img: <DedupeNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.dedupeNavigate,
        tourUrl: STORYLANE_URLS.dedupeAndMatching,
        tourBiEvent: GET_STARTED_EVENTS.dedupeTakeTour,
        buttonText: 'Create Rule',
      },
    ],
  },
  {
    id: GetStartedSectionId.VISUALIZE,
    adminOnly: false,
    label: 'Visualize your sales motion',
    icon: <Funnels />,
    iconBgColor: colors.sky[300],
    actions: [
      {
        id: GetStartedActionId.visualizeNavigate,
        text: (
          <>
            Establish a source of Salesforce truth, instantly. Leverage Sweep's visual workspace to
            build, prototype, and create the best revenue motions for your team.
          </>
        ),
        img: <VisualizeNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.visualizeNavigate,
        tourUrl: STORYLANE_URLS.templates,
        tourBiEvent: GET_STARTED_EVENTS.visualizeTakeTour,
        buttonText: 'Get Started',
      },
    ],
  },
  {
    id: GetStartedSectionId.OPTIMIZE,
    adminOnly: false,
    label: 'Optimize a sales process',
    icon: <Chats />,
    iconBgColor: '#FFCE00',
    actions: [
      {
        id: GetStartedActionId.optimizeNavigate,
        text: (
          <>
            With Sweep's visual workspace, you can plan, build, and optimize all of your revenue
            motions in one place. Plus, you can collaborate easily with with cross-functional
            stakeholders, access feedback via comments, and then deploy changes to Salesforce in a
            click.
          </>
        ),
        img: <OptimizeNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.optimizeNavigate,
        tourUrl: STORYLANE_URLS.templates,
        tourBiEvent: GET_STARTED_EVENTS.optimizeTakeTour,
        buttonText: 'Get Started',
      },
    ],
  },
  {
    id: GetStartedSectionId.CHANGE_FEED,
    adminOnly: false,
    label: 'Track every change in your Salesforce org, automatically',
    icon: <Settings />,
    iconBgColor: colors.sun[300],
    actions: [
      {
        id: GetStartedActionId.changeFeedNavigate,
        text: (
          <>
            Sweep’s comprehensive Change Feed allows you to see every change in your environment in
            real-time. Plus, you'll receive immediate notifications for updates that require your
            attention.
          </>
        ),
        img: <ChangeFeedNavigate />,
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: true,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
        biEvent: GET_STARTED_EVENTS.changeFeedNavigate,
        tourUrl: STORYLANE_URLS.changeFeed,
        tourBiEvent: GET_STARTED_EVENTS.changeFeedTakeTour,
        buttonText: 'Get Started',
      },
    ],
  },
  {
    id: GetStartedSectionId.SETUP,
    adminOnly: true,
    label: 'Complete your setup',
    icon: <Rocket />,
    iconBgColor: colors.sky[100],
    actions: [
      {
        id: GetStartedActionIdDerived.setupManagedPackage,
        label: 'Install Sweep Managed Package',
        text: (
          <>
            Install our Managed Package to deploy directly from Sweep.
            <br />
            It takes just a few minutes to uncover the power of Salesforce with Sweep.
          </>
        ),
        img: (
          <img src={setupManagedPackage} alt={'Install Sweep Managed Package'} style={imgStyle} />
        ),
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: false,
        requireManagedPackage: false,
        status: ActionStatus.NOT_COMPLETED,
      },
      {
        id: GetStartedActionIdDerived.setupPermissionSet,
        label: 'Assign Sweep Permission Set Group',
        text: `Assign Sweep permission set group to Salesforce users to test Sweep, or take the full advantage of triggering Sweep configuration in Salesforce.`,
        img: (
          <img
            src={setupPermissionSet}
            alt={'Assign Sweep Permission Set Group'}
            style={imgStyle}
          />
        ),
        requiredSfConnection: RequireSfConnectionType.ANY,
        requireFetched: false,
        requireManagedPackage: true,
        status: ActionStatus.NOT_COMPLETED,
      },
    ],
  },
];

export const ADMIN_TOOLS_BOXES: GetStartedBox[] = [
  {
    name: 'Documentation',
    text: 'Activate AI-Powered Salesforce Documentation',
    navigateTo: appRoutes.documentation,
    biEvent: GET_STARTED_EVENTS.documentationNavigate,
  },
  {
    name: 'Change Feed',
    text: 'Track every change in Salesforce, automatically',
    navigateTo: appRoutes.sfChangeFeed,
    biEvent: GET_STARTED_EVENTS.changeFeedNavigate,
  },
  {
    name: 'Rollups',
    text: 'Create rollups for any object in Salesforce',
    navigateTo: appRoutes.rollups,
    biEvent: GET_STARTED_EVENTS.rollupsNavigate,
  },
  {
    name: 'Process Visualization',
    text: 'Bring your processes to life in a visual workspace',
    navigateTo: appRoutes.funnelMapsPage,
    biEvent: GET_STARTED_EVENTS.visualizeNavigate,
  },
];

export const ADDITIONAL_CASES_BOXES: GetStartedBox[] = [
  {
    name: 'Lead Routing',
    text: 'Automate assignments',
    navigateTo: appRoutes.routing,
    biEvent: GET_STARTED_EVENTS.assignmentNavigate,
    buttonText: 'Get started',
    image: <RoutingBox />,
  },
  {
    name: 'Actionable Alerts',
    text: 'Create custom alerts for Slack or Teams',
    navigateTo: appRoutes.alerts,
    biEvent: GET_STARTED_EVENTS.slackNavigate,
    buttonText: 'Get started',
    image: <AlertsBox />,
  },
  {
    name: 'Dedupe & Matching',
    text: 'Maintain data hygiene by eliminating  duplicate records',
    navigateTo: appRoutes.dedup,
    biEvent: GET_STARTED_EVENTS.dedupeNavigate,
    buttonText: 'Get started',
    image: <DedupBox />,
  },
  {
    name: 'Automations',
    text: 'Build powerful Salesforce automations in minutes',
    navigateTo: appRoutes.automations,
    biEvent: GET_STARTED_EVENTS.automationsNavigate,
    buttonText: 'Get started',
    image: <AutomationsBox />,
  },
];
