import { Box } from '@mui/material';
import { AutomationsEmptyState } from './AutomationsEmptyState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SingleAutomationDialog, SingleAutomationDialogRef } from './SingleAutomationDialog';
import { AutomationsList } from './AutomationsList';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { FlexBox } from '../common/FlexBox';
import { SearchInput } from '../common/SearchInput';
import { SortDisplayTypes, SweepSort } from '../common/SweepSort';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationSortOption, getAddAutomationButtonByType, sortAutomations } from './helper';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { SingleDedupMatchingDialog } from '../routing/SingleDedupMatchingDialog';
import { AutomationType } from '../../types/enums/AutomationType';
import { useCanvasAutomationFilters } from './useCanvasAutomationFilters';
import pluralize from 'pluralize';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import _noop from 'lodash/noop';
import { AutomationsTemplatesWrapper } from './AutomationsTemplatesWrapper';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import { PageTemplateCanvasDialog, PageTemplateFullPage } from '../pages/pages-templates';
import { DedupTemplateContent } from '../pages/canvas-pages/dedup-and-matching/DedupTemplateContent';
import { CompleteSetupToDeploy } from '../common/deploy-button/complete-sf-setup/CompleteSetupToDeploy';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { useAutomationsContext } from './AutomationsContext';
import useQueryParams from '../../hooks/useQueryParams';
import useSweepNavigate from '../common/useSweepNavigate';
import useCompileTemplate from '../../hooks/useCompileTemplate';

interface AutomationDialogContentProps {
  onSaveOrCreate: (
    automationJson: AutomationStructureNew,
    crmOrgIds: string[],
    isEdit: boolean,
  ) => any;
  onToggleActivation: (
    automationJson: AutomationStructureNew,
    deployToOrgIds: string[],
    isActive: boolean,
  ) => any;
  onDeleteAutomation: (automationId: string, automationType: AutomationType) => any;
  automations: AutomationStructureNew[];
  crmOrgId: string;
  recordTypesData?: RecordTypesData;
  funnelsData?: FunnelsData;
  sortOptions?: AutomationSortOption[];
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string) => void;
  disableCanvasTemplates?: boolean;
  onCreateData?: { newElement: Partial<AutomationStructureNew> };
  filterFunnelIds?: string[];
  excludeAutomationsNotInFunnelMapObjects?: boolean;
  objectType?: string;
  hideFilters?: boolean;
  onDeployAutomation: (
    deployAutomationDto: AutomationStructureNew,
    deployToOrgIds: string[],
  ) => any;
  isShowWideScreenPopup?: boolean;
}

const automationsCountTextMap = {
  [AutomationFormVariant.ASSIGNMENT_RULES]: 'Assignment',
  [AutomationFormVariant.PLAYBOOK_ALERT]: 'Alert',
  [AutomationFormVariant.DEDUP_MATCHING]: 'Automation',
  [AutomationFormVariant.AUTOMATIONS]: 'Automation',
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: 'Scheduled assignment',
  [AutomationFormVariant.SCHEDULED_REPORTS]: 'Scheduled report',
};

export const AutomationDialogContent = ({
  crmOrgId,
  automations,
  onSaveOrCreate,
  recordTypesData,
  funnelsData,
  onToggleActivation,
  onDeleteAutomation,
  sortOptions,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
  disableCanvasTemplates,
  onCreateData,
  filterFunnelIds,
  excludeAutomationsNotInFunnelMapObjects,
  objectType,
  hideFilters,
  onDeployAutomation,
  isShowWideScreenPopup,
}: AutomationDialogContentProps) => {
  const SingleAutomationDialogRef = useRef<SingleAutomationDialogRef>(null);
  const [automationInEdit, setAutomationInEdit] = useState<Partial<AutomationStructureNew>>();
  const [dedupMatchingTypeInNew, setDedupMatchingTypeInNew] = useState<DedupMatchingType>();
  const [isForceWideScreenPopup, setIsForceWideScreenPopup] = useState(false);
  const hasNoAutomations = automations.length === 0;
  const dataByVariant = useAutomationsContext();
  const { automationVariant } = dataByVariant;
  const [isTemplateScreenOpen, setIsTemplateScreenOpen] = useState(false);
  const { getSweepFieldsById } = useSweepFields();
  const { assignmentsFte } = useFeatureToggle();

  const query = useQueryParams();
  const forceAutomationId = query.get('forceAutomationId');
  const forceVersionId = query.get('forceVersionId');
  const { removeQueryParams } = useSweepNavigate();

  useEffect(() => {
    if (forceAutomationId) {
      const automation = automations.find(
        (automation) => automation.automationId === forceAutomationId,
      );
      if (automation) {
        setAutomationInEdit(automation);
      }
      removeQueryParams(['forceAutomationId', 'forceVersionId']);
    }
  }, [automations, forceAutomationId, removeQueryParams, forceVersionId]);

  const closeAutomationDialog = useCallback(() => {
    setAutomationInEdit(undefined);
    setDedupMatchingTypeInNew(undefined);
    setIsForceWideScreenPopup(false);
  }, []);

  const _onSaveOrCreate = useCallback(
    async (automationJson: AutomationStructureNew, crmOrgIds: string[]) => {
      const savedAutomation = await onSaveOrCreate(
        automationJson,
        crmOrgIds,
        !!automationJson?.automationId,
      );
      closeAutomationDialog();
      return savedAutomation;
    },
    [closeAutomationDialog, onSaveOrCreate],
  );

  const openNewAutomationWithObject = useCallback(
    (objectName: string, type?: DedupMatchingType) => {
      if (type) {
        setDedupMatchingTypeInNew(type);
      }
      setAutomationInEdit({ ...onCreateData?.newElement, objectName });
    },
    [onCreateData?.newElement],
  );

  const changeAutomationModal = useCallback((automationItem: AutomationStructureNew) => {
    setAutomationInEdit(automationItem);
  }, []);

  const { openConfirm } = useConfirm();

  const onBeforeCloseModal = useCallback(
    async (automationItem: AutomationStructureNew) => {
      const isPristine = SingleAutomationDialogRef.current?.isPristine();
      if (isPristine) {
        changeAutomationModal(automationItem);
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          changeAutomationModal(automationItem);
        }
      }
    },
    [changeAutomationModal, openConfirm],
  );

  const setSearch = useCallback(
    (search: string) => {
      setSelectedFilters({ searchText: search });
    },
    [setSelectedFilters],
  );

  const { filteredAutomations: newFilteredAutomations, clearFilters: newClearFilters } =
    useCanvasAutomationFilters({
      automations,
      search: selectedFilters.searchText,
      setSearch,
    });

  const [filteredAutomations, clearFilters] = [newFilteredAutomations, newClearFilters];

  const [sortBy, setSortBy] = useState(sortOptions ? sortOptions[0].value : undefined);
  const selectedSortOption = sortOptions?.find((option) => option.value === sortBy);
  const automationsToDisplay = sortAutomations({
    sortBy,
    automations: filteredAutomations,
    selectedSortOption,
  });

  const compileTemplate = useCompileTemplate();

  const showRegularForm =
    automationVariant !== AutomationFormVariant.DEDUP_MATCHING &&
    automationInEdit?.type !== AutomationType.Dedupe &&
    automationInEdit?.type !== AutomationType.Matching;

  const isShowTemplatesForVariant =
    !disableCanvasTemplates &&
    dataByVariant.templateSupport &&
    //feature toggle check: (this condition should be removed)
    (automationVariant === AutomationFormVariant.ASSIGNMENT_RULES ? assignmentsFte : true);
  const renderDedupTemplates = AutomationFormVariant.DEDUP_MATCHING === automationVariant;

  const getFieldsList = async (fieldsList: string[]) => {
    await getSweepFieldsById({
      fieldIds: fieldsList,
    });
  };

  const getNewButtonWithTemplates = () => {
    const getNewButton = () => {
      return (
        <Button
          disabled={!!isTemplateScreenOpen || !!automationInEdit}
          startIconName="Plus"
          size="small"
          onClick={() => {
            setIsTemplateScreenOpen(true);
          }}
        >
          New
        </Button>
      );
    };

    if (isShowTemplatesForVariant) {
      return (
        <>
          {getNewButton()}
          {isTemplateScreenOpen && (
            <AutomationsTemplatesWrapper
              title={`Create your next ${dataByVariant.nameInMessageSingular}`}
              onCancel={() => setIsTemplateScreenOpen(false)}
              isFullPage={false}
              automationVariant={automationVariant}
              crmOrgId={crmOrgId}
              onSelectTemplate={(item: AutoTemplatesStruct) => {
                const automationToEdit = compileTemplate(item.automation);
                setAutomationInEdit(automationToEdit);
                getFieldsList(item.fieldsList);
                setIsTemplateScreenOpen(false);
              }}
              createEmptyButton={getAddAutomationButtonByType({
                variant: automationVariant,
                props: {
                  disabled: !!automationInEdit,
                  onSelect: (object: string) => {
                    openNewAutomationWithObject(object);
                    setIsTemplateScreenOpen(false);
                  },
                  crmOrgId,
                  buttonText: 'Get started',
                  excludeAutomationsNotInFunnelMapObjects,
                  funnelsData,
                  recordTypesData,
                  automationVariant,
                  isEmptyState: false,
                  onAddClick: _noop,
                  variant: 'outlined',
                },
              })}
            />
          )}
        </>
      );
    }
    if (!disableCanvasTemplates && renderDedupTemplates) {
      return (
        <>
          {getNewButton()}
          <PageTemplateCanvasDialog
            isOpen={isTemplateScreenOpen}
            onCancel={() => setIsTemplateScreenOpen(false)}
            title={`Create your next ${dataByVariant.nameInMessageSingular}`}
          >
            <DedupTemplateContent
              onSelect={(objectType, type) => {
                openNewAutomationWithObject(objectType, type);
              }}
            />
          </PageTemplateCanvasDialog>
        </>
      );
    }

    return getAddAutomationButtonByType({
      variant: automationVariant,
      props: {
        disabled: !!automationInEdit,
        onSelect: openNewAutomationWithObject,
        crmOrgId,
        buttonText: 'New',
        excludeAutomationsNotInFunnelMapObjects,
        funnelsData,
        recordTypesData,
        automationVariant,
        isEmptyState: false,
        onAddClick: _noop,
      },
    });
  };

  const renderEmptyAutomationsState = () => {
    if (isShowTemplatesForVariant) {
      return (
        <AutomationsTemplatesWrapper
          isFullPage={true}
          automationVariant={automationVariant}
          crmOrgId={crmOrgId}
          title={`Create your first ${dataByVariant.nameInMessageSingular}`}
          onSelectTemplate={(item: AutoTemplatesStruct) => {
            setAutomationInEdit(item.automation);
            getFieldsList(item.fieldsList);
            setIsForceWideScreenPopup(true);
          }}
          createEmptyButton={getAddAutomationButtonByType({
            variant: automationVariant,
            props: {
              disabled: !!automationInEdit,
              onSelect: (object: string) => {
                openNewAutomationWithObject(object);
                setIsForceWideScreenPopup(true);
              },
              crmOrgId,
              buttonText: 'Get started',
              excludeAutomationsNotInFunnelMapObjects,
              funnelsData,
              recordTypesData,
              automationVariant,
              isEmptyState: false,
              onAddClick: _noop,
              variant: 'outlined',
            },
          })}
        />
      );
    } else if (renderDedupTemplates) {
      return (
        <PageTemplateFullPage title={`Create your first ${dataByVariant.nameInMessageSingular}`}>
          <DedupTemplateContent onSelect={openNewAutomationWithObject} />
        </PageTemplateFullPage>
      );
    } else {
      return (
        <AutomationsEmptyState
          disabled={!!automationInEdit}
          onSelect={openNewAutomationWithObject}
          crmOrgId={crmOrgId}
          title={dataByVariant.emptyStateTitle}
          image={dataByVariant.emptyStateImage}
          excludeAutomationsNotInFunnelMapObjects={excludeAutomationsNotInFunnelMapObjects}
          defaultObjectType={objectType}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
        />
      );
    }
  };

  return (
    <>
      {hasNoAutomations && renderEmptyAutomationsState()}

      {!hasNoAutomations && (
        <>
          <FlexBox sx={{ alignItems: 'center', flexDirection: 'row', gap: 1, mb: 2, mt: 1 }}>
            <Box flex={1}>
              <SearchInput
                TextFieldProps={{
                  disabled: !!automationInEdit,
                  value: selectedFilters.searchText,
                  placeholder: 'Search',
                  onChange: (e) => {
                    setSelectedFilters({ searchText: e.target.value });
                  },
                  fullWidth: true,
                }}
                withFixedMagnifyingGlassIcon={true}
                onClearButtonClick={() => setSelectedFilters({ searchText: '' })}
              />
            </Box>
            <Box>
              {onCreateData ? (
                <Button
                  disabled={!!automationInEdit}
                  startIconName="Plus"
                  size="small"
                  onClick={() => {
                    setAutomationInEdit(onCreateData.newElement);
                  }}
                >
                  New
                </Button>
              ) : (
                getNewButtonWithTemplates()
              )}
            </Box>
          </FlexBox>
          {!hideFilters && (
            <FlexBox
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Typography variant="body" color={colors.grey[700]}>
                {pluralize(
                  automationsCountTextMap[automationVariant],
                  automationsToDisplay.length,
                  true,
                )}
              </Typography>
              {sortOptions && sortBy && (
                <Box>
                  <SweepSort
                    selectedValue={sortBy}
                    onChange={setSortBy}
                    options={sortOptions}
                    displayType={SortDisplayTypes.Label_Only}
                  />
                </Box>
              )}
            </FlexBox>
          )}

          {automationsToDisplay.length === 0 && <NoSearchResult clearFilters={clearFilters} />}

          {automationsToDisplay.length > 0 && (
            <Box>
              <CompleteSetupToDeploy entityName={dataByVariant.nameInMessage} />
              <AutomationsList
                automationVariant={automationVariant}
                crmOrgId={crmOrgId}
                selectedId={automationInEdit?.automationId}
                automationList={automationsToDisplay}
                onToggle={(
                  automationJson: AutomationStructureNew,
                  deployToOrgIds: string[],
                  isActive: boolean,
                ) => {
                  onToggleActivation(automationJson, deployToOrgIds, isActive);
                }}
                onDeleteAutomation={(automationId: string, automationType: AutomationType) => {
                  if (automationInEdit && automationInEdit.automationId === automationId) {
                    closeAutomationDialog();
                  }
                  onDeleteAutomation(automationId, automationType);
                }}
                onEdit={(automationItem: AutomationStructureNew) => {
                  if (SingleAutomationDialogRef.current) {
                    onBeforeCloseModal(automationItem);
                  } else {
                    setAutomationInEdit(automationItem);
                  }
                }}
                onDuplicate={(automationItem: AutomationStructureNew) => {
                  if (SingleAutomationDialogRef.current) {
                    onBeforeCloseModal(automationItem);
                  } else {
                    setAutomationInEdit(automationItem);
                  }
                }}
                onDeploy={(automationItem: AutomationStructureNew, crmOrgIds: string[]) => {
                  onDeployAutomation(automationItem, crmOrgIds);
                }}
                setHoveredItem={setHoveredItem}
              />
            </Box>
          )}
        </>
      )}

      {automationInEdit && showRegularForm && (
        <SingleAutomationDialog
          ref={SingleAutomationDialogRef}
          isOpen={!!automationInEdit}
          automation={automationInEdit}
          onSaveOrCreate={async (automationJson, crmOrgIds) => {
            const savedAutomationJson = await onSaveOrCreate(
              automationJson,
              crmOrgIds,
              !!automationJson?.automationId,
            );
            if (savedAutomationJson?.isError) {
              return {};
            }
            closeAutomationDialog();
            return savedAutomationJson;
          }}
          onCancel={closeAutomationDialog}
          crmOrgId={crmOrgId}
          objectName={automationInEdit.objectName ?? ''}
          filterFunnelIds={filterFunnelIds}
          isShowWideScreenPopup={isShowWideScreenPopup || isForceWideScreenPopup}
        />
      )}
      {automationInEdit && !showRegularForm && (
        <SingleDedupMatchingDialog
          ref={SingleAutomationDialogRef}
          isOpen={!!automationInEdit}
          automation={automationInEdit}
          newDedupMatchingType={
            dedupMatchingTypeInNew ??
            (
              automationInEdit?.automationDetails?.actions?.[0] as
                | DeduplicationAutomationAction
                | MatchingAutomationAction
            )?.actionParams?.dmType
          }
          onSaveOrCreate={_onSaveOrCreate}
          onCancel={closeAutomationDialog}
          crmOrgId={crmOrgId}
          objectName={automationInEdit.objectName ?? ''}
          automationVariant={automationVariant}
          forceWideScreen={hasNoAutomations}
        />
      )}
    </>
  );
};

const NoSearchResult = ({ clearFilters }: { clearFilters: () => void }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} mt={2}>
      <Typography variant="body" color={colors.grey[800]}>
        No results found
      </Typography>
      <Button variant="link" onClick={clearFilters}>
        Clear filters
      </Button>
    </Box>
  );
};
