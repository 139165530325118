import { Stack, Box } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { getPaywallBlockData } from './helper';
import { PaywallSource } from '../paywallTypes';
import usePaywall from '../usePaywall';

const PaywallVerticalLayout = ({
  paywallSource,
  variant = 'regular',
}: {
  paywallSource?: PaywallSource;
  variant: 'regular' | 'wide';
}) => {
  const { ctaCallback } = usePaywall();
  const data = getPaywallBlockData(paywallSource);
  const { header, subHeader, cta, imageVertical, imageVerticalWide } = data ?? {};

  return (
    <Stack width="100%" height="100%">
      <Stack justifyContent="space-between" height="100%" gap="96px">
        <Stack
          gap="40px"
          sx={{
            '.MuiTypography-h1-bold': {
              lineHeight: '32px',
            },
            '.MuiTypography-body': {
              lineHeight: '24px',
            },
          }}
        >
          <Stack gap={1}>
            <Box maxWidth="70%">
              <Typography variant="h1-bold" color={colors.night[900]}>
                {header}
              </Typography>
            </Box>
            <Box maxWidth="80%">
              <Typography variant="body" color={colors.grey[800]}>
                {subHeader}
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Button onClick={() => ctaCallback(paywallSource)}>{cta}</Button>
          </Box>
        </Stack>
        <Box
          sx={{
            borderRadius: '16px',
            overflow: 'hidden',
            svg: {
              width: '100%',
            },
          }}
        >
          {variant === 'regular' ? imageVertical : imageVerticalWide}
        </Box>
      </Stack>
    </Stack>
  );
};

export default PaywallVerticalLayout;
