import { ConfigurationType, DependenciesContentType } from './types';
import { DependenciesHeader } from './DependenciesHeader';
import { DependenciesContent } from './DependenciesContent';
import CommentsList from './user-inputs/comments/CommentsList';
import { useSelector } from 'react-redux';
import { selectDependenciesConfigurationItem } from '../../../reducers/documentationReducer';
import { SourceView } from './source-view/SourceView';

interface DependenciesContentWrapperProps {
  crmOrgId: string;
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  historyLength: number;
  stepNamesUsedInCanvas: string[];
  displayItemType: string;
}

export const DependenciesContentWrapper = ({
  crmOrgId,
  configurationItem,
  type,
  historyLength,
  stepNamesUsedInCanvas,
  displayItemType,
}: DependenciesContentWrapperProps) => {
  const contentType = useSelector(selectDependenciesConfigurationItem)?.contentType;

  if (!contentType) {
    return <></>;
  }

  return (
    <>
      <DependenciesHeader
        withCommentsButton={contentType === DependenciesContentType.default}
        crmOrgId={crmOrgId}
        configurationItem={configurationItem}
        type={type}
        historyLength={historyLength}
        stepNamesUsedInCanvas={stepNamesUsedInCanvas}
        displayItemType={displayItemType}
      />

      {contentType === DependenciesContentType.default && (
        <DependenciesContent
          key={configurationItem.id}
          crmOrgId={crmOrgId}
          configurationItem={configurationItem}
          type={type}
          displayItemType={displayItemType}
        />
      )}

      {contentType === DependenciesContentType.comments && (
        <CommentsList configurationId={configurationItem.id} crmOrgId={crmOrgId} />
      )}

      {contentType === DependenciesContentType.sourceCode && (
        <SourceView configurationItem={configurationItem} crmOrgId={crmOrgId} />
      )}
    </>
  );
};
