import { Skeleton, Stack } from '@mui/material';
import { AutomationDialogContent } from './AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import { useDispatch, useSelector } from 'react-redux';
import useAutomationsCanvasInteractions from '../pages/canvas-pages/useAutomationsCanvasInteractions';
import { useCallback } from 'react';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { selectLeftPanelFilters, setLeftPanelFiltersValues } from '../../reducers/leftPanelReducer';
import { WithEnvConnectionErrorPanel } from '../pages/canvas-pages/united-canvas/canvas-page/error-panel/EnvConnectionErrorPanel';
import { getPaywallSourceFromAutomationVariant } from '../dashboard/paywall/sales-activated-trial/helper';
import PaywallVerticalLayout from '../dashboard/paywall/sales-activated-trial/PaywallVerticalLayout';
import usePaywall, { PaywallAction } from '../dashboard/paywall/usePaywall';

interface AutomationDialogProps {
  onSaveOrCreate: (
    automationJson: AutomationStructureNew,
    crmOrgIds: string[],
    isEdit: boolean,
  ) => any;
  onToggleActivation: (
    automationJson: AutomationStructureNew,
    deployToOrgIds: string[],
    isActive: boolean,
  ) => any;
  onDeleteAutomation: (automationId: string, automationType: AutomationType) => any;
  automations: AutomationStructureNew[];
  crmOrgId: string;
  funnelMap: FunnelMap;
  isLoading?: boolean;
  automationVariant: AutomationFormVariant;
  onDeployAutomation: (
    deployAutomationDto: AutomationStructureNew,
    deployToOrgIds: string[],
  ) => any;
  onClose?: () => void;
  disableCanvasTemplates?: boolean;
}

export const AutomationDialog = ({
  crmOrgId,
  automations,
  onSaveOrCreate,
  funnelMap,
  isLoading,
  onToggleActivation,
  onDeleteAutomation,
  automationVariant,
  onDeployAutomation,
  onClose,
  disableCanvasTemplates,
}: AutomationDialogProps) => {
  const selectedFilters = useSelector(selectLeftPanelFilters);
  const { setHoveredItem } = useAutomationsCanvasInteractions();
  const dispatch = useDispatch();

  const setSelectedFilters = useCallback(
    (filters: Partial<AutomationDialogFilters>) => {
      dispatch(setLeftPanelFiltersValues(filters));
    },
    [dispatch],
  );

  const headerContent = (
    <ConfigurationCanvasPanelHeader onClose={onClose}>
      {automationVariant === AutomationFormVariant.PLAYBOOK_ALERT ? 'Alerts' : 'Automations'}
    </ConfigurationCanvasPanelHeader>
  );

  const { paywallAction } = usePaywall();
  const isPaywallV2Block = paywallAction === PaywallAction.TALK_TO_SALES;
  const paywallSource = getPaywallSourceFromAutomationVariant(automationVariant);

  if (isLoading) {
    return (
      <ConfigurationCanvasLeftPanel>
        <WithEnvConnectionErrorPanel>
          {headerContent}
          <ConfigurationCanvasPanelContent>
            <Stack spacing={2} direction="column">
              <Skeleton variant="text" width={'80%'} height={46} />
              <Skeleton variant="rectangular" width={296} height={42} />
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={100} />
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={100} />
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={100} />
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={100} />
            </Stack>
          </ConfigurationCanvasPanelContent>
        </WithEnvConnectionErrorPanel>
      </ConfigurationCanvasLeftPanel>
    );
  }

  return (
    <ConfigurationCanvasLeftPanel
      sx={{
        '.configuration-canvas-panel-content': {
          overflow: 'hidden',
        },
      }}
    >
      <WithEnvConnectionErrorPanel>
        {headerContent}
        <ConfigurationCanvasPanelContent>
          {isPaywallV2Block && (
            <PaywallVerticalLayout paywallSource={paywallSource} variant="regular" />
          )}
          {!isPaywallV2Block && (
            <AutomationDialogContent
              onSaveOrCreate={onSaveOrCreate}
              onToggleActivation={onToggleActivation}
              onDeleteAutomation={onDeleteAutomation}
              automations={automations}
              crmOrgId={crmOrgId}
              recordTypesData={funnelMap.recordTypesData}
              funnelsData={funnelMap.funnelsData}
              sortOptions={automationsSortOptions}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setHoveredItem={setHoveredItem}
              onDeployAutomation={onDeployAutomation}
              disableCanvasTemplates={disableCanvasTemplates}
            />
          )}
        </ConfigurationCanvasPanelContent>
      </WithEnvConnectionErrorPanel>
    </ConfigurationCanvasLeftPanel>
  );
};
