import { Box } from '@mui/material';
import { Button, Tag } from '@sweep-io/sweep-design';

interface CreateButtonProps {
  onCreate?: (label: string) => void;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  currentSearch: string;
}

export const CreateButton = ({ onCreate, currentSearch, setIsOpen }: CreateButtonProps) => (
  <Box
    p={0.5}
    display="flex"
    gap={0.5}
    alignItems="center"
    sx={{ '& > button': { minWidth: 0 }, cursor: 'pointer' }}
    onClick={() => {
      onCreate && onCreate(currentSearch);
      setIsOpen(false);
    }}
  >
    <Button variant="link" type="secondary" size="tiny">
      Create
    </Button>
    <Tag label={currentSearch} size="small" />
  </Box>
);
