import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import { useState } from 'react';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { AssignmentToType } from '../../types/enums/AssignmentToType';
import { ReferenceObjectType } from '../../types/enums/ReferenceObjectType';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';

interface AssignmentOwnershipSelectorProps {
  crmOrgId: string;
  objectType: string;
  assignTo?: Partial<AssignmentsAssignTo>;
  readonly?: boolean;
  onChange: (assignTo: Partial<AssignmentsAssignTo>) => any;
}

export const AssignmentOwnershipSelector = ({
  crmOrgId,
  objectType,
  assignTo = {},
  readonly,
  onChange,
}: AssignmentOwnershipSelectorProps) => {
  const { searchSweepFields } = useSweepFields();
  const [fieldMappingObject] = useState(assignTo?.fieldMapping);
  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  const getDefaultOwnerId = async () => {
    const { sweepFields } = await searchSweepFields({
      objectType: [objectType],
      crmOrgId,
    });
    const fields = sweepFields[objectType].fields;
    return fields.find((el) => el.sweepFieldName === 'Owner ID');
  };

  useRunOnceWhenTruthy(
    async () => {
      const ownerField = await getDefaultOwnerId();
      ownerField &&
        onChange({
          ...assignTo,
          fieldMapping: {
            ...assignTo.fieldMapping,
            toField: {
              fieldIds: [ownerField.id ?? ''],
            },
          },
        });
    },
    !fieldMappingObject || fieldMappingObject?.toField === undefined,
  );

  const showOnlyUpdatable = (field: SweepField) =>
    field.properties?.updateable !== false && field.fieldType !== SweepFieldTypes.serviceid;

  const getObjectForReference = () => {
    if (assignTo.type === AssignmentToType.Queue) {
      return ReferenceObjectType.Group;
    }
    return ReferenceObjectType.User;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '14px',
        alignItems: 'center',
      }}
    >
      <Typography variant="body">Choose ownership field:</Typography>
      <Box
        sx={{
          borderRadius: '4px',
          border: `1px solid ${colors.grey[300]}`,
          position: 'relative',
        }}
      >
        <NestedFieldsSelector
          filterBy={showOnlyUpdatable}
          readonly={readonly}
          isReferencedValue={true}
          restrictReferencesToObject={getObjectForReference()}
          disableLookupItemsResolve={true}
          placeholder={'Choose field'}
          crmOrgId={crmOrgId || ''}
          objectType={objectType}
          nestedPath={getEnrichedNestedPath(assignTo?.fieldMapping?.toField?.fieldIds)}
          onChange={(sweepField: NestedFieldsField) => {
            onChange({
              ...assignTo,
              fieldMapping: {
                ...assignTo.fieldMapping,
                toField: {
                  fieldIds: sweepField.fieldIds,
                },
              },
            });
          }}
        />
      </Box>
    </Box>
  );
};
