import usePaywall, { PaywallAction } from '../components/dashboard/paywall/usePaywall';
import useQueryParams from './useQueryParams';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';
import {
  ACTIVATE_TRIAL_COOKIE_NAME,
  ACTIVATE_TRIAL_QUERY_PARAM,
} from '../components/dashboard/paywall/paywallConsts';
import { useCookies } from 'react-cookie';
import { setUserSignUpSource } from '../services/mixpanel';
import useSweepNavigate from '../components/common/useSweepNavigate';
import { PaywallSource } from '../components/dashboard/paywall/paywallTypes';

const useAutoActivateTrial = () => {
  const { startTrial, paywallAction } = usePaywall();
  const [cookies, , removeCookie] = useCookies();
  const query = useQueryParams();
  const { removeQueryParams } = useSweepNavigate();

  const activateTrialCookie = cookies[ACTIVATE_TRIAL_COOKIE_NAME];
  const activateTrialQuery = query.has(ACTIVATE_TRIAL_QUERY_PARAM);
  const shouldActivateTrial = activateTrialQuery || activateTrialCookie;
  const canStartTrial = paywallAction === PaywallAction.START_TRIAL;

  useRunOnceWhenTruthy(() => {
    setUserSignUpSource('Sales');
    //TODO change to 'auto' SWEEP-5282
    startTrial(PaywallSource.AUTO);
    //cleanup:
    removeCookie(ACTIVATE_TRIAL_COOKIE_NAME, { path: '/' });
    removeQueryParams([ACTIVATE_TRIAL_QUERY_PARAM]);
  }, shouldActivateTrial && canStartTrial);
};

export default useAutoActivateTrial;
