import { Box, Link, Menu, MenuItem } from '@mui/material';
import {
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
  Button,
  colors,
  IconButton,
  Typography,
} from '@sweep-io/sweep-design';
import { CloudCross, MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import { useCrmOrgs } from '../useCrmOrgs';
import { selectUserById } from '../../../../reducers/accountUsersReducer';
import { FetchStatus } from '@server/fetch_status.type';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { OrgDot } from '../../../common/OrgDot';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { EnvironmentTypes } from '../EnvironmentTypeEnum';
import { DeleteEnvCard } from './DeleteEnvCard';
import { FactoryResetCard } from './FactoryResetCard';
import { UNAUTHORIZED_FETCH_TOOLTIP } from '../../../../constants';
import WithPermissions from '../../../common/WithPermissions';
import { EditableLabel, EditableLabelRef } from '../../../common/EditableLabel';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { isOrgConnectionError } from '../../../../hooks/global-reducer/environmentsHelper';
import { selectConnectedCrmOrgs } from '../environmentsReducer';
import useUserInfo from '../../../../hooks/useUserInfo';
import { useNavigateToEnv } from './useNavigateToEnv';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { ACTIONS_EVENTS } from '../../../../services/events';
import { telemetry } from '../../../../telemetry';
import { getCrmOrgConnectionErrorData } from '../../get-started/connect-org/getCrmOrgConnectionErrorData';

export const EnvironmentCard = ({ env, allowDelete }: { env: CrmOrg; allowDelete: boolean }) => {
  const { orgFactoryReset } = useFeatureToggle();
  const [menuTarget, setMenuTarget] = useState<null | HTMLElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addNotification } = useSweepNotifications();
  const envs = useSelector(selectConnectedCrmOrgs);
  const defaultCreationCrmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { updateDefaultCreationCrmOrgId } = useUserInfo();

  const editableLabelRef = useRef<EditableLabelRef>(null);
  const [isResetting, setIsResetting] = useState(false);

  const { fetchOrg, removeCrmOrg, renameCrmOrg, connectOrg } = useCrmOrgs();
  const { navigateToEnvIfAppropriate } = useNavigateToEnv();

  const creatorUserInfo = useSelector(selectUserById(env.createdById));

  const hasError = isOrgConnectionError(env.fetchStatus);

  const closeMenu = () => {
    setIsDeleting(false);
    setIsResetting(false);
    setMenuTarget(null);
  };

  const confirmDeleteHandler = useCallback(async () => {
    const envType = env.isMain ? 'Production' : 'Sandbox';
    const biEvent = {
      name: ACTIONS_EVENTS.removeEnv,
      props: { type: envType },
    };
    try {
      let _env: CrmOrg | undefined;
      if (defaultCreationCrmOrgId === env.id) {
        _env = envs.find((e) => e.id !== env.id);
        if (_env) {
          await updateDefaultCreationCrmOrgId(_env.id);
          navigateToEnvIfAppropriate(_env.id);
          await removeCrmOrg(env.id, biEvent);
          closeMenu();
          return;
        }
      }
      await removeCrmOrg(env.id, biEvent);
    } catch (e) {
      addNotification({
        message: 'Unknown error',
        variant: SweepNotificationVariant.Error,
      });
    }
  }, [
    addNotification,
    defaultCreationCrmOrgId,
    env.id,
    env.isMain,
    envs,
    navigateToEnvIfAppropriate,
    removeCrmOrg,
    updateDefaultCreationCrmOrgId,
  ]);

  const confirmFactoryResetHandler = async () => {
    await fetchOrg(env, true);
    closeMenu();
  };
  const [envName, setEnvName] = useState(env.name);

  const confirmRenameHandler = useCallback(
    async (name: string) => {
      setEnvName(name);
      try {
        await renameCrmOrg(env.id, name.trim());
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Unknown error',
          variant: SweepNotificationVariant.Error,
          keepOpen: true,
        });
        setEnvName(env.name);
      }
    },
    [addNotification, env.id, env.name, renameCrmOrg],
  );

  const reconnectHandler = async () => {
    const result = await connectOrg({
      crmOrgToReconnect: env,
      type: env.isSandbox ? EnvironmentTypes.Sandbox : EnvironmentTypes.Production,
    });
    if (result.result === 'error') {
      const { description, title } = getCrmOrgConnectionErrorData({
        error: result.error,
      });
      addNotification({
        message: title,
        details: description.join(' '),
        variant: SweepNotificationVariant.Error,
      });
    }
  };

  const refreshHandler = async () => {
    await fetchOrg(env);
  };

  if (isDeleting) {
    return <DeleteEnvCard onCancel={closeMenu} onConfirm={confirmDeleteHandler} />;
  }

  if (isResetting && orgFactoryReset) {
    return <FactoryResetCard onCancel={closeMenu} onConfirm={confirmFactoryResetHandler} />;
  }

  return (
    <Box
      py={2}
      px={2}
      mx={-2}
      sx={{
        '.sweep__env-action': {
          position: 'absolute',
          right: 0,
          display: 'none',
        },
        '&:hover': {
          backgroundColor: colors.grey[100],
          '.sweep__env-action': {
            display: 'block',
          },
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <EditableLabel
            value={envName}
            onValueConfirm={confirmRenameHandler}
            padding="0px 8px 0px 0px"
            inputSx={{ ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-medium'] }}
            ref={editableLabelRef}
          />
          {env.isMain && <OrgDot styles={{ top: '1px' }} />}
        </Box>
        <Box gap={0.5} display="flex" alignItems="center">
          {hasError && <CloudCross />}
          <IconButton size="tiny" variant="flat" onClick={(ev) => setMenuTarget(ev.currentTarget)}>
            <MoreActionsVertical />
          </IconButton>
        </Box>
      </Box>
      <Box my={0.5}>
        <Typography variant="body">Created by {creatorUserInfo?.name}</Typography>
      </Box>
      <Box display="flex" position="relative" justifyContent="space-between" alignItems="center">
        <Typography variant="body">
          Last refreshed <ReactTimeAgo date={new Date(env.updatedAt)} locale="en-US" />
        </Typography>
        {env.fetchStatus === FetchStatus.Fetched && (
          <div className="sweep__env-action">
            <WithPermissions
              permissionStr={['create:org:fetch-job']}
              disabledTooltip={UNAUTHORIZED_FETCH_TOOLTIP}
            >
              <Button size="small" variant="link" onClick={refreshHandler}>
                Refresh
              </Button>
            </WithPermissions>
          </div>
        )}
        {[FetchStatus.Fetching, FetchStatus.InitialFetching].includes(env.fetchStatus) && (
          <CenteredCircularProgress circularProgressProps={{ size: 25 }} />
        )}
        {hasError && (
          <div className="sweep__env-action">
            <Button size="small" variant="link" onClick={reconnectHandler}>
              Reconnect
            </Button>
          </div>
        )}
      </Box>
      <Menu anchorEl={menuTarget} open={!!menuTarget} onClose={() => setMenuTarget(null)}>
        <MenuItem
          onClick={() => {
            editableLabelRef.current?.edit();
            closeMenu();
          }}
        >
          Rename
        </MenuItem>
        {orgFactoryReset && <MenuItem onClick={() => setIsResetting(true)}>Factory Reset</MenuItem>}
        <MenuItem>
          <Link href={env.instanceUrl} target="_blank" underline="none">
            <Typography variant="body" color={colors.blue[500]}>
              Go to Environment
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem
          disabled={
            env.fetchStatus === FetchStatus.Fetching ||
            env.fetchStatus === FetchStatus.InitialFetching ||
            !allowDelete
          }
          onClick={() => setIsDeleting(true)}
        >
          <Typography variant="body" color={colors.blush[500]}>
            Remove Environment
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
