import { useCallback } from 'react';
import { Field } from 'ts-force';
import { useSweepApi } from '../sweep';
import buildURLQuery from '../../lib/buildURLQuery';
import { FunnelField } from '../../constants/fieldsManagementConsts';
import { useDispatch } from 'react-redux';
import { assignFieldsLabelsFromSweepFields } from '../../sweep-fields/sweepFieldsLabelsReducer';

export type GetSweepFieldsIdsByNamesResponse = {
  fieldIds: string[][];
};

export type SFFieldWithReadOnly = Field & { readVersion?: ReadOnlyStandardField };

export interface SweepFieldsDto {
  objectType: string;
  fields: SweepFieldInterface[];
  references?: SweepFieldObjectReferenceDto[];
  queryFields?: string[];
}

export interface SweepFieldsDtoResponse {
  complete: boolean;
  sweepFields: SweepFieldsDto[];
}

export enum FieldTypeGroup {
  Text = 'text',
  Checkbox = 'checkbox',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  DateTime = 'dateTime',
  FieldReference = 'fieldReference',
  UserFieldReference = 'userFieldReference',
  Email = 'email',
}

export interface SweepFieldsGetParams {
  objectType?: string[];
  fieldIds?: string[];
  includeSiblings?: boolean;
  detectLeadingCandidates?: boolean;
  typeGroups?: FieldTypeGroup[];
  crmOrgId?: string;
  includeDescriptionValueSetNameRequired?: boolean;
}

export const useSweepFieldsApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  return {
    get_sweepFields: useCallback(
      async (query: SweepFieldsGetParams) => {
        const { objectType, fieldIds, typeGroups, ...rest } = query;
        const _objectType = objectType && objectType.join(',');
        const _fieldIds = fieldIds && fieldIds.join(',');
        const _typeGroups = typeGroups && typeGroups.join(',');

        const searchParams = buildURLQuery({
          ...rest,
          objectType: _objectType,
          fieldIds: _fieldIds,
          typeGroups: _typeGroups,
        });
        const sweepFieldsDtoResponse = (
          await sweepApi.get(`/sweep_fields${searchParams ? '?' + searchParams : ''}`)
        ).data as SweepFieldsDtoResponse;

        dispatch(
          assignFieldsLabelsFromSweepFields({
            sweepFields: sweepFieldsDtoResponse.sweepFields.flatMap(
              (sweepFields) => sweepFields.fields,
            ),
          }),
        );
        return sweepFieldsDtoResponse;
      },
      [dispatch, sweepApi],
    ),
    get_sweepField: useCallback(
      async (fieldId: string, crmOrgId?: string, readOnly: boolean = false) => {
        const response = await sweepApi.get(
          `/sweep_fields/${fieldId}?crmOrgId=${crmOrgId}&includeReadOnly=${readOnly}`,
        );
        return response.data as { readVersion?: ReadOnlyStandardField };
      },
      [sweepApi],
    ),
    get_sweepFieldsIdsByNames: useCallback(
      async ({ crmOrgId, fieldNames }: { fieldNames: string[]; crmOrgId: string }) => {
        const searchParams = buildURLQuery({
          fieldNames: fieldNames.join(','),
          crmOrgId,
        });
        const response = await sweepApi.get(`/sweep_fields/ids-by-names?${searchParams}`);
        return response.data as GetSweepFieldsIdsByNamesResponse;
      },
      [sweepApi],
    ),

    post_sweepField: useCallback(
      async ({
        crmOrgId,
        field,
        draft,
      }: {
        crmOrgId: string;
        field: SweepField;
        draft?: boolean;
      }) => {
        const response = await sweepApi.post(
          `/sweep_fields?${buildURLQuery({ crmOrgId, draft })}`,
          field,
        );
        const sweepField = response.data as SweepField;
        dispatch(
          assignFieldsLabelsFromSweepFields({
            sweepFields: [sweepField],
          }),
        );
        return sweepField;
      },
      [dispatch, sweepApi],
    ),

    put_sweepField: useCallback(
      async ({
        fieldId,
        field,
        crmOrgId,
        enableDeduceValuesOrder,
      }: {
        fieldId: string;
        crmOrgId?: string;
        field: SweepField | FunnelField;
        enableDeduceValuesOrder?: boolean;
      }) => {
        const query = buildURLQuery({ crmOrgId });
        const payload = {
          field,
          enableDeduceValuesOrder,
        };
        const response = await sweepApi.put(`/sweep_fields/${fieldId}?${query}`, payload);
        const sweepField = response.data as SweepField;

        dispatch(
          assignFieldsLabelsFromSweepFields({
            sweepFields: [sweepField],
          }),
        );

        return sweepField;
      },
      [dispatch, sweepApi],
    ),
    delete_sweepField: useCallback(
      async ({ field }: { field: SweepField }) => {
        await sweepApi.delete(`/sweep_fields`, { data: field });
      },
      [sweepApi],
    ),
    post_refreshSweepFieldValueSet: useCallback(
      async ({
        fieldId,
        crmOrgId,
        refreshFromCrmOrgId,
      }: {
        fieldId: string;
        crmOrgId: string;
        refreshFromCrmOrgId: string;
      }) => {
        const query = buildURLQuery({
          crmOrgId,
          refreshFromCrmOrgId,
        });

        return await sweepApi.post(`/sweep_fields/${fieldId}/refresh?${query}`);
      },
      [sweepApi],
    ),
  };
};
