import { DialogContent } from '@mui/material';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import { DedupMatchingForm } from './DedupMatchingForm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import useSendOpenAutomationEvent from '../Automations/useSendOpenAutomationEvent';

export type SingleDedupMatchingDialogRef = {
  isPristine: () => boolean;
};

export interface SingleDedupMatchingDialogProps {
  newDedupMatchingType?: DedupMatchingType;
  automation: Partial<AutomationStructureNew>;
  onSaveOrCreate: (automation: AutomationStructureNew, crmOrgIds: string[]) => any;
  onCancel: () => any;
  isOpen: boolean;
  readonly?: boolean;
  crmOrgId: string;
  objectName: ObjectTypeValues;
  funnelData?: { id: string; name: string; objectName: string };
  automationVariant: AutomationFormVariant;
  forceWideScreen?: boolean;
}

export const SingleDedupMatchingDialog = forwardRef<
  SingleDedupMatchingDialogRef,
  SingleDedupMatchingDialogProps
>(
  (
    {
      isOpen,
      automation,
      onSaveOrCreate,
      onCancel: closeDialog,
      readonly,
      crmOrgId,
      objectName,
      funnelData,
      newDedupMatchingType,
      forceWideScreen,
    },
    ref,
  ) => {
    const [isPristine, setIsPristine] = useState(true);
    const { openConfirm } = useConfirm();

    const _onCancel = useCallback(async () => {
      if (isPristine) {
        closeDialog();
      } else {
        const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
        if (isConfirmed) {
          closeDialog();
        }
      }
    }, [isPristine, openConfirm, closeDialog]);

    useImperativeHandle(ref, () => {
      return {
        isPristine() {
          return isPristine;
        },
      };
    }, [isPristine]);

    useSendOpenAutomationEvent(automation);

    return (
      <DialogOnCanvas
        open={isOpen}
        onClose={_onCancel}
        blockFully={!isPristine}
        forceWideScreen={forceWideScreen}
      >
        <DialogContent>
          <DedupMatchingForm
            newDedupMatchingType={newDedupMatchingType}
            key={`${automation?.automationId}${automation?.versionId}`}
            funnelData={funnelData}
            objectName={objectName}
            readonly={readonly}
            crmOrgId={crmOrgId}
            onBackButtonClick={_onCancel}
            onPristineChange={setIsPristine}
            onSaveOrCreate={onSaveOrCreate}
            initialAutomationsJson={automation}
          />
        </DialogContent>
      </DialogOnCanvas>
    );
  },
);
