import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import type { RoleGroupInterface } from '@server/role-group-interface';

export const useRolesApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_roleGroups: useCallback(async () => {
      const response = await sweepApi.get(`/roles/groups`);
      return response.data as RoleGroupInterface[];
    }, [sweepApi]),

    post_roleGroup: useCallback(
      async (roleGroup: Pick<RoleGroupInterface, 'name' & 'description' & 'roles'>) => {
        const response = await sweepApi.post(`/roles/group`, roleGroup);
        return response.data as RoleGroupInterface;
      },
      [sweepApi],
    ),

    put_roleGroup: useCallback(
      async (roleGroup: Pick<RoleGroupInterface, 'id' | 'name' | 'description' | 'roles'>) => {
        const response = await sweepApi.put(`/roles/group/${roleGroup.id}`, roleGroup);
        return response.data as RoleGroupInterface;
      },
      [sweepApi],
    ),

    delete_roleGroup: useCallback(
      async (roleGroupId: string) => {
        const response = await sweepApi.delete(`/roles/group/${roleGroupId}`);
        return response.data as RoleGroupInterface;
      },
      [sweepApi],
    ),
  };
};
