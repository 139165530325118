import { Badge, Typography, colors } from '@sweep-io/sweep-design';
import { ConfigurationType } from '../../dependencies/types';
import { Box, Stack } from '@mui/material';
import {
  DocumentationTabTypes,
  SWEEP_ELEMENTS_TAB_TYPES,
} from '../../../../types/enums/DocumentationTabTypes';
import { SearchInput } from '../../../common/SearchInput';
import { VirtualScrollDataTable } from '../../../common/table/VirtualScrollDataTable';
import { NoResults } from '../../NoResults';
import { DependenciesSideDialog } from '../../dependencies/DependenciesSideDialog';
import { useDependencies } from '../../dependencies/hooks/useDependencies';
import { ParsedRuleWithParentType, RecordTypesLabelsByNameAndObjectName } from '../../types';
import { useDocumentation } from '../../useDocumentation';
import { DocumentationFilters } from '../filters/DocumentationFilters';
import { useExpandedModeCalculations } from './useExpandedModeCalculations';
import { ChangeEvent } from 'react';

export interface ExpandedObjectDataTableElement {
  id: string;
  label: string;
  sfApiName: string; //rendered in table wrapped in component
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  description?: string;
  trigger?: string;
  actions?: React.ReactNode[];
  status?: string;
  type: string; //rendered in table wrapped in component
  usage?: number;
  isRollup?: boolean;
  parentType: ConfigurationType;
  errorMessage?: string;

  _type: string; //string used in api calls
  _sfApiName: string; //string used in api calls
}

interface ExpandedObjectContentProps {
  documentationTabType: DocumentationTabTypes;
  elements: FieldMetadataRecordProperties[] | AutomationStructureNew[] | ParsedRuleWithParentType[];
  objectName: string;
  label: string;
  crmOrgId: string;
  stepNamesUsedInCanvas: string[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}

export const ExpandedObjectContent = ({
  elements,
  objectName,
  documentationTabType,
  label,
  crmOrgId,
  stepNamesUsedInCanvas,
  recordTypeNamesUsedInCanvas,
}: ExpandedObjectContentProps) => {
  const {
    configurationItem,
    dependenciesData,
    historyLength,
    activeItemId,
    handleSetConfigurationItem,
  } = useDependencies(crmOrgId, recordTypeNamesUsedInCanvas);

  const { onFieldClick } = useDocumentation();

  const { setSearchStr, columns, rows, searchStr, onRowClick, onSortChange, sortBy } =
    useExpandedModeCalculations({
      elements,
      objectName,
      documentationTabType,
      onFieldClick,
      activeItemId,
      handleSetConfigurationItem,
      crmOrgId,
    });

  const isSweepElement = SWEEP_ELEMENTS_TAB_TYPES.includes(documentationTabType);

  return (
    <Box borderTop={`1px solid ${colors.grey[200]}`} display="flex" height="100%" width="100%">
      <Box width="100%" ml={2} mr={2} pt={3.5}>
        {isSweepElement && (
          <Box pb={3.5} display="flex" alignItems="center" gap={1}>
            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography variant="h3">{label}</Typography>
              <Badge label={elements.length} variant="secondary" showZero />
            </Stack>
          </Box>
        )}

        <Box display="flex" gap={1.5} alignItems="center">
          <Box>
            <SearchInput
              withFixedMagnifyingGlassIcon
              onClearButtonClick={() => setSearchStr('')}
              placeholder="Search"
              TextFieldProps={{
                sx: { maxWidth: '283px' },
                value: searchStr,
                onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setSearchStr(event.target.value),
              }}
            />
          </Box>
          <DocumentationFilters crmOrgId={crmOrgId} staticView />
        </Box>

        <Box
          pt={2.5}
          height={isSweepElement ? 'calc(100% - 160px)' : 'calc(100% - 90px)'}
          //overwrite width to prevent recalculating columns width
          sx={{
            'div[data-virtuoso-scroller="true"] > div': { width: 'calc(100vw - 90px) !important' },
          }}
        >
          <VirtualScrollDataTable
            defaultSortState={elements.length ? sortBy : undefined}
            columns={columns}
            rows={rows}
            onRowClick={onRowClick}
            onSortChange={onSortChange}
            TableEmptyStateComponent={
              <Box display="flex" justifyContent="center" pt={4}>
                <NoResults />
              </Box>
            }
          />
        </Box>
      </Box>

      {configurationItem && dependenciesData?.parentType && (
        <Box height="calc(100% - 40px)">
          <DependenciesSideDialog
            crmOrgId={crmOrgId}
            configurationItem={configurationItem}
            type={dependenciesData?.parentType}
            stepNamesUsedInCanvas={stepNamesUsedInCanvas}
            recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
            historyLength={historyLength}
            staticView
          />
        </Box>
      )}
    </Box>
  );
};
