import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { useCallback, useEffect, useState } from 'react';
import { useGetMpVersion } from '../deploy-button/complete-sf-setup/useGetMpVersion';
import { waitForWindowToClose } from '../../pages/helper';
import { Button } from '@sweep-io/sweep-design';
import { useCrmOrgs } from '../../pages/environments/useCrmOrgs';
import { telemetry } from '../../../telemetry';
import { retry } from 'ts-retry-promise';

const MP_CHECK_INTERVAL = 2000;
const MP_CHECK_TIMEOUT = 40000;

const useVerifyMpInstallation = () => {
  const { getCrmOrg } = useCrmOrgs();

  const asyncVerifyMpInstallation = async (crmOrgId: string) => {
    const getConnectedCrmOrg = async () => {
      try {
        const _crmOrg = await getCrmOrg(crmOrgId, true);
        return _crmOrg.isManagedPackageInstalled;
      } catch (err) {
        telemetry.captureError(err);
      }
      return false;
    };
    try {
      // Retry until the managed package is installed
      // or until the timeout is reached
      const isMpInstalled = await retry(getConnectedCrmOrg, {
        until: (isMpInstalled) => isMpInstalled,
        timeout: MP_CHECK_TIMEOUT,
        delay: MP_CHECK_INTERVAL,
      });
      return isMpInstalled;
    } catch (err) {
      telemetry.captureError(err);
    }
    return false;
  };

  return { asyncVerifyMpInstallation };
};

const InstallMpButton = ({
  disabled,
  text,
  startIconName,
}: {
  disabled?: boolean;
  text: string;
  startIconName?: string;
}) => {
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackageInstalled = crmOrg?.isManagedPackageInstalled;
  const instanceUrl = crmOrg?.instanceUrl;
  const [isLoading, setIsLoading] = useState(false);
  const mpVersion = useGetMpVersion();

  const { asyncVerifyMpInstallation } = useVerifyMpInstallation();

  const onInstallMpClick = useCallback(async () => {
    if (!crmOrg?.id) {
      return;
    }
    const _window = window.open(
      `${instanceUrl}/packaging/installPackage.apexp?p0=${mpVersion}`,
      '_blank',
    );
    if (_window) {
      setIsLoading(true);
      await waitForWindowToClose(_window);
      await asyncVerifyMpInstallation(crmOrg?.id);
      setIsLoading(false);
    }
  }, [crmOrg?.id, instanceUrl, mpVersion, asyncVerifyMpInstallation]);

  useEffect(() => {
    if (isManagedPackageInstalled) {
      setIsLoading(false);
    }
  }, [isManagedPackageInstalled]);

  if (!crmOrg || !mpVersion) {
    return null;
  }

  return (
    <>
      <Button
        onClick={onInstallMpClick}
        disabled={isManagedPackageInstalled || disabled || isLoading}
        variant="filled"
        size="small"
        loading={isLoading}
        startIconName={startIconName}
      >
        {text}
      </Button>
    </>
  );
};

export default InstallMpButton;
