import { useLocation } from 'react-router';
import { getPaywallSourceFromRoute } from './helper';
import PaywallHorizontalLayout from './PaywallHorizontalLayout';
import { PaywallSource } from '../paywallTypes';
import usePaywall from '../usePaywall';
import { useEffect } from 'react';

const PaywallPageBlock = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const paywallSource: PaywallSource | undefined = getPaywallSourceFromRoute('/' + path);
  const { sendEncounterPaywallEvent } = usePaywall();

  useEffect(() => {
    if (paywallSource) {
      sendEncounterPaywallEvent({
        source: paywallSource,
      });
    }
  }, [paywallSource, sendEncounterPaywallEvent]);

  return <PaywallHorizontalLayout paywallSource={paywallSource} />;
};

export default PaywallPageBlock;
