import GroupMembersList from '../pages/assignments/group-members/GroupMembersList';
import { Box, DialogContent, Stack } from '@mui/material';
import { IconButton, Typography } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { useCallback, useState } from 'react';
import { GroupsList } from '../pages/assignments/groups/GroupsList';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';

const GroupsDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  const [groupId, setGroupId] = useState<string | undefined>();
  const { assignmentGroups } = useAssignmentGroups();
  const onBackClick = useCallback(() => setGroupId(undefined), []);
  const onGroupClick = useCallback((groupId: string) => setGroupId(groupId), []);
  return (
    <DialogOnCanvas onClose={closeDialog} open={true} transitionDuration={transitionDuration}>
      <DialogContent sx={{ display: 'flex', paddingTop: 4 }}>
        <Stack flex={1} gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h1">{groupId ? '' : 'Groups'}</Typography>
            <Box>
              <IconButton variant="flat" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box flex={1}>
            {!assignmentGroups && <CenteredCircularProgress />}
            {assignmentGroups && (
              <>
                {groupId ? (
                  <GroupMembersList groupId={groupId} onBackClick={onBackClick} />
                ) : (
                  <GroupsList assignmentsGroupList={assignmentGroups} onGroupClick={onGroupClick} />
                )}
              </>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </DialogOnCanvas>
  );
};

export default GroupsDialog;
