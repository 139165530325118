import { Stack, Box } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { getPaywallBlockData } from './helper';
import { PaywallSource } from '../paywallTypes';
import usePaywall from '../usePaywall';

const PaywallHorizontalLayout = ({ paywallSource }: { paywallSource?: PaywallSource }) => {
  const { ctaCallback } = usePaywall();
  const data = getPaywallBlockData(paywallSource);
  const { Icon, header, subHeader, cta, gainAccessTo, quote, imageHorizontal } = data ?? {};

  return (
    <Stack
      width="100%"
      sx={{
        padding: '72px 80px 96px',
      }}
      alignItems="center"
    >
      <Stack maxWidth="1120px" gap={2}>
        <Stack
          direction="row"
          gap={2}
          sx={{
            flex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.sky[100],
              borderRadius: '16px 4px 4px 4px',
              flex: 1,
            }}
          >
            <Stack
              gap={3}
              sx={{
                padding: '40px 32px 24px 40px',
                '.MuiTypography-h1-bold': {
                  lineHeight: '32px',
                },
                '.MuiTypography-body': {
                  lineHeight: '24px',
                },
              }}
            >
              <Stack gap={1} sx={{
                'svg': {
                  width: '32px',
                  height: '32px',
                  'path' :{
                    strokeWidth: '2.5',
                  }
                }
              }}>
                {Icon && <Icon variant="large" color={colors.night[900]} />}
                <Box maxWidth="70%">
                  <Typography variant="h1-bold" color={colors.night[900]}>
                    {header}
                  </Typography>
                </Box>
                <Box maxWidth="80%">
                  <Typography variant="body" color={colors.grey[900]}>
                    {subHeader}
                  </Typography>
                </Box>
              </Stack>

              {gainAccessTo && (
                <Stack sx={{ marginBottom: 1 }}>
                  <Typography variant="body-bold" color={colors.night[900]}>
                    Gain immediate access to:
                  </Typography>
                  <ul style={{ margin: 0, marginTop: '4px', paddingLeft: '24px' }}>
                      <li>
                        <Typography variant="body" color={colors.grey[900]}>
                          {gainAccessTo[0]}
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body" color={colors.grey[900]}>
                          {gainAccessTo[1]}
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body" color={colors.grey[900]}>
                          {gainAccessTo[2]}
                        </Typography>
                      </li>
                    </ul>
                </Stack>
              )}
              <Box>
                <Button onClick={() => ctaCallback(paywallSource)}>{cta}</Button>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: '4px 16px 4px 4px',
              flex: 1,
              overflow: 'hidden',
              svg: {
                height: '100%',
              },
              maxWidth: '552px',
              width: '552px',
            }}
          >
            {imageHorizontal}
          </Box>
        </Stack>
        <Box
          sx={{
            backgroundColor: colors.sky[100],
            borderRadius: '4px 4px 16px 16px',
            padding: '24px 24px 32px',
          }}
        >
          {quote && (
            <Stack
              direction="row"
              gap={4}
              sx={{
                '.MuiTypography-body': { lineHeight: '24px' },
              }}
            >
              <Box display="flex" alignItems="center">
                {quote.logo}
              </Box>

              <Stack
                gap={2}
                sx={{
                  maxWidth: '75%',
                }}
              >
                <Typography variant="body" color={colors.grey[900]}>
                  {quote.text}
                </Typography>
                <Typography variant="caption" color={colors.grey[700]}>
                  - {quote.quoteBy}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default PaywallHorizontalLayout;
