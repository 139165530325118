import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { DialogLoader } from '../../../common/dialogs/DialogLoader';
import { FieldsTable } from '../FieldsTable';
import { useDocumentation } from '../../useDocumentation';
import {
  AutomationStructureWithParentType,
  ParsedRuleWithParentType,
  RecordTypesLabelsByNameAndObjectName,
} from '../../types';
import {
  getCardListFromAutomationStructure,
  getCardListFromParsedRecordTypes,
  getCardListFromParsedRules,
} from '../utils';
import { useSelector } from 'react-redux';
import { selectDependenciesConfigurationItem } from '../../../../reducers/documentationReducer';
import { selectAccountUsers } from '../../../../reducers/accountUsersReducer';
import { useSortElements } from '../useSortElements';
import { useDependencies } from '../../dependencies/hooks/useDependencies';
import { ElementSubheader } from './ElementSubheader';
import { ParsedRecordType } from '../../ParserTypes';
import { CardsList } from './CardsList';

interface ElementContentProps {
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled: boolean;
  items:
    | ParsedRuleWithParentType[]
    | FieldMetadataRecordProperties[]
    | AutomationStructureWithParentType[]
    | ParsedRecordType[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}

export const ElementContent = ({
  activeTab,
  objectTypeName,
  isManagePackageInstalled,
  items,
  crmOrgId,
  recordTypeNamesUsedInCanvas,
}: ElementContentProps) => {
  const { onFieldClick, isLoadingSingleField } = useDocumentation();
  const { handleSetConfigurationItem } = useDependencies(crmOrgId, {});

  const { sortedItems } = useSortElements({
    items,
    activeTab,
    isManagePackageInstalled,
  });

  const users = useSelector(selectAccountUsers);
  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const firstHistoryItem = selectedDependency?.history?.[0];

  const renderActiveTab = () => {
    switch (activeTab) {
      case DocumentationTabTypes.FIELDS:
        return (
          <FieldsTable
            crmOrgId={crmOrgId}
            fields={sortedItems as FieldMetadataRecordProperties[]}
            isManagePackageInstalled={isManagePackageInstalled}
            objectName={objectTypeName.objectType}
            onFieldClick={onFieldClick}
          />
        );

      case DocumentationTabTypes.RECORD_TYPES:
        return (
          <CardsList
            list={getCardListFromParsedRecordTypes(
              sortedItems as ParsedRecordType[],
              objectTypeName.objectType,
              recordTypeNamesUsedInCanvas,
            )}
            firstHistoryItem={firstHistoryItem}
            handleSetConfigurationItem={handleSetConfigurationItem}
          />
        );

      case DocumentationTabTypes.APEX:
      case DocumentationTabTypes.SF_AUTOMATIONS:
      case DocumentationTabTypes.VALIDATION_RULES:
      case DocumentationTabTypes.CPQ_DATA:
      case DocumentationTabTypes.LAYOUTS:
        return (
          <CardsList
            list={getCardListFromParsedRules(
              sortedItems as ParsedRuleWithParentType[],
              objectTypeName.objectType,
            )}
            firstHistoryItem={firstHistoryItem}
            handleSetConfigurationItem={handleSetConfigurationItem}
          />
        );

      case DocumentationTabTypes.SWEEP_AUTOMATIONS:
      case DocumentationTabTypes.ASSIGNMENTS:
      case DocumentationTabTypes.SCHEDULED_ASSIGNMENTS:
      case DocumentationTabTypes.MATCHING_DEDUPE:
      case DocumentationTabTypes.PLAYBOOK_ALERTS:
        return (
          <CardsList
            list={getCardListFromAutomationStructure(
              sortedItems as AutomationStructureWithParentType[],
              users,
            )}
            firstHistoryItem={firstHistoryItem}
            handleSetConfigurationItem={handleSetConfigurationItem}
          />
        );
    }
  };

  return (
    <>
      <DialogLoader visible={isLoadingSingleField} variant="light" />

      <ElementSubheader
        objectLabel={objectTypeName.label}
        items={sortedItems}
        activeTab={activeTab}
        isManagePackageInstalled={isManagePackageInstalled}
      />

      {renderActiveTab()}
    </>
  );
};
