import { Box, SxProps, TextField, TextFieldProps } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useState } from 'react';
import { buttons } from './Buttons';
import { getOpacityInHex } from '../../../../lib/getOpacityInHex';
import classNames from 'classnames';

export type TextFieldVariant = 'primary' | 'secondary';

type TextFieldWithButtonProps = TextFieldProps & {
  onConfirm: (message: string) => void;
  inputPlaceholder?: string;
  readonly?: boolean;
  isLoading?: boolean;
  inputVariant?: TextFieldVariant;
};

export const TextFieldWithButton = ({
  onConfirm,
  onFocus,
  onBlur,
  inputPlaceholder,
  readonly = false,
  isLoading = false,
  inputVariant = 'primary',
  ...rest
}: TextFieldWithButtonProps) => {
  const ButtonComponent = buttons[inputVariant];

  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !isLoading) {
      _onConfirm();
    }
  };

  const _onConfirm = useCallback(() => {
    onConfirm(value);
    setValue('');
  }, [value, onConfirm]);

  return (
    <TextField
      {...rest}
      onKeyDown={handleKeyDown}
      onChange={(event) => setValue(event.target.value)}
      onFocus={(event) => {
        setFocused(true);
        onFocus && onFocus(event);
      }}
      onBlur={(event) => {
        setFocused(false);
        onBlur && onBlur(event);
      }}
      value={value}
      className={classNames({ focused: focused })}
      sx={{
        width: '100%',
        borderRadius: '50px',
        boxShadow: 'none',

        ...textFieldVariantStyles[inputVariant],
      }}
      InputProps={{
        className: classNames({ focused: focused }),
        placeholder: inputPlaceholder,
        disabled: readonly || isLoading,
        sx: {
          ...inputVariantStyles[inputVariant],
        },
        endAdornment: ButtonComponent({ onClick: _onConfirm, disabled: isLoading || readonly }),
        startAdornment: (
          <>
            {inputVariant === 'primary' ? (
              <Box display="flex" alignItems="center">
                <Sparkle color={colors.blue[500]} />
              </Box>
            ) : (
              <></>
            )}
          </>
        ),
      }}
    />
  );
};

const sharedStyles = {
  borderRadius: '50px',
};

const textFieldVariantStyles: { [key in TextFieldVariant]: SxProps } = {
  primary: {
    border: `4px solid ${colors.blue[500]}${getOpacityInHex(20)}`,

    '&.focused': {
      borderColor: `${colors.blue[500]}${getOpacityInHex(20)}`,
    },

    '&:hover': {
      background: `${colors.blue[500]}${getOpacityInHex(40)}`,
      borderRadius: '50px',
    },
  },
  secondary: {},
};

const inputVariantStyles: { [key in TextFieldVariant]: SxProps } = {
  primary: {
    ...sharedStyles,
    border: `2px solid ${colors.blue[500]}`,

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    '&.MuiInputBase-root': {
      height: '48px',
      '& .MuiInputBase-inputAdornedStart': {
        pl: 0.5,
      },
    },

    '&.MuiInputBase-adornedEnd': {
      pr: 1,
    },
  },
  secondary: {
    ...sharedStyles,
  },
};
