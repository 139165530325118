import { Box, Dialog, DialogContent, DialogTitle, MenuItem, Select, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { selectWorkflowById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import {
  WorkflowActionType,
  WorkflowBaseDto,
  WorkflowCreateDto,
  WorkflowWhenEventTypes,
  WorkflowWhenType,
} from '../types';
import { DialogHeaderTextField } from '../../../../common/create-edit-dialog/DialogHeaderTextField';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';

import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { Button, colors, IconButton, Tag, Typography } from '@sweep-io/sweep-design';
import { FlexBox } from '../../../../common/FlexBox';
import { HubspotRuleBuilder } from '../hubspot-rule-builder/HubspotRuleBuilder';
import { uniqueId } from '../../../../../lib/uniqueId';
import { useHubspotWorkflows } from './useHubspotWorkflows';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import {
  hasOnlyOneCriteriaAndItIsEmpty,
  validateSweepCriteria,
} from '../../../../common/rule-builder/validateSweepCriteria';
import isEmpty from 'lodash/isEmpty';
import { HubspotEventRuleBuilder } from '../hubspot-rule-builder/HubspotEventRuleBuilder';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { telemetry } from '../../../../../telemetry';

export const workflowWhenOptionLabels = {
  [WorkflowWhenType.EVENT]: 'When an event occurs',
  [WorkflowWhenType.FILTERS]: 'When Filter criteria is met',
};

const newWorkflow = (campaignId: string): DeepPartial<WorkflowCreateDto> =>
  ({
    name: '',
    objectName: 'contacts',
    workflowDetails: {
      when: {
        _id: uniqueId(),
        whenType: WorkflowWhenType.FILTERS,
        filter: {
          criteria: [
            {
              criterionId: uniqueId(),
              valueType: 'Literal',
            },
          ],
          criteriaLogic: '1',
        },
      },
      actions: [
        {
          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
          campaignId,
          status: '',
        },
        {},
      ],
      onlyEnrollsManually: true,
    },
  }) as WorkflowCreateDto;

const WhenTag = () => {
  return (
    <div>
      <Tag color={colors.sun[500]} textTheme="light" label="WHEN" size="medium" />
    </div>
  );
};

const ThenTag = () => {
  return (
    <div>
      <Tag color={colors.gum[500]} textTheme="light" label="THEN" size="medium" />
    </div>
  );
};

const validateWorkflowForm = (workflow: DeepPartial<WorkflowBaseDto>) => {
  if (!workflow) return false;
  if (!workflow.name) return false;
  if (!workflow.objectName) return false;

  if (!workflow.workflowDetails) return false;
  if (!workflow.workflowDetails.actions) return false;
  if (workflow.workflowDetails.actions.length < 2) return false;

  if (!workflow.workflowDetails.when) return false;
  if (!workflow.workflowDetails.when.whenType) return false;
  if (!workflow.workflowDetails.actions[1]?.status) return false;
  if (
    workflow.workflowDetails.when.whenType === WorkflowWhenType.FILTERS &&
    !workflow.workflowDetails.when.filter?.criteria?.length
  ) {
    return false;
  }
  const errors = validateSweepCriteria(
    workflow.workflowDetails.when.filter?.criteria as SweepCriterion[],
    workflow.workflowDetails.when.whenType === WorkflowWhenType.EVENT,
    true,
  );

  const isValid = errors.length === 0;

  if (!isValid) return false;

  return true;
};

const AndThenTag = () => {
  return (
    <div>
      <Tag color={colors.gum[500]} textTheme="light" label="AND THEN" size="medium" />
    </div>
  );
};

const BoxSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const WorkflowDialog = ({
  workflowId,
  campaignId,
  onClose,
  campaignName,
  isDuplicate,
}: {
  workflowId?: string;
  campaignId: string;
  onClose: () => void;
  campaignName: string;
  isDuplicate?: boolean;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { createHubspotWorkflow, updateHubspotWorkflow } = useHubspotWorkflows();
  const baseWorkflow = useSelector(selectWorkflowById(workflowId));
  const { addNotification } = useSweepNotifications();

  const [editingWorkflow, setCurrentEditingWorkflow] = useState<DeepPartial<WorkflowBaseDto>>(
    () => {
      if (baseWorkflow) {
        const _workflow = cloneDeep({ ...baseWorkflow });
        const id = isDuplicate ? undefined : _workflow.id;
        const name = isDuplicate ? `Copy of ${_workflow.name}` : _workflow.name;

        return {
          id,
          name,
          objectName: _workflow.objectName,
          workflowDetails: { ..._workflow.workflowDetails },
        };
      }

      return newWorkflow(campaignId);
    },
  );
  const [isPristine, setIsPristine] = useState(true);

  // Merge properties recursively to the currentWorkflow.
  const onChangeProperty = useCallback((workflowPartial: DeepPartial<WorkflowBaseDto>) => {
    setCurrentEditingWorkflow((prev) => {
      setIsPristine(false);
      const newWorkflow = { ...prev };
      const when = newWorkflow.workflowDetails?.when;
      if (workflowPartial.workflowDetails?.when?.filter && when?.filter) delete when['filter'];

      merge(newWorkflow, workflowPartial);

      return { ...newWorkflow };
    });
  }, []);

  const when = editingWorkflow.workflowDetails?.when;

  const isInEditMode = Boolean(editingWorkflow.id);

  const deployWorkflowToHubspot = useCallback(async () => {
    setIsSaving(true);
    try {
      const _editingWorkflow = { ...editingWorkflow } as WorkflowBaseDto;

      if (_editingWorkflow?.workflowDetails?.when?.filter?.criteria) {
        if (hasOnlyOneCriteriaAndItIsEmpty(_editingWorkflow.workflowDetails.when.filter.criteria)) {
          _editingWorkflow.workflowDetails.when.filter.criteria = [];
          _editingWorkflow.workflowDetails.when.filter.criteriaLogic = '';
        }
      }

      if (isInEditMode) {
        await updateHubspotWorkflow(_editingWorkflow as Required<WorkflowBaseDto>);
      } else {
        await createHubspotWorkflow(_editingWorkflow as WorkflowCreateDto);
      }
      setIsSaving(false);
      onClose();
    } catch (e: any) {
      addNotification({
        message: `Failed to save workflow `,
        keepOpen: true,
        details: e.message?.[0] || e.toString(),
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      setIsSaving(false);
    }
  }, [
    createHubspotWorkflow,
    editingWorkflow,
    isInEditMode,
    onClose,
    updateHubspotWorkflow,
    addNotification,
  ]);

  const { openConfirm } = useConfirm();

  const _onClose = useCallback(async () => {
    if (isPristine) {
      onClose?.();
    } else {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (isConfirmed) {
        onClose?.();
      }
    }
  }, [isPristine, onClose, openConfirm]);

  const isValid = validateWorkflowForm(editingWorkflow);

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          width: '1032px',
          height: '823px',
          margin: 0,
        },
      }}
      onClose={_onClose}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          sx={{
            '.MuiButton-contained': {
              whiteSpace: 'nowrap',
              padding: '0 40px',
            },
          }}
        >
          <DialogHeaderTextField
            value={editingWorkflow.name as string}
            onChange={(name) => onChangeProperty({ name })}
            placeholder=""
            editDisabled={isSaving}
          />

          <Button
            onClick={deployWorkflowToHubspot}
            size="small"
            startIconName="Hubspot"
            disabled={!isValid || isSaving}
            loading={isSaving}
          >
            Deploy to Hubspot
          </Button>
          <IconButton onClick={_onClose} variant="flat">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box display="flex" gap={2} flexDirection="column">
          <BoxSection>
            <WhenTag />
            <Box sx={{ display: 'flex', gap: 1, alignContent: 'center', alignItems: 'center' }}>
              <Select
                value={when?.whenType}
                placeholder="This happens"
                disabled={isSaving}
                onChange={(e) => {
                  const whenType = e.target.value as WorkflowWhenType;
                  onChangeProperty({
                    workflowDetails: {
                      when: {
                        whenType,
                        filter:
                          whenType === WorkflowWhenType.EVENT
                            ? null
                            : {
                                _id: uniqueId(),
                              },
                      },
                    },
                  } as any);
                }}
              >
                <MenuItem value={WorkflowWhenType.EVENT}>
                  {workflowWhenOptionLabels[WorkflowWhenType.EVENT]}
                </MenuItem>
                <MenuItem value={WorkflowWhenType.FILTERS}>
                  {workflowWhenOptionLabels[WorkflowWhenType.FILTERS]}
                </MenuItem>
              </Select>
              {when?.whenType === WorkflowWhenType.EVENT && (
                <>
                  <Select
                    value={when?.eventType}
                    placeholder="This happens"
                    disabled={isSaving}
                    onChange={(e) =>
                      onChangeProperty({
                        workflowDetails: {
                          when: {
                            eventType: e.target.value as WorkflowWhenEventTypes,
                            filter: {
                              criteria: [],
                              criteriaLogic: '',
                            },
                          },
                        },
                      })
                    }
                  >
                    <MenuItem value={WorkflowWhenEventTypes.AD_INTERACTION}>
                      has completed: ad interaction
                    </MenuItem>
                    <MenuItem value={WorkflowWhenEventTypes.FORM_SUBMITTED}>
                      has completed: form interaction
                    </MenuItem>
                    <MenuItem value={WorkflowWhenEventTypes.EMAIL_REPLY}>
                      has completed: replied to email
                    </MenuItem>
                  </Select>
                </>
              )}
            </Box>
            {when?.whenType === WorkflowWhenType.FILTERS && (
              <HubspotRuleBuilder
                onChange={(filter) => {
                  const _filter = !isEmpty(filter)
                    ? filter
                    : {
                        criteria: [
                          {
                            criterionId: uniqueId(),
                            valueType: 'Literal',
                          },
                        ],
                        criteriaLogic: '1',
                      };
                  onChangeProperty({
                    workflowDetails: {
                      when: {
                        filter: _filter,
                      },
                    },
                  });
                }}
                sweepCondition={when.filter}
                readOnly={isSaving}
              />
            )}
            {when?.whenType === WorkflowWhenType.EVENT && (
              <HubspotEventRuleBuilder
                onChange={(filter) => {
                  const _filter = !isEmpty(filter)
                    ? filter
                    : {
                        criteria: [
                          {
                            criterionId: uniqueId(),
                          },
                        ],
                        criteriaLogic: '1',
                      };
                  onChangeProperty({
                    workflowDetails: {
                      when: {
                        filter: _filter,
                        eventType: when.eventType as WorkflowWhenEventTypes,
                      },
                    },
                  });
                }}
                eventType={when.eventType || WorkflowWhenEventTypes.AD_INTERACTION}
                readOnly={isSaving}
                sweepCondition={when.filter}
              />
            )}
          </BoxSection>
          <BoxSection>
            <ThenTag />
            <Typography variant="body">Set {campaignName}</Typography>
          </BoxSection>
          <BoxSection>
            <AndThenTag />
            <FlexBox
              sx={{
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="body">Status is</Typography>
              <Select
                value={get(editingWorkflow, 'workflowDetails.actions[1].status')}
                placeholder="Select status"
                disabled={isSaving}
                onChange={(e) => {
                  onChangeProperty({
                    workflowDetails: {
                      actions: [
                        {
                          _id: uniqueId(),
                          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
                          campaignId,
                          status: 'Sent',
                        },
                        {
                          _id: uniqueId(),
                          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
                          campaignId,
                          status: e.target.value,
                        },
                      ],
                    },
                  });
                }}
              >
                <MenuItem value="Sent">Sent</MenuItem>
                <MenuItem value="Received">Received</MenuItem>
                <MenuItem value="Responded">Responded</MenuItem>
              </Select>
            </FlexBox>
          </BoxSection>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
