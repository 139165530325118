import { Box } from '@mui/material';
import { Tag, Typography, colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { RoleGroupInterface, RoleGroupType } from '@server/role-group-interface';
import { ActionMenu } from './ActionMenu';

interface RoleProps {
  role: RoleGroupInterface;
  onClick: () => void;
}

export const Role = ({ role, onClick }: RoleProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  return (
    <Box
      sx={{
        cursor: 'pointer',
        gap: 0.5,
        borderRadius: 0.5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1.5,
        background: anchorEl ? colors.grey[100] : colors.white,
        '&:hover': { background: colors.grey[100] },
        height: '64px',
      }}
      onClick={() => {
        if (!anchorEl) {
          onClick();
        } else {
          setAnchorEl(undefined);
        }
      }}
    >
      <>
        <Box
          sx={{
            cursor: 'pointer',
            gap: 0.5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body">{role.name} </Typography>
            {role.type === RoleGroupType.USER_CREATED && (
              <Tag color={colors.blue[100]} label="Custom role" />
            )}
          </Box>
          <Typography variant="caption" color={colors.grey[700]}>
            {role.description}
          </Typography>
        </Box>
        <ActionMenu role={role} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </>
    </Box>
  );
};
