import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useSelector } from 'react-redux';
import { selectCrmOrgUsers } from '../../../../reducers/crmOrgUsersReducer';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import {
  selectAssignmentGroupsList,
  selectIsAssignmentGroupsLoaded,
} from '../../../../reducers/assignmentGroupsPageReducer';
import {
  AddErrorHandlingNotificationType,
  useErrorHandling,
} from '../../../../hooks/useErrorHandling';
import { AxiosError } from 'axios';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useEffect, useRef, useState } from 'react';
import { telemetry } from '../../../../telemetry';

export const useAssignmentGroups = () => {
  const { getCrmOrgUsers } = useCrmOrgs();
  const { getAssignmentsGroupList, clearAllAssignmentGroupsData } = useAssignmentsApiWithReducer();
  const { errorHandlingBuilder } = useErrorHandling();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const crmOrgUsers = useSelector(selectCrmOrgUsers(crmOrgId || ''));
  const [isManagedPackageError, setIsManagedPackageError] = useState(false);

  const assignmentGroups = useSelector(selectAssignmentGroupsList);
  const isGroupsLoaded = useSelector(selectIsAssignmentGroupsLoaded);

  const setHasManagedPackageError = (
    err: unknown,
    addErrorHandlingNotification: AddErrorHandlingNotificationType,
  ) => {
    const axiosError = err as AxiosError<{ message: string; sweepError: number }>;
    //0 is the enum value for SweepError.ManagedPackageRequired
    if (axiosError.response?.data.sweepError === 0) {
      setIsManagedPackageError(true);
    } else {
      setIsManagedPackageError(true);
      addErrorHandlingNotification('Unknown error');
      telemetry.captureError(err);
    }
  };

  useRunOnceWhenTruthy(
    () => {
      errorHandlingBuilder().withOnError(setHasManagedPackageError).execute(getCrmOrgUsers);
    },
    Boolean(crmOrgId) && !crmOrgUsers,
  );

  const currentAssignmentGroupCrmOrgId = useRef<string | null>(null);

  useEffect(() => {
    (async () => {
      if (crmOrgId) {
        if (!isGroupsLoaded && currentAssignmentGroupCrmOrgId.current !== crmOrgId) {
          clearAllAssignmentGroupsData();
          currentAssignmentGroupCrmOrgId.current = crmOrgId;
          getAssignmentsGroupList();
        }
      }
    })();
  }, [clearAllAssignmentGroupsData, crmOrgId, getAssignmentsGroupList, isGroupsLoaded]);

  return {
    crmOrgUsers,
    assignmentGroups,
    isManagedPackageError,
  };
};
