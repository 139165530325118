import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { displayCorrectFormat } from '../../../common/rule-builder/selectors/DateSelector';
import { NoResults } from '../../NoResults';
import {
  NewDependencyProps,
  ConfigurationType,
  SelectedDependency,
  DependenciesContentType,
} from '../../dependencies/types';
import { OCCUPIED_SPACE_SINGLE_OBJECT_VIEW_CANVAS } from '../../helpers';
import { ListElementWrapper } from '../../shared/ListElementWrapper';
import { ClampedText } from '../../../common/ClampedText';
import { getRecordTypeIdentifier } from '../../../pages/configuration-canvas/utils/getRecordTypeIdentifier';

interface CardListProps {
  list: CardListItem[];
  containerRef?: any;
  firstHistoryItem?: SelectedDependency;
  isCanvasDocumentation?: boolean;
  handleSetConfigurationItem: (props: NewDependencyProps) => void;
}

interface CardListItem {
  id: string;
  dependencyType: string;
  parentType: ConfigurationType;
  name: string;
  label: string;
  objectName: string;
  changedAt?: string;
  changedBy?: string;
  withHideButton?: boolean;
}

export const CardsList = ({
  list,
  containerRef,
  firstHistoryItem,
  isCanvasDocumentation,
  handleSetConfigurationItem,
}: CardListProps) => {
  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${
          isCanvasDocumentation ? OCCUPIED_SPACE_SINGLE_OBJECT_VIEW_CANVAS : '230px'
        })`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {list.length ? (
        list.map((item) => {
          const date = displayCorrectFormat(item.changedAt);

          return (
            <Box key={'wrapper_' + item.id}>
              <ListElementWrapper
                elementId={getRecordTypeIdentifier(item.name, item.objectName)}
                withVisibilityToggle={!!item.withHideButton}
                sx={{
                  minHeight: '64px',
                  pr: 0,
                  pl: 0,
                  pt: 1.5,
                  pb: 1.5,
                }}
                onWrapperClick={() => {
                  handleSetConfigurationItem({
                    id: item.id,
                    dependencyType: item.dependencyType,
                    parentType: item.parentType,
                    name: item.name,
                    objectName: item.objectName,
                    clearHistory: true,
                    contentType: DependenciesContentType.default,
                  });
                }}
                isHighlighted={
                  firstHistoryItem?.id === item.id || firstHistoryItem?.name === item.name
                }
                leftContent={<Content label={item.label} date={date} />}
              />
            </Box>
          );
        })
      ) : (
        <Box pl={2}>
          <NoResults />
        </Box>
      )}
    </Box>
  );
};

const Content = ({ label, date }: { label: string; date?: string }) => {
  return (
    <Box display="flex" pr={2} pl={2} flexWrap="wrap" minWidth={0}>
      <ClampedText text={label} />

      {date && (
        <Box width="100%">
          <Typography variant="caption" color={colors.grey[700]}>
            Last updated {date}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
