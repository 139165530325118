import { isProdEnvIndeed } from '../environment';
import { BiEvent, BiEventName, SIGN_UP_EVENTS, USER_EVENTS } from './events';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

const isEventToSendToWeb = (eventName: BiEventName) => {
  const eventsToSendToWeb: BiEventName[] = [
    SIGN_UP_EVENTS.newUserLogin,
    USER_EVENTS.meetingBooked,
    USER_EVENTS.meetingBooked,
  ];
  return eventsToSendToWeb.includes(eventName);
};

//This is to indicate which BI services are should be enabled.
const ENABLE_MAP = {
  mixpanel: false,
};

export type BiUser = {
  id: string;
  email: string;
  name: string;
  role: string;
  accountId?: string;
  accountName?: string;
  accountType?: string;
  questionnaireRole?: string;
  questionnaireUseCases?: string;
  userSource?: string;
};

let mixpanelApp: Mixpanel | undefined;
let mixpanelWeb: Mixpanel | undefined;

const initMixpanel = () => {
  mixpanelApp = mixpanel.init(
    'a51065b3a9d0329648a8c5545173ae11',
    {
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true,
      api_host: 'https://bi.sweep.io',
    },
    'app',
  );
  mixpanelWeb = mixpanel.init(
    'dbcc0dc209419a071fa40a0416ef5d78',
    {
      track_pageview: false,
      persistence: 'localStorage',
      ignore_dnt: true,
      api_host: 'https://bi.sweep.io',
    },
    'website',
  );
};

const setUserInMixpanel = (user: BiUser, utmParams: Record<string, any>) => {
  if (mixpanelApp) {
    mixpanelApp.identify(user.id);
    mixpanelApp.alias(user.email);
    mixpanelApp.people.set({
      $name: user.name,
      $email: user.email,
      role: user.role,
      accountId: user.accountId,
      accountName: user.accountName,
      plan: user.accountType,
      questionnaireRole: user.questionnaireRole,
      questionnaireUseCases: user.questionnaireUseCases,
      ...utmParams,
    });
  }
  if (mixpanelWeb) {
    mixpanelWeb.identify(user.email);
    mixpanelWeb.people.set({
      $email: user.email,
      plan: user.accountType,
      'User Source': user.userSource,
    });
  }
};

const setUserSourceInMixpanel = (userSource: string) => {
  if (mixpanelWeb) {
    mixpanelWeb.people.set({
      'User Source': userSource,
    });
  }
};

const logoutUserInMixpanel = () => {
  if (!mixpanelApp) return;
  mixpanelApp.reset();
};

const sendEventToWeb = (event: BiEvent) => {
  if (!mixpanelWeb) return;
  mixpanelWeb.track(event.name, event.props);
};

const sendEvent = (event: BiEvent) => {
  if (isEventToSendToWeb(event.name)) {
    sendEventToWeb(event);
  }
  if (!mixpanelApp) return;
  mixpanelApp.track(event.name, event.props);
};

export const initBi = (force = false) => {
  if (isProdEnvIndeed() || force) {
    ENABLE_MAP.mixpanel = true;
  }
  if (ENABLE_MAP.mixpanel) initMixpanel();
};

export const setUserDetailsForBi = (user: BiUser, utmParams: Record<string, any>) => {
  if (ENABLE_MAP.mixpanel) setUserInMixpanel(user, utmParams);
};

export const setUserSignUpSource = (userSource: string) => {
  if (ENABLE_MAP.mixpanel) setUserSourceInMixpanel(userSource);
};

export const sendEventInMixpanel = (event: BiEvent) => {
  if (ENABLE_MAP.mixpanel) sendEvent(event);
};

export const logoutUserBI = () => {
  if (ENABLE_MAP.mixpanel) logoutUserInMixpanel();
};
