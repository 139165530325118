import { useDependencies } from '../hooks/useDependencies';
import { useRunOnce } from '../../../common/useRunOnce';
import { useState } from 'react';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { useSelector } from 'react-redux';
import { selectConfigurationSourceCode } from '../../../../reducers/documentationReducer';
import { colors, Typography } from '@sweep-io/sweep-design';
import Box from '@mui/material/Box/Box';
import { highlightMatch } from '../../../../lib/highlightMatch';
import { useUniversalSearch } from '../../universal-search/useUniversalSearch';

interface SourceViewProps {
  crmOrgId: string;
  configurationItem: FieldMetadataRecordProperties | ConfigurationItem;
}

export const SourceView = ({ crmOrgId, configurationItem }: SourceViewProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getSourceCode } = useDependencies(crmOrgId, {});
  const { searchTxt } = useUniversalSearch(crmOrgId);

  const sourceCode = useSelector(selectConfigurationSourceCode(crmOrgId, configurationItem.id));

  useRunOnce(async () => {
    if (!sourceCode && configurationItem.filename) {
      setIsLoading(true);
      await getSourceCode(configurationItem.filename, configurationItem.id);
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box component="pre" pl={2.5} pr={2.5} height="100%" overflow="auto">
      <Typography variant="body" whiteSpace="break-spaces" color={colors.grey[800]}>
        {highlightMatch(sourceCode, searchTxt)}
      </Typography>
    </Box>
  );
};
