import { useCallback } from 'react';
import { ChevronButton } from './shared/ChevronButton';
import { TableHeaderVariant, TableWithHeader } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { DataTableRow } from '../../common/table/TableTypes';
import {
  ConfigurationType,
  DependenciesContentType,
  NewDependencyProps,
} from '../dependencies/types';

const columns = [
  { field: 'label', headerName: 'Label', width: '35%' },
  { field: 'name', headerName: 'API Name', width: '35%' },
  { field: '__actions', headerName: '', width: '10%' },
];

const renderRows = ({ items }: { items: NameProperties[] }) => {
  return items.map((row) => ({
    id: row.name,
    label: <TableTypography>{row.label}</TableTypography>,
    name: <TableTypography>{row.name}</TableTypography>,
    _nameStr: row.name, //needed in onRowClick
    __actions: <ChevronButton />,
    isClickable: true,
  }));
};

interface ObjectTableProps {
  items: NameProperties[];
  onItemClick: (props: NewDependencyProps) => void;
  headerVariant?: TableHeaderVariant;
  shortListViewCounter?: number;
  clearHistory?: boolean;
}

export const ObjectsTable = ({
  items,
  onItemClick,
  shortListViewCounter,
  headerVariant,
  clearHistory,
}: ObjectTableProps) => {
  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      onItemClick({
        id: row.id,
        name: row._nameStr,
        parentType: ConfigurationType.objects,
        clearHistory,
        contentType: DependenciesContentType.default,
      });
    },
    [onItemClick, clearHistory],
  );

  return (
    <TableWithHeader
      tableHeader="Objects"
      columns={columns}
      rows={renderRows({ items })}
      headerVariant={headerVariant}
      shortListViewCounter={shortListViewCounter}
      onRowClick={onRowClick}
    />
  );
};
