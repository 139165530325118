import { renderToStaticMarkup } from 'react-dom/server';
import { createTheme } from '@mui/material/styles';
import CheckIcon from './appThemeIcons/dropdown-check.svg?react'; //TODO research why design system icon doesn't work
import CaretDown from './appThemeIcons/caret_down.svg?react';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';

const DIALOG_CONTENT_PADDING_TOP = '0';
const DIALOG_PADDING = '32px';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
    rounded: true;
    squared: true;
  }

  interface ButtonPropsColorOverrides {
    placeholder: true;
  }
}

const sharedCheckboxMark = {
  '&:after': {
    content: '""',
    position: 'absolute',
    backgroundImage: `url('data:image/svg+xml;utf8,${renderToStaticMarkup(<CheckIcon />)}')`,
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
    alignItems: 'center',
    right: '20px',
    height: '16px',
    width: '16px',
  },
};

const sharedSelectedStyles = {
  fontWeight: 600,
  background: 'transparent',
};

export const sharedHoverMenuItemStyles = {
  borderRadius: '4px',
  backgroundColor: colors.grey[100],
};

const sharedPlaceholderStyles = {
  '&::placeholder': {
    color: colors.grey[700],
    fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
    fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
    opacity: 1,
  },
  /**
   * Icon color should have the same color as placeholder
   */
  '&::placeholder-shown + .MuiAutocomplete-endAdornment': {
    svg: {
      path: {
        stroke: colors.grey[700],
      },
    },
  },
};

export const designSystemElevations = {
  Elevation1: '0px 2px 15px rgba(0, 0, 0, 0.15)',
  Elevation2: '0px 8px 32px rgba(0, 0, 0, 0.1)',
  Elevation4: '0px 40px 100px rgba(0, 0, 0, 0.1)',
  Elevation5: '0px 0px 16px rgba(0, 0, 0, 0.15)',
  Elevation6: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  Elevation7: '0px 2px 2px rgba(0, 0, 0, 0.1), 0px 5px 25px rgba(0, 0, 0, 0.15)',
};

const appTheme = createTheme({
  zIndex: {
    tooltip: 1500111,
  },

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            padding: 0,
            paddingLeft: '5px',
          },
        },
        paper: {
          width: 'fit-content',
        },
        input: {
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
        },
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            ...sharedSelectedStyles,
            ...sharedCheckboxMark,

            '&.Mui-focused': {
              background: 'transparent',
            },
          },
          '& .Mui-focused': {
            ...sharedHoverMenuItemStyles,
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
    },
    MuiButton: {
      //variants are moved to sweep-design
      defaultProps: {
        size: 'small',
        color: 'primary',
        variant: 'rounded',
      },
      styleOverrides: {
        sizeLarge: {
          height: '48px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: colors.white,
          borderRadius: '8px',
          border: `1px solid ${colors.grey[300]}`,
          backgroundClip: 'padding-box',
          cursor: 'pointer',
          '&:hover, &.active': {
            borderColor: colors.blue[500],
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0 22px',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '381px',
          borderRadius: '12px', //aligned with new design system
          maxWidth: 'initial', // to override the default limitation
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: `${DIALOG_PADDING} ${DIALOG_PADDING} 16px`,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${DIALOG_PADDING} ${DIALOG_PADDING} 8px`,
          paddingTop: DIALOG_CONTENT_PADDING_TOP,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px 32px 32px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          padding: '0 12px 5.5px',
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,

          '&::placeholder': {
            color: colors.grey[500],
          },
        },
        inputMultiline: {
          padding: '0px',
          minHeight: '28px',
        },
        root: {
          '&:before': {
            borderBottom: `1px solid ${colors.grey[300]}`,
          },
          '&:after, &:hover:after': {
            borderBottom: `1px solid ${colors.blue[500]}`,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${colors.blue[500]}`,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        inputMultiline: {
          padding: '0px',
          minHeight: '28px',
        },
        input: {
          padding: '8px 12px',
          ...sharedPlaceholderStyles,
        },
        notchedOutline: {
          borderColor: colors.grey[300],
          borderWidth: '1px',
        },
        root: {
          minHeight: '40px',
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,

          notchedOutline: {
            borderColor: colors.grey[300],
            borderWidth: '1px',
          },

          background: colors.white,
          color: colors.black,
          borderColor: colors.grey[300],
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey[300],
            borderWidth: '1px',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blush[500],
            borderWidth: '1px',
          },
          '&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blue[500],
          },
          '&.MuiInputBase-multiline': {
            padding: '11px',
          },
          '&.Mui-disabled': {
            background: colors.grey[100],
            color: colors.grey[500],

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.grey[300],
            },

            svg: {
              path: {
                stroke: colors.grey[500],
              },
            },
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: '12px',
            '.MuiInputAdornment-positionStart': {
              marginRight: 0,
            },
            input: {
              paddingLeft: '8px',
              fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
              fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
              lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
            },
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: '12px',
            '.MuiInputAdornment-positionEnd': {
              marginLeft: 0,
            },
            input: {
              paddingRight: '16px',
              fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
              fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
              lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0, //to override Mui definition and allow alignment of elements to the left
          '.MuiCheckbox-root': {
            marginRight: '9px', //if there is checkbox, allow margin to the label
          },
        },
        label: {
          position: 'relative',
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-bold']?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-bold']?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-bold']?.lineHeight,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.blue[500],
          display: 'flex',
          alignItems: 'center',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '15px',

          '& > svg path': {
            fill: colors.blue[500],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: '8px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          marginBottom: '8px',
          '&:hover': {
            ...sharedHoverMenuItemStyles,
          },

          '&.Mui-selected': {
            '&, &:hover': {
              background: sharedSelectedStyles.background,
            },

            '& .MuiListItemText-root': {
              fontWeight: sharedSelectedStyles.fontWeight,
            },

            ...sharedSelectedStyles,
            ...sharedCheckboxMark,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '4px 12px', // to align with menus design
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        root: {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        MenuListProps: {
          disablePadding: false,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: (props: any) => ({
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
          marginBottom: '8px',
          padding: '8px 12px',
          ...(props.hidden ? { display: 'none' } : {}),
          '&:hover': {
            ...sharedHoverMenuItemStyles,
          },
          '&.Mui-selected': {
            ...sharedSelectedStyles,
            paddingRight: '52px',

            ...sharedCheckboxMark,

            '&:has(> .MuiCheckbox-root)': {
              '&:after': { display: 'none' },
            },

            '&, &:hover, &.Mui-focusVisible': {
              background: sharedSelectedStyles.background,
            },
          },
          '&:last-child': {
            marginBottom: 0,
          },
          '.MuiCheckbox-root': {
            marginRight: '9px', //if the menu has checkbox, allow margin to the text
          },
        }),
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: 'transparent',
          fontSize: '0.8rem',
          position: 'relative',
        },
        head: {
          fontSize: '0.675rem',
          color: '#a7a7a7',
        },
        body: {
          fontWeight: 500,
          color: colors.black,
        },
      },
    },
    MuiPopover: {
      //This is used (among others) also in menus
      styleOverrides: {
        paper: {
          marginTop: '6px',
          borderRadius: '8px',
          boxShadow: designSystemElevations.Elevation1,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        IconComponent: CaretDown,
        MenuProps: {
          MenuListProps: {
            disablePadding: false,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: (props: any) => ({
            '& .MuiSelect-select .notranslate::after': props.placeholder
              ? {
                  content: `"${props.placeholder}"`,
                  fontWeight: sharedPlaceholderStyles['&::placeholder'].fontWeight,
                  color: sharedPlaceholderStyles['&::placeholder'].color,
                }
              : {},

            '&.MuiOutlinedInput-root': {
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,

              '& .MuiSelect-icon':
                props.placeholder && !props.value
                  ? sharedPlaceholderStyles['&::placeholder-shown + .MuiAutocomplete-endAdornment']
                      .svg
                  : { path: { fill: colors.black } },
            },
          }),
        },
      ],
      styleOverrides: {
        icon: {
          alignItems: 'center',
          right: '12px',
          width: '11px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            marginLeft: 0,
          },
        },
      },
    },
    /**
     * NOTE!
     * Paper Elevations uses MUI theme shadows. PLEASE CONFIGURE THEM IN SHADOWS SECTION.
     *
     *  --------- DO NOT DO THIS: ---------
     *    elevationX: {
     *      boxShadow: '....',
     *    },
     *  ------------------------------------
     */
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderRadius: '8px',
          borderColor: colors.grey[300],
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingLeft: 0, //to override Mui definition and allow alignment of all elements to the left
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...(__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body || {}),
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '14px',
          color: colors.grey[500],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.grey[300],
        },
      },
    },
  },
});

export { appTheme };
