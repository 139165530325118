import { Box, SxProps } from '@mui/material';
import { ActionsMenu, ActionsMenuAction } from './actions-menu/ActionsMenu';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Permission } from '@server/permissions';
import { ClampedText } from './ClampedText';
import { IconButtonSize } from '@sweep-io/sweep-design/dist/components/IconButton/types';

interface ListItemActionCardProps<ItemValueT = any> {
  showToggle?: boolean;
  actionsMenu: ActionsMenuAction<ItemValueT>[];
  title: string;
  headerRightContent?: React.ReactNode;
  content: React.ReactNode;
  toggleButton?: {};
  permissionString?: Permission;
  notAllowedTitle?: string;
  onLineClick: () => any;
  isAllowedBtn: boolean;
  readonly?: boolean;
  onActionMenuClick: (action: ItemValueT) => any;
  onDeleteLine: () => any;
  onToggleChange?: (action: ItemValueT) => any;
  isToggleChecked?: boolean;
  isSelected?: boolean;
  titleSX?: SxProps;
  horizontalMargin?: string | number;
  actionsMenuIconSize?: IconButtonSize;
}

function ListItemActionCard<ItemValueT = any>({
  actionsMenu,
  content,
  title,
  headerRightContent,
  notAllowedTitle,
  onLineClick,
  isAllowedBtn,
  onActionMenuClick,
  onDeleteLine,
  isSelected,
  titleSX,
  horizontalMargin = 2,
  actionsMenuIconSize,
}: ListItemActionCardProps<ItemValueT>) {
  return (
    <Box
      sx={{
        background: isSelected ? colors.grey[100] : colors.white,
        '&:hover': {
          cursor: 'pointer',
          background: colors.grey[100],
        },
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: colors.grey[200],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          letterSpacing: '0',
          ml: horizontalMargin,
          mr: horizontalMargin,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            gap: '8px',
            borderRadius: '8px 8px 0 0',
            justifyContent: 'space-between',
            pt: 2,
          }}
        >
          <Box
            sx={{
              lineHeight: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '18px',
              flex: '1',
              ...titleSX,
            }}
            onClick={onLineClick}
          >
            <ClampedText text={title} typographyVariant="body-medium" />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                textWrap: 'no-wrap',
              }}
            >
              {headerRightContent}
            </Box>
            <Box sx={{ marginLeft: 1 }}>
              <ActionsMenu
                actions={actionsMenu}
                onClick={(action) => {
                  if (action.value === 'Delete') {
                    onDeleteLine();
                  } else {
                    onActionMenuClick(action.value as ItemValueT);
                  }
                }}
                iconTooltip={!isAllowedBtn ? notAllowedTitle : undefined}
                iconSize={actionsMenuIconSize}
              />
            </Box>
          </Box>
        </Box>

        {content && (
          <Box
            onClick={onLineClick}
            sx={{
              width: '100%',
              pb: 2,
            }}
          >
            <Typography variant="body">{content}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ListItemActionCard;
