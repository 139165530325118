import { SelectChangeEvent, MenuItem, Box } from '@mui/material';
import SweepSelect from '../SweepSelect';
import usePermission from '../permissions/usePermission';
import { Typography, colors } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { RoleGroupInterface } from '@server/role-group-interface';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';

interface RolesDropdownProps {
  userId?: string;
  userRole?: RoleGroupInterface;
  accountRoles: RoleGroupInterface[];
  onChangeRoleCb: (userId: string, roleId: string) => void;
  onUserRemove?: (userId: string) => void;
  disableRoleDropdown?: boolean;
}

export const RolesDropdown = ({
  userId,
  userRole,
  accountRoles,
  onChangeRoleCb,
  disableRoleDropdown,
  onUserRemove,
}: RolesDropdownProps) => {
  const [isAllowedBtn, isAllowedBtnDelete] = usePermission(['edit:users:roles', 'delete:users']);
  const [_userRole, setUserRole] = useState(userRole);

  if ((disableRoleDropdown || !isAllowedBtn) && userRole) {
    return (
      <Box sx={{ paddingRight: 1 }}>
        <Typography ellipsis variant="body">
          {userRole?.name}
        </Typography>
      </Box>
    );
  }

  return (
    <SweepSelect
      removeBorders
      SelectProps={{
        renderValue: () => <>{_userRole?.name ?? ''}</>,
        value: _userRole?.id ?? '',
        onChange: (event: SelectChangeEvent<unknown>) => {
          event.preventDefault();
          const roleId = event.target.value as string;

          if (roleId === 'remove_user' && userId) {
            onUserRemove && onUserRemove(userId);
          } else {
            const role = accountRoles.find((role) => role.id === roleId);
            role && onChangeRoleCb(userId || '', roleId);
            setUserRole(role);
          }
        },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
        sx: {
          maxHeight: '440px',
          overflow: 'hidden',
          '& .MuiSelect-select': {
            paddingRight: 3,
          },
        },
      }}
    >
      <Box maxHeight="380px" overflow="auto">
        {accountRoles.map((role) => (
          <MenuItem
            key={role.id}
            value={role.id}
            sx={{
              width: '299px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', minWidth: 0 }}>
              <Typography variant="body">{role.name}</Typography>
              <TruncatedTextTooltip variant="caption" color={colors.grey[700]}>
                {role.description}
              </TruncatedTextTooltip>
            </Box>
          </MenuItem>
        ))}
      </Box>

      {isAllowedBtnDelete && onUserRemove && userId && (
        <MenuItem value="remove_user" sx={{ background: colors.white, color: colors.blush[500] }}>
          Remove
        </MenuItem>
      )}
    </SweepSelect>
  );
};
