import get from 'lodash/get';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import toLower from 'lodash/toLower';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { AutomationTypes, ScheduledParametersOffsetType } from '../../types/enums/AutomationTypes';
import { NestedSelectorPath } from '../common/NestedSelector/NestedSelector';
import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationType } from '../../types/enums/AutomationType';
import { SweepSortOption } from '../common/SweepSort';
import { AddDedupMatchingButton } from './AddDedupMatchingButton';
import { AddAutomationButton, AddAutomationButtonProps } from './AddAutomationButton';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import { uniqueId } from '../../lib/uniqueId';
import { DedupMergeActionType } from '../routing/dedupMatching/helpers';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { humanizeDate, humanizeDateVariants } from '../helpers/humanizeDate';
import { DateTime } from 'luxon';
import { SweepFieldOperator } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { ACTIONS_EVENTS } from '../../services/events';

import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

enum Constant {
  exact = 'exact',
}

export const validateEmails = (emailsArr: string[]) => {
  const regexExp = /\S+@\S+\.\S+/;
  const isValid = emailsArr.every((x) => regexExp.test(x));
  return isValid;
};

export const getNestedPath = (
  _fieldIds: string | string[] | undefined,
  _fieldLabels: string[] | undefined,
): NestedSelectorPath => {
  if (!_fieldIds || _fieldIds.length === 0 || !_fieldLabels || _fieldLabels.length === 0) {
    return [];
  }
  if (!isArray(_fieldIds)) {
    _fieldIds = [_fieldIds];
  }
  return _fieldIds.map((fieldId, idx) => ({
    label: _fieldLabels[idx + 1] || '',
    value: fieldId,
  }));
};

export const parseTime = (time: string) => {
  const timeInt = parseInt(time);
  const minutes = time.substring(3, 5);
  if (time > '12:00') {
    return `${timeInt === 12 ? timeInt : timeInt - 12}:${minutes} pm`;
  } else {
    return `${timeInt}:${minutes} am`;
  }
};

export const getDisplayLineTexts = (automation: AutomationStructureNew) => {
  let triggerText = '',
    actionText: ReactNode[] = [];
  const { stageEnter, stageExit, fieldUpdate, dateArrive, timeInStep, schedule } =
    automation.automationDetails.automationParams || {};
  switch (automation.automationDetails?.triggerType) {
    case AutomationTypes.StageEnter:
      triggerText = 'When entering ' + stageEnter?.toStage?.stageName;
      break;
    case AutomationTypes.StageExit:
      triggerText = `When exiting ${stageExit?.fromStage?.stageName} and entering ${stageExit?.toStage?.stageName}`;
      break;
    case AutomationTypes.FieldUpdate:
      triggerText =
        'When ' + automation.objectName + '.' + fieldUpdate?._leadingFieldLabels[1] + ' is updated';
      break;
    case AutomationTypes.DateArrive:
      const { offsetValue, offsetType, relativeTime, sourceField } = dateArrive || {};

      if (relativeTime === Constant.exact) {
        triggerText = `When ${automation.objectName}.${sourceField?._leadingFieldLabels[1]} arrives`;
      } else {
        triggerText = `${offsetValue} ${offsetType} ${relativeTime} ${sourceField?._leadingFieldLabels[1]} arrives`;
      }
      break;
    case AutomationTypes.TimeInStep:
      const { offsetValue: _offsetValue, offsetType: _offsetType, stage } = timeInStep || {};
      triggerText = `When ${stage?.stageName} is in the same step for ${_offsetValue} ${_offsetType}`;
      break;
    case AutomationTypes.OnCreate:
      triggerText = 'When a record is created';
      break;
    case AutomationTypes.OnUpdate:
      triggerText = 'When a record is updated';
      break;
    case AutomationTypes.OnUpsert:
      triggerText = 'When a record is created or updated';
      break;
  }
  if (automation.type === AutomationType.Dedupe || automation.type === AutomationType.Matching) {
    triggerText = '';
  }
  if (
    automation.type === AutomationType.ScheduledAssignment ||
    automation.type === AutomationType.ScheduledReport ||
    automation.automationDetails?.triggerType === AutomationTypes.Scheduled
  ) {
    const offsetRecurring = automation.automationDetails.automationParams.schedule?.offset;
    const startDate = humanizeDate({
      dateOrTimestamp: schedule?.startDate ?? '',
      variant: humanizeDateVariants.SHORT_WITHOUT_TIME,
    });
    const startOn = 'starting on ' + startDate;
    const startTime = parseTime(schedule?.executeTime ?? '');
    if (offsetRecurring) {
      const frequent = offsetRecurring?.offsetValue;
      const isOne = frequent === 1;
      const dateObject = new Date(schedule?.startDate ?? '');
      switch (offsetRecurring?.offsetType) {
        case ScheduledParametersOffsetType.DAY:
          triggerText = isOne
            ? `Daily at ${startTime}, ${startOn}`
            : `Every ${frequent} days at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.WEEK:
          const weekDays = (offsetRecurring as ScheduleWeekOffset)?.weekDays.map(
            (el) => upperFirst(toLower(el)) + ' ',
          );
          triggerText = isOne
            ? `Weekly on ${weekDays} at ${startTime}, ${startOn}`
            : `Every ${frequent} weeks on ${weekDays} at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.MONTH:
          triggerText = isOne
            ? `Monthly on day ${dateObject.getDate()} at ${startTime}, ${startOn}`
            : `Every ${frequent} months on day ${dateObject.getDate()} at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.YEAR:
          triggerText = isOne
            ? `Yearly on ${dateObject.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
              })} at ${startTime}, ${startOn}`
            : `Every ${frequent} years on ${dateObject.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
              })} at ${startTime}, ${startOn}`;
          break;
      }
    } else {
      triggerText = `On ${startDate} at ${startTime}`;
    }
  }
  actionText = automation.automationDetails.actions.map((action) => {
    switch (action.actionType) {
      case AutomationActionType.SendEmail:
        return 'Send an email notification';
      case AutomationActionType.CreateRecord:
        return `Create a new ${action?.actionParams?.objectTypeName?.label}`;
      case AutomationActionType.UpdateRecords:
        return 'Update record';
      case AutomationActionType.ConvertLead:
        return 'Convert Lead';
      case AutomationActionType.Slack:
        return 'Post to Slack';
      case AutomationActionType.Assignment:
        return 'Assign record';
      case AutomationActionType.AssignmentRules:
        return (
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="body">Run assignment rules:</Typography>
            {action.actionParams.assignmentRules.map((rule, i) => (
              <Typography variant="body" key={rule.name + i}>
                {i + 1}. {rule.name}
              </Typography>
            ))}
          </Box>
        );
      case AutomationActionType.SlackDealRoom:
        return 'Create Slack deal room';
      case AutomationActionType.SlackAddMember:
        return 'Add members to deal room';
      case AutomationActionType.SendReportToSlack:
        return (
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="body">Send a Salesforce report:</Typography>
            <Typography variant="body">{action.actionParams.reportName}</Typography>
          </Box>
        );
      case AutomationActionType.VerifyURL:
        return 'Verify URL';
      case AutomationActionType.SlackRenameDealroom:
        return 'Rename deal room';
      case AutomationActionType.SlackArchiveDealroom:
        return 'Archive deal room';
      default:
        return '';
    }
  });
  if (automation.type === AutomationType.Dedupe) {
    const getText = () => {
      const propToCheck = (
        automation?.automationDetails?.actions?.[0] as DeduplicationAutomationAction
      )?.actionParams?.mergeActionType;
      switch (propToCheck) {
        case DedupMergeActionType.OVERRIDE_NOTHING:
          return ['Match leads and merge manually'];
        case DedupMergeActionType.OVERRIDE_NEW_VALUES:
          return ['Enrich existing lead and merge'];
        case DedupMergeActionType.OVERRIDE_ALL:
          return ['Override existing lead and merge'];
        case DedupMergeActionType.OVERRIDE_BY_CONVERT:
          return ['Convert lead into the duplicate contact'];
        default:
          return ['Match leads and merge manually'];
      }
    };
    actionText = getText();
  }
  if (automation.type === AutomationType.Matching) {
    const getAssignAction = () => {
      return (
        automation?.automationDetails?.actions?.[0] as MatchingAutomationAction
      )?.actionParams?.followUpActions?.[0]?.actions?.find(
        (el) => el.actionType === AutomationActionType.Assignment,
      ) as AssignmentAutomationAction;
    };
    const assignAction = getAssignAction();
    if (!assignAction) {
      actionText = ['Only match'];
    } else if (assignAction?.actionParams?.when) {
      actionText = ['Match and assign (if conditions are met)'];
    } else {
      actionText = ['Match and assign'];
    }
  }
  if (automation.type === AutomationType.ScheduledAssignment) {
    actionText = ['Assign records from Salesforce report'];
  }

  return { triggerText, actionText };
};

export type AutomationSortOption = SweepSortOption & {
  dataAccessor: string;
  getManualValue?: (automation: AutomationStructureNew) => string;
};

export const isAutomationsInStage = (
  automationDetails: AutomationDetailsDtoNew,
  searchValue: string,
  propToUse: '_stageId' | 'stageName',
) => {
  const isNotFieldUpdate = automationDetails.triggerType !== AutomationTypes.FieldUpdate;
  const isToStage =
    automationDetails.automationParams.stageEnter?.toStage?.[propToUse] === searchValue ||
    automationDetails.automationParams.stageExit?.toStage?.[propToUse] === searchValue;

  const isFromStage =
    automationDetails.automationParams.stageExit?.fromStage?.[propToUse] === searchValue;

  const isInStepFor =
    automationDetails.automationParams.timeInStep?.stage?.[propToUse] === searchValue;

  return isNotFieldUpdate && (isToStage || isFromStage || isInStepFor);
};

const ANY_STEP_NAME = 'Any step';
const ANY_STEP_ID = 'ANY_STEP';

export const getStepsFromAutomation = (
  automationDetails: AutomationDetailsDtoNew,
  propToUse: '_stageId' | 'stageName',
): string[] => {
  if (automationDetails.triggerType === AutomationTypes.FieldUpdate) {
    return [];
  }

  const steps = [
    automationDetails.automationParams.stageEnter?.toStage?.[propToUse],
    automationDetails.automationParams.stageExit?.toStage?.[propToUse],
    automationDetails.automationParams.stageExit?.fromStage?.[propToUse],
    automationDetails.automationParams.timeInStep?.stage?.[propToUse],
  ];

  return steps.filter(
    (nameOrId) => !!nameOrId && nameOrId !== ANY_STEP_NAME && nameOrId !== ANY_STEP_ID,
  ) as string[];
};

export const getRecordTypeLabelsFromAutomation = (automation?: AutomationStructureNew) => {
  if (!automation) {
    return [];
  }

  const funnelRecordTypeLabel = automation.funnelRecordType?.label ?? '';
  const rtLabels = automation.automationDetails?.recordTypes?.map((rt) => rt.label) ?? [];

  return [funnelRecordTypeLabel, ...rtLabels].filter((rtName) => rtName !== '');
};

export const getRecordTypeNamesFromAutomation = (automation?: AutomationStructureNew) => {
  if (!automation) {
    return [];
  }

  const funnelRecordTypeName = automation.funnelRecordType?.name ?? '';
  const rtNames = automation.automationDetails?.recordTypes?.map((rt) => rt.name) ?? [];

  return [funnelRecordTypeName, ...rtNames].filter((rtName) => rtName !== '');
};

export const getRecordTypeIdentifier = (recordTypeName: string, objectName: string) =>
  `${objectName}.${recordTypeName}`;

export const getObjectNameFromRecordTypeId = (id: string) => id.split('.')[0];
export const getRecordTypeNameFromRecordTypeId = (id: string) => id.split('.')[1];

export const getAutomationsForStageId = (
  stageId: string,
  automations?: AutomationStructureNew[],
) => {
  const byStep = automations?.filter((el) => {
    return isAutomationsInStage(el.automationDetails, stageId, '_stageId');
  });
  return byStep ?? [];
};

//According to product, "FunnelAutomation" is : object-level OR record-type level (with this specific RT) OR step-level (steps in this funnel)
export const getFunnelAutomations = ({
  funnelId,
  objectName,
  recordType,
  automations,
}: {
  funnelId: string;
  objectName: string;
  recordType: FunnelRecordType;
  automations?: AutomationStructureNew[];
}) => {
  return automations?.filter((automation) => {
    const isSameObject = automation.objectName === objectName;
    if (!isSameObject) return false;
    const isObjectLevel = isObjectLevelAutomation(automation);
    const isRecordTypeLevel = isRecordTypeLevelAutomation(automation, recordType.name);
    const isStepLevel = isStepLevelAutomation(automation, funnelId);
    return isObjectLevel || isRecordTypeLevel || isStepLevel;
  });
};

export const isObjectLevelAutomation = (automation: AutomationStructureNew) =>
  !automation.automationDetails.funnelId && !automation.automationDetails.recordTypes;

export const isRecordTypeLevelAutomation = (
  automation: AutomationStructureNew,
  recordTypeName: string,
) =>
  !automation.automationDetails.funnelId &&
  automation.automationDetails.recordTypes &&
  automation.automationDetails.recordTypes.map((rt) => rt.name).includes(recordTypeName);

export const isStepLevelAutomation = (
  automation: AutomationStructureNew,
  funnelId: string,
  stepId?: string, //if empty, return all automations for this funnel
) => {
  const isSameFunnel = automation.automationDetails.funnelId === funnelId;
  if (!isSameFunnel) return false;
  const stepsInAutomation = getStepsFromAutomation(automation.automationDetails, '_stageId');
  return stepId ? stepsInAutomation.includes(stepId) : true;
};

export const getAddAutomationButtonByType = ({
  variant,
  props,
}: {
  variant: any;
  props: Omit<AddAutomationButtonProps, 'popoverProps' | 'startIconName'> & {
    isEmptyState: boolean;
  };
}) => {
  const { isEmptyState } = props;
  const popoverProps: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin' | 'slotProps'> = {
    anchorOrigin: {
      vertical: isEmptyState ? 'top' : 'bottom',
      horizontal: isEmptyState ? 'right' : 'left',
    },
    transformOrigin: {
      vertical: isEmptyState ? 'top' : -4,
      horizontal: isEmptyState ? -4 : 'left',
    },
    slotProps: {
      paper: {
        style: {
          width: '277px',
        },
        sx: {
          marginTop: 0,
        },
      },
    },
  };
  if (variant === AutomationFormVariant.DEDUP_MATCHING) {
    return <AddDedupMatchingButton {...props} startIconName={isEmptyState ? '' : 'Plus'} />;
  } else
    return (
      <AddAutomationButton
        {...props}
        popoverProps={popoverProps}
        startIconName={isEmptyState ? '' : 'Plus'}
      />
    );
};

export const getDedupMatchingDefaultAutomation = (
  type: DedupMatchingType,
): Partial<AutomationStructureNew> => {
  switch (type) {
    case DedupMatchingType.LEAD_TO_LEAD_DEDUP:
      return {
        name: 'Lead to Lead Deduplication',
        objectName: 'Lead',
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.LEAD_TO_LEAD_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.CONTACT_TO_CONTACT_DEDUP:
      return {
        name: 'Contact to Contact Deduplication',
        objectName: 'Contact',
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.CONTACT_TO_CONTACT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP:
      return {
        name: 'Account to Account Deduplication',
        objectName: 'Account',
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING:
      return {
        name: 'Lead to Account Matching',
        objectName: 'Lead',
        type: AutomationType.Matching,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnUpsert,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'MATCHING',
              actionParams: {
                useContinuousSync: false,
                fieldMapping: [],
                dmType: DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING,
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.LEAD_TO_CONTACT_DEDUP:
      return {
        name: 'Lead to Contact Deduplication',
        objectName: 'Lead',
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.LEAD_TO_CONTACT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.LEAD_TO_LEAD_MATCHING:
      return {
        name: 'Lead to Lead Matching',
        objectName: 'Lead',
        type: AutomationType.Matching,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnUpsert,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'MATCHING',
              actionParams: {
                useContinuousSync: false,
                fieldMapping: [],
                dmType: DedupMatchingType.LEAD_TO_LEAD_MATCHING,
              },
            },
          ],
          automationParams: {},
        },
      };
  }
};

export const getFunnelMapObjects = (funnelsData: FunnelsData, recordTypesData: RecordTypesData) => {
  const allObjectsUsedInFunnels = Object.values(funnelsData).map((funnel) => {
    return funnel.funnelDetails.leadingObject.objectName;
  });
  const allRecordTypesUsedInFunnels = Object.values(recordTypesData).map((record) => {
    return record.objectName;
  });
  return uniq([...allObjectsUsedInFunnels, ...allRecordTypesUsedInFunnels]).sort();
};

export const getTypeNameFromVariant = (automationVariant: AutomationFormVariant) => {
  switch (automationVariant) {
    case AutomationFormVariant.PLAYBOOK_ALERT:
      return 'alert';
    case AutomationFormVariant.ASSIGNMENT_RULES:
      return 'assignment rule';
    case AutomationFormVariant.DEDUP_MATCHING:
      return 'dedupe matching';
    default:
      return 'automation';
  }
};

export const sortAutomations = ({
  sortBy,
  automations,
  selectedSortOption,
}: {
  sortBy?: string;
  automations: AutomationStructureNew[];
  selectedSortOption: any;
}) => {
  return sortBy
    ? automations.sort((automationA, automationB) => {
        let aValue, bValue;
        if (selectedSortOption?.getManualValue) {
          aValue = selectedSortOption?.getManualValue(automationA);
          bValue = selectedSortOption?.getManualValue(automationB);
        } else {
          aValue = get(automationA, selectedSortOption?.dataAccessor ?? '');
          bValue = get(automationB, selectedSortOption?.dataAccessor ?? '');
        }
        return aValue?.localeCompare(bValue) ?? 1;
      })
    : automations;
};

export const getIsScheduleValid = (schedule?: AutomationSchedule) => {
  const forAll = schedule?.startDate && schedule?.executeTime && schedule?.timezone;
  let isOffset = !!schedule?.offset;
  if (isOffset) {
    isOffset =
      schedule?.offset?.offsetType &&
      !!schedule?.offset?.offsetValue &&
      !!(schedule?.offset?.offsetValue >= 1) &&
      (schedule?.offset?.offsetType === ScheduledParametersOffsetType.WEEK
        ? (schedule?.offset as ScheduleWeekOffset)?.weekDays &&
          (schedule?.offset as ScheduleWeekOffset)?.weekDays?.length > 0
        : true);
    if (schedule?.endDate) {
      isOffset =
        !!schedule?.endDate &&
        DateTime.fromISO(schedule?.startDate) < DateTime.fromISO(schedule?.endDate);
    }
  } else {
    isOffset = true;
  }
  return forAll && isOffset;
};

export const getOperatorsWithoutRecordsComparison = (
  operatorList: {
    label: string;
    operator: SweepFieldOperator;
  }[],
) => {
  const listOfOperatorsToRemove = [
    SweepFieldOperator.HAS_DECREASED,
    SweepFieldOperator.HAS_INCREASED,
    SweepFieldOperator.IS_CHANGED_FALSE,
    SweepFieldOperator.IS_CHANGED_TRUE,
  ];
  return operatorList.filter(({ operator }) => {
    return !listOfOperatorsToRemove.includes(operator);
  });
};

export const AutomationDeploySuccessEventMap = {
  [AutomationType.Default]: ACTIONS_EVENTS.automationsDeploySuccess,
  [AutomationType.Alert]: ACTIONS_EVENTS.alertsDeploySuccess,
  [AutomationType.Dedupe]: ACTIONS_EVENTS.matchingDedupeDeploySuccess,
  [AutomationType.Matching]: ACTIONS_EVENTS.matchingDedupeDeploySuccess,
  [AutomationType.Assignment]: ACTIONS_EVENTS.assignmentsDeploySuccess,
  [AutomationType.ScheduledAssignment]: ACTIONS_EVENTS.scheduledAssignmentsDeploySuccess,
  [AutomationType.ScheduledReport]: ACTIONS_EVENTS.scheduledReportsDeploySuccess,
};

export const AutomationAddEventMap = {
  [AutomationFormVariant.AUTOMATIONS]: ACTIONS_EVENTS.automationsAdd,
  [AutomationFormVariant.PLAYBOOK_ALERT]: ACTIONS_EVENTS.alertsAdd,
  [AutomationFormVariant.DEDUP_MATCHING]: ACTIONS_EVENTS.matchingDedupeAdd,
  [AutomationFormVariant.ASSIGNMENT_RULES]: ACTIONS_EVENTS.assignmentsAdd,
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: ACTIONS_EVENTS.scheduledAssignmentsAdd,
  [AutomationFormVariant.SCHEDULED_REPORTS]: ACTIONS_EVENTS.scheduledReportsAdd,
};

export const AutomationDeleteEventMap = {
  [AutomationFormVariant.AUTOMATIONS]: ACTIONS_EVENTS.automationsDelete,
  [AutomationFormVariant.PLAYBOOK_ALERT]: ACTIONS_EVENTS.alertsDelete,
  [AutomationFormVariant.DEDUP_MATCHING]: ACTIONS_EVENTS.matchingDedupeDelete,
  [AutomationFormVariant.ASSIGNMENT_RULES]: ACTIONS_EVENTS.assignmentsDelete,
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: ACTIONS_EVENTS.scheduledAssignmentsDelete,
  [AutomationFormVariant.SCHEDULED_REPORTS]: ACTIONS_EVENTS.scheduledReportsDelete,
};

export const getPropsForBiEvent = (automationId: string, items?: AutomationStructureNew[]) => {
  const item = items?.find((item) => item.automationId === automationId);
  return item
    ? {
        isDeployed: [DeployStatusForTable.Deployed, DeployStatusForTable.Pending].includes(
          item.status,
        ),
        object: item.objectName,
      }
    : undefined;
};
