import { colors, Typography } from '@sweep-io/sweep-design';
import { DotDivider } from '../../../common/DotDivider';
import { CenteredRowWithGap } from '../atoms/CenteredRowWithGap';
import { ReactNode } from 'react';

export const WrapperWithDotDivider = ({ txt }: { txt: ReactNode }) => (
  <CenteredRowWithGap gap={0.5} ml={-0.5}>
    <DotDivider dotColor={colors.grey[700]} />
    {typeof txt === 'string' && (
      <Typography variant="caption" color={colors.grey[700]} whiteSpace="nowrap">
        {txt}
      </Typography>
    )}
    {typeof txt !== 'string' && txt}
  </CenteredRowWithGap>
);
