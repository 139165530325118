import { Box, FormControl } from '@mui/material';
import {
  NestedFieldsSelector,
  NestedFieldsField,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SlackFields } from './SlackFields';
import { useCallback } from 'react';
import { isEqual } from 'lodash';
import FieldLabel from '../../common/FieldLabel';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackNotConnected } from './SlackNotConnected';
import { SlackRecipientsSelect } from './SlackRecipientsSelect';
import { useSlackRecipients } from './useSlackRecipients';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { useGetSlackRecipientsLabels } from './useGetSlackRecipientsLabels';
import { SlackConstant } from './utils';
import { SlackSelectDealroomType } from './SlackSelectDealroomType';

interface SlackAddMemberActionProps {
  readonly?: boolean;
  action: SlackAddMemberAutomationAction;
  crmOrgId: string;
  onChange: (slackDealRoomAutomationAction: SlackAddMemberAutomationAction) => any;
  objectName: string;
}

export const SlackAddMemberAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SlackAddMemberActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);
  const { recipientWithLabelsArray } = useGetSlackRecipientsLabels({
    recipients: action.actionParams?.members ?? [],
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSlackAddMemberActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const setBeforeCallingFields = useCallback(() => {
    updateOnChange({
      fields: [...(action.actionParams?.fields || [])],
      channelRef: action.actionParams?.channelRef
        ? { ...action.actionParams?.channelRef }
        : {
            type: SlackConstant.Sweep_Created_Slack_Channel,
          },
    });
  }, [action.actionParams?.channelRef, action.actionParams?.fields, updateOnChange]);

  const { isLoading } = useSlackRecipients({
    objectName,
    crmOrgId,
    setBeforeCallingFields,
  });

  const handleDeleteChip = useCallback(
    (chipToRemove: SlackRecipientStruct) => {
      const newRecipients = action.actionParams.members?.filter((item) => {
        if (chipToRemove.type === SlackConstant.Slack) {
          return item.value !== chipToRemove.value;
        } else {
          return !isEqual(
            (item.value as SlackFieldsStruct).fieldIds,
            (chipToRemove.value as SlackFieldsStruct).fieldIds,
          );
        }
      });
      updateOnChange({ members: newRecipients });
    },
    [updateOnChange, action],
  );

  const _onChange = useCallback(
    (event: any, newValues: any) => {
      const newItems = newValues.map((el: any) => {
        if (el.type === SlackConstant.Salesforce) {
          return {
            ...el,
            type: SlackConstant.Salesforce,
            value: {
              fieldIds: el.value.fieldIds,
            },
          };
        } else if (el.type === SlackConstant.Salesforce_Created_Slack_Channel) {
          return {
            ...el,
            type: SlackConstant.Salesforce_Created_Slack_Channel,
            value: {
              fieldIds: el.value.fieldIds,
            },
          };
        } else {
          return {
            ...el,
            type: SlackConstant.Slack,
            value: el.value,
          };
        }
      });
      updateOnChange({ members: newItems as SlackRecipientStruct[] });
    },
    [updateOnChange],
  );

  return (
    <>
      {(!productionOrg || slackStatus !== SlackConnectionStatus.Connected) && (
        <Box sx={{ marginTop: '32px' }}>
          <SlackNotConnected hasProduction={!!productionOrg} slackStatus={slackStatus} />
        </Box>
      )}

      {slackStatus === SlackConnectionStatus.Connected && (
        <Box
          sx={{
            background: colors.grey[100],
            padding: '24px 24px 19px',
            borderRadius: '8px',
            margin: '16px 0',
          }}
        >
          <SlackSelectDealroomType
            readonly={readonly}
            value={action?.actionParams?.channelRef}
            crmOrgId={crmOrgId}
            objectName={objectName}
            onChange={(val) => {
              updateOnChange({ channelRef: val });
            }}
          />

          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
              maxWidth: '1148px',
            }}
          >
            <Box
              sx={{
                marginBottom: '3px',
                '& div': {
                  color: colors.grey[800],
                  fontSize: '12px',
                  fontWeight: '400',
                },
              }}
            >
              <FieldLabel label={'Add members'} markLabelAsRequired={true} />
            </Box>

            <SlackRecipientsSelect
              customButtonText={'Add from Salesforce field'}
              objectName={objectName}
              crmOrgId={crmOrgId}
              isLoading={isLoading}
              onChange={_onChange}
              hideDisclaimer={true}
              readonly={!!readonly}
              recipientsNoChannels
              recipients={recipientWithLabelsArray}
              handleDeleteChip={handleDeleteChip}
            />
          </FormControl>

          <SlackNewMessageEditor
            placeholder="Type message body..."
            isRequired={false}
            label="Message"
            readonly={readonly}
            messageStruct={action.actionParams?.message ?? { template: '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg });
            }}
            objectName={objectName}
          />

          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <FieldLabel label={'Additional fields'} />
            <SlackFields
              readonly={readonly}
              actionFields={
                action?.actionParams?.fields?.map((f) => ({
                  fieldIds: f.fieldIds,
                  fieldData: f,
                })) ?? []
              }
              onChange={(_, fields) => {
                updateOnChange({ fields });
              }}
            />

            <Box>
              <Box>
                <NestedFieldsSelector
                  readonly={readonly}
                  crmOrgId={crmOrgId}
                  objectType={objectName}
                  customButtonStartIcon={<Plus color={colors.blue[500]} />}
                  useCustomButton
                  customButtonText="Add field"
                  nestedPath={[]}
                  customButtonSx={{
                    color: colors.blue[500],
                    border: 'none',
                    textTransform: 'unset',
                    marginTop: '6px',
                    p: '0',
                    '& span': {
                      fontSize: '14px',
                      fontWeight: 500,
                    },
                    '&:hover': {
                      background: 'transparent',
                      color: colors.blue[600],
                    },
                  }}
                  onChange={(sweepField: NestedFieldsField) => {
                    const newItem = {
                      fieldIds: sweepField.fieldIds,
                      _fieldLabels: sweepField.fieldLabels,
                    };
                    const newFields = [
                      ...((action.actionParams?.fields as SlackFieldsStruct[]) || []),
                      newItem,
                    ];
                    updateOnChange({ fields: newFields });
                  }}
                />
              </Box>
            </Box>
          </FormControl>

          <Typography variant="caption-bold">
            Note: this automation would work as expected only if the record has a dedicated deal
            room
          </Typography>
        </Box>
      )}
    </>
  );
};
