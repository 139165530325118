import { Dispatch, SetStateAction, useEffect } from 'react';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { DependenciesSideDialog } from './dependencies/DependenciesSideDialog';
import { ObjectListWrapper } from './object-list/ObjectListWrapper';
import { useDocumentation } from './useDocumentation';
import { SelectedObjectWithList } from './selected-object/list-mode/SelectedObjectWithList';
import { useDependencies } from './dependencies/hooks/useDependencies';
import { HoveredPin, RecordTypesLabelsByNameAndObjectName } from './types';
import { useInitializeObjectsStructure } from './useInitializeObjectsStructure';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { useSelector } from 'react-redux';
import { selectObjects, selectSingleObject } from '../../reducers/documentationReducer';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { ExpandedObject } from './selected-object/expanded-mode/ExpandedObject';
import { useInitializeLayouts } from './useInitializeLayouts';
import { useInitializeUserInputs } from './dependencies/hooks/useInitializeUserInputs';

interface DocumentationDialogProps {
  isOpen: boolean;
  crmOrgId: string;
  funnelsData?: FunnelsData;
  recordTypesData?: RecordTypesData;
  onCloseDialog?: () => void;
  onCrmOrgIdChange?: (nextCrmOrgId: string) => void;
  setHoveredPin?: Dispatch<SetStateAction<HoveredPin | undefined>>;
  stepNamesUsedInCanvas: string[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}

export const DocumentationDialog = ({
  crmOrgId,
  funnelsData = {},
  recordTypesData = {},
  setHoveredPin,
  isOpen,
  onCloseDialog,
  hideAddObjectButton,
  stepNamesUsedInCanvas,
  recordTypeNamesUsedInCanvas,
}: DocumentationDialogProps & { hideAddObjectButton?: boolean }) => {
  const { isLoading, objectTypes } = useObjectTypesWithFetch({ crmOrgId, useSfObjects: true });
  const { isExpandedMode } = useExpandedMode();

  useInitializeObjectsStructure({ funnelsData, recordTypesData, crmOrgId, objectTypes });
  useInitializeLayouts();
  useInitializeUserInputs(crmOrgId);

  const objects = useSelector(selectObjects);
  const isLoadingObjects = isLoading || !objects.favorites?.length;

  const singleObjectName = useSelector(selectSingleObject);

  const { parsedRules, handleClose } = useDocumentation();
  const { configurationItem, dependenciesData, historyLength } = useDependencies(
    crmOrgId,
    recordTypeNamesUsedInCanvas,
  );

  const displayDependencies = !!(configurationItem && dependenciesData?.parentType);
  const displaySingleObject = !!singleObjectName;
  const displayObjectList = !singleObjectName && !isLoadingObjects;

  useEffect(() => {
    return handleClose();
  }, [handleClose]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      {isLoadingObjects && <CenteredCircularProgress />}

      {displayObjectList && (
        <ObjectListWrapper
          crmOrgId={crmOrgId}
          setHoveredPin={setHoveredPin}
          onCloseDialog={onCloseDialog}
          hideAddObjectButton={hideAddObjectButton}
          recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
          withExpandedModeButton
          stepNamesUsedInCanvas={stepNamesUsedInCanvas}
        />
      )}

      {displaySingleObject && !isExpandedMode && (
        <SelectedObjectWithList
          crmOrgId={crmOrgId}
          objectTypeName={singleObjectName}
          parsedRules={parsedRules}
          onCloseDialog={onCloseDialog}
          withExpandedModeButton
          recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
          stepNamesUsedInCanvas={stepNamesUsedInCanvas}
        />
      )}

      {displaySingleObject && isExpandedMode && (
        <ExpandedObject
          crmOrgId={crmOrgId}
          objectTypeName={singleObjectName}
          parsedRules={parsedRules}
          recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
          stepNamesUsedInCanvas={stepNamesUsedInCanvas}
          onCloseDialog={onCloseDialog}
        />
      )}

      {displayDependencies && !isExpandedMode && (
        <DependenciesSideDialog
          crmOrgId={crmOrgId}
          historyLength={historyLength}
          configurationItem={configurationItem}
          type={dependenciesData?.parentType}
          stepNamesUsedInCanvas={stepNamesUsedInCanvas}
          recordTypeNamesUsedInCanvas={recordTypeNamesUsedInCanvas}
        />
      )}
    </>
  );
};
