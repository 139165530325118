import { useFlags } from 'launchdarkly-react-client-sdk';
import useQueryParams from '../../hooks/useQueryParams';

export const useFeatureToggle = () => {
  const query = useQueryParams();

  const debugTools = query.has('debugTools');

  // Please remove feature toggles no longer in use when merging an experiment
  // This list should have all currently used feature toggle flags.
  const {
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sfObjectTypes,
    fieldsInDevopsCenter,
    sweepSupportChat,
    priorValueSlackMessages,
    copilot,
    fieldsPreviewInDevopsCenter,
    searchByLabel,
    aiGpt4OMini,
    verifyUrlAction,
    fieldsChat,
    userRoles,
    unifiedAiEndpoint,
    assignmentsFte,
    storeChannelIdInSfField,
    searchV1,
    searchV2,
    userInputsTags,
    dealRoomActions,
    userInputsComments,
    salesActivatedTrial,
    inactiveForAmarok,
  } = useFlags();

  return {
    debugTools,
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sfObjectTypes,
    sweepSupportChat,
    fieldsInDevopsCenter,
    priorValueSlackMessages,
    copilot,
    searchByLabel,
    fieldsPreviewInDevopsCenter,
    aiGpt4OMini,
    verifyUrlAction,
    fieldsChat,
    userRoles,
    unifiedAiEndpoint,
    assignmentsFte,
    storeChannelIdInSfField,
    searchV1,
    searchV2,
    userInputsTags,
    dealRoomActions,
    userInputsComments,
    salesActivatedTrial, //AKA "paywallV2"
    inactiveForAmarok,
  };
};
