import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  selectDependenciesConfigurationItem,
  selectLayoutsByObjectName,
  selectParsedConfigurationItems,
} from '../../../../reducers/documentationReducer';
import { selectEnvironments } from '../../../../reducers/global/globalReducer';
import { selectCrmOrgRollups } from '../../../../reducers/global/rollupReducers';
import { selectParsedFields } from '../../../../reducers/parserReducer';
import {
  findLayoutIdx,
  findRuleIdx,
  getConfigurationItemWithRTAttribution,
  addLayoutProperties,
  createConfigurationItemFromRollupField,
  findConfigurationItemIdx,
  createConfigurationItemFromRecordType,
  createConfigurationItemFromSweepField,
  createConfigurationItemFromAutomation,
} from '../../helpers';
import { RecordTypesLabelsByNameAndObjectName } from '../../types';
import { useDocumentation } from '../../useDocumentation';

export const useGetConfigurationItem = ({
  crmOrgId,
  recordTypeNamesUsedInCanvas,
}: {
  crmOrgId: string;
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}) => {
  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const { parsedRecordTypes, parsedRules } = useDocumentation();
  const { id, name, objectName } = selectedDependency ?? {};

  const objectLayouts = useSelector(selectLayoutsByObjectName(crmOrgId))?.[objectName ?? ''];
  const parsedConfigurationItems = useSelector(selectParsedConfigurationItems(crmOrgId));
  const parsedFields = useSelector(selectParsedFields);
  const rollups = useSelector(selectCrmOrgRollups(crmOrgId));
  const globalEnvironments = useSelector(selectEnvironments);

  const { automations, alerts, matching, dedup, assignments, scheduledAssignments } =
    globalEnvironments?.[crmOrgId]?.data ?? {};

  const makeNotUndefined = (items?: AutomationStructureNew[]) => items ?? [];
  const mergedAutomations = useMemo(
    () => [
      ...makeNotUndefined(automations),
      ...makeNotUndefined(alerts),
      ...makeNotUndefined(assignments),
      ...makeNotUndefined(matching),
      ...makeNotUndefined(dedup),
      ...makeNotUndefined(scheduledAssignments),
    ],
    [automations, alerts, matching, dedup, assignments, scheduledAssignments],
  );

  if (!id && !name) {
    return;
  }

  const _id = id ?? '';
  const _name = name ?? '';
  const _objectName = objectName ?? '';
  const searchByName = !id;

  const layoutIdx = findLayoutIdx({
    layouts: objectLayouts?.layouts ?? [],
    id: _id,
    name: _name,
    searchByName,
  });

  const layout = objectLayouts?.layouts?.[layoutIdx];

  const ruleIdx = findRuleIdx({
    rules: parsedRules,
    id: _id,
    name: _name,
    searchByName,
    objectName: _objectName,
  });

  if (ruleIdx !== -1) {
    const rule = getConfigurationItemWithRTAttribution(
      parsedRules?.[ruleIdx],
      recordTypeNamesUsedInCanvas,
    );
    return addLayoutProperties(rule, layout);
  }

  const rollup = rollups?.find((el) => el.rollupId === id || el.rollupField.id === id);
  const parsedRollupObjectFields = parsedFields?.[rollup?.objectName ?? ''];

  if (rollup) {
    const fieldUsage = parsedRollupObjectFields?.find((field) => field.name === _name)?.usage;

    return createConfigurationItemFromRollupField(rollup.rollupId, rollup?.rollupField, fieldUsage);
  }

  const parsedConfigItemIdx = findConfigurationItemIdx({
    parsedConfigurationItems,
    id: _id,
    name: _name,
    objectName: _objectName,
    searchByName,
  });

  if (parsedConfigItemIdx !== -1) {
    const config = parsedConfigurationItems?.[parsedConfigItemIdx];
    return addLayoutProperties(config, layout);
  }

  const parsedRecordType = parsedRecordTypes?.find(
    (rt) => rt.name === _name && _objectName === rt.objectApiName,
  );

  if (!!parsedRecordType) {
    return createConfigurationItemFromRecordType(parsedRecordType);
  }

  const parsedObjectFields = parsedFields?.[_objectName ?? ''];
  const parsedFieldIdx = parsedObjectFields?.findIndex((field) => field.id === id) ?? -1;

  if (parsedFieldIdx !== -1) {
    return parsedObjectFields?.[parsedFieldIdx];
  }

  const sweepFields = globalEnvironments?.[crmOrgId]?.data?.sweepFields;
  const fieldFromSweepElement = sweepFields?.[_id];

  if (fieldFromSweepElement) {
    return createConfigurationItemFromSweepField(fieldFromSweepElement);
  }

  return createConfigurationItemFromAutomation(
    mergedAutomations.find((item) => item.automationId === id),
  );
};
