import { Stack, Typography } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { useRoleManagerContext } from './RoleManagerContext';
import { CreateEditDialogHeader } from '../../common/create-edit-dialog/CreateEditDialogHeader';
import { RoleGroupInterface, RoleGroupType } from '@server/role-group-interface';
import { useRoleManager } from './useRoleManager';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useCallback } from 'react';

const createNewRole = () =>
  ({
    id: '',
    name: '',
    description: '',
    roles: {},
  }) as Partial<RoleGroupInterface>;

export const RoleManagerHeader = () => {
  const {
    goBack,
    editRole,
    hasChanges,
    headerText,
    setRoleManager,
    updateRole,
    initializeEditRole,
    isValid,
  } = useRoleManagerContext();
  const { saveRoleGroup, isSavingUser } = useRoleManager();
  const { openConfirm, onCancel, onConfirm } = useConfirm();
  const readonlyMode = editRole?.type === RoleGroupType.SYSTEM;

  const onDialogClose = useCallback(() => {
    goBack();
    onConfirm();
  }, [onConfirm, goBack]);

  return (
    <Stack direction="row" justifyContent="space-between" maxWidth="calc(100% - 8px)">
      {!readonlyMode && editRole && (
        <CreateEditDialogHeader
          value={editRole.name}
          buttonContent="Save role"
          placeholder={'Enter role name...'}
          buttonDisabled={!isValid || isSavingUser}
          onChange={(value) => updateRole('name', value)}
          isButtonLoading={isSavingUser}
          onButtonClick={async () => {
            await saveRoleGroup(editRole);
            goBack();
          }}
          onBackClick={async () => {
            if (!hasChanges && !isSavingUser) {
              goBack();
              return;
            }

            const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);

            if (isConfirmed) {
              onDialogClose();
            } else {
              onCancel();
            }
          }}
        />
      )}

      {(readonlyMode || !editRole) && (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              variant="flat"
              onClick={() => {
                editRole && goBack();
                !editRole && setRoleManager(false);
              }}
            >
              <ArrowLeft />
            </IconButton>

            <Typography variant="h2">{headerText}</Typography>
          </Stack>

          {!editRole && (
            <Button
              startIconName="Plus"
              size="small"
              onClick={() => initializeEditRole(createNewRole() as RoleGroupInterface)}
            >
              Add role
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};
