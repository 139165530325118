import { Permission } from '@server/permissions';
import { GetStartedDto } from '../components/pages/get-started/getStartedTypes';
import { AccountOnboardingStep } from '@server/account-onboarding';
import { AccountType } from '@server/account-type';
import { QuestionnaireDto } from '../components/pages/questionnaire/questionnaireTypes';

export type Emoji = {
  content: string;
  bgColor: string;
};

export enum InviteeStatus {
  InviteeStatusPending = 'PENDING',
  InviteeStatusExpired = 'EXPIRED',
}

export enum Template {
  Funnel = 'FUNNEL',
  Account = 'ACCOUNT',
}

export interface InviteeInfo {
  id?: string;
  invitee: string;
  role: string;
  status: InviteeStatus;
  template: Template;
  message?: string;
  entryPointName?: string;
  entryPointId?: string;
}

export interface UserPreferences {
  getStarted?: GetStartedDto;
  defaultCreationCrmOrgId?: string | null;
  canvasPanelModeExpanded?: boolean;
  questionnaire?: QuestionnaireDto;
}

export interface UserInfo {
  id?: string;
  canResetPassword?: boolean;
  name?: string;
  imageUrl?: string;
  jobTitle?: string;
  email?: string;
  account?: {
    id?: string;
    name?: string;
    authZeroOrgId?: string;
    onboardingStep?: AccountOnboardingStep;
    type?: AccountType;
    trialStartDate?: string;
    trialEndDate?: string;
  };
  role?: string;
  emoji?: Emoji;
  permissions?: Permission[];
  status?: UserStatus;
  preferences?: UserPreferences;
  email_verified?: boolean;
  locale?: string;
}

export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
}

export interface AccountUsersData {
  invitations?: InviteeInfo[];
  users?: UserInfo[];
}
