import { CssBaseline } from '@mui/material';
import { Route, Navigate, createRoutesFromElements } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { ApiProvider } from '../apis/sweep';
import Auth0ProviderWithHistory from '../auth/Auth0ProviderWithHistory';
import { appRoutes, EnvironmentCanvasPagesRoutes, ROUTING_TAB } from '../constants/appRoutes';
import { lazyLoader } from '../lazyLoader';
import { App } from './App';
import { ImpersonationMode } from './ImpersonationMode';
import { Dashboard } from './dashboard/Dashboard';
import Home from './pages/Home';
import { Version } from './pages/Version';
import AppConfigurationCanvas from './pages/configuration-canvas/AppConfigurationCanvas';
import { ConfigurationCanvasRedirectToDefaultCreationEnv } from './pages/configuration-canvas/ConfigurationCanvasRedirectToDefaultCreationEnv';
import { ConfigurationPages } from './pages/configuration-canvas/configurationPages';
import { RuleBuilderDemoPage } from './pages/demos/RuleBuilderDemoPage';
import { HubspotOAuth2Redirect } from './pages/environments/HubspotOAuth2Redirect';
import { SalesforceOAuth2Redirect } from './pages/environments/SalesforceOAuth2Redirect';
import { SlackOAuth2Redirect } from './pages/environments/SlackOAuth2Redirect';
import { UnitedDashboard } from './pages/canvas-pages/united-canvas/UnitedConfigurationCanvas';
import { PanelsProvider } from './panels/PanelsContext';
import noop from 'lodash/noop';
import { ReadOnlyUnitedDashboard } from './pages/canvas-pages/united-canvas/read-only/ReadOnlyUnitedConfigurationCanvas';

interface RouteRendererProps<T = any> {
  Comp: React.FC<T>;
  withDashboard: boolean;
  propsForComp?: T;
}

function RouteRenderer<T = any>({ Comp, withDashboard, propsForComp }: RouteRendererProps<T>) {
  const comp = Comp(propsForComp as T);

  return (
    <>
      <CssBaseline />
      <ImpersonationMode />
      {withDashboard ? (
        <PanelsProvider confirmOpen={noop}>
          <Dashboard>{comp}</Dashboard>
        </PanelsProvider>
      ) : (
        comp
      )}
    </>
  );
}

const AppAuth0AndApiProvidersWrapper = () => {
  return (
    <Auth0ProviderWithHistory>
      <ApiProvider>
        <App />
      </ApiProvider>
    </Auth0ProviderWithHistory>
  );
};

const routes = (
  //official guidelines don't cover integration between auth0 and createBrowserRouter
  //I used solution mentioned in issue linked below
  //https://github.com/remix-run/react-router/discussions/9321
  <Route element={<AppAuth0AndApiProvidersWrapper />}>
    <Route path={appRoutes.salesforce} element={<SalesforceOAuth2Redirect />} />
    <Route path={appRoutes.hubspotRedirect} element={<HubspotOAuth2Redirect />} />
    <Route path={appRoutes.slack} element={<SlackOAuth2Redirect />} />
    <Route path={appRoutes.home} element={<Home />}>
      <Route
        path={appRoutes.getStarted}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/get-started/GetStartedEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.funnelMapsPage}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/funnel-maps/FunnelMapsPage'))}
          />
        }
      />
      <Route
        path={`${appRoutes.devopsCenter}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/devops-center/DevOpsCenterPage'))}
          />
        }
      />
      <Route
        path={appRoutes.reports}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/reports/ReportsPage'))}
          />
        }
      />
      <Route
        path={appRoutes.sfChangeFeed}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/sf-change-feed/ChangeFeedPage'))}
          />
        }
      />
      <Route
        path={appRoutes.rollups}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/rollups/RollupsPage'))}
          />
        }
      />
      <Route
        path={`${appRoutes.rollups}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/rollups/RollupsPage'))}
          />
        }
      />
      <Route
        path={appRoutes.aiAssistant}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={lazyLoader(() => import('./pages/ai-assistant/AiAssistantPage'))}
          />
        }
      />

      {/* Demos */}
      <Route
        path={appRoutes.demoRuleBuilder}
        element={<RouteRenderer withDashboard={true} Comp={RuleBuilderDemoPage} />}
      />
      <Route
        path={appRoutes.demoComponents}
        element={
          <RouteRenderer withDashboard={true} Comp={lazyLoader(() => import('./pages/demos'))} />
        }
      />
      <Route path={appRoutes.version} element={<Version />} />
      <Route
        path={`${appRoutes.automations}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Automations,
              route: EnvironmentCanvasPagesRoutes.automations,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.automations}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.canvasUnited}/:funnelMapId`}
        element={<RouteRenderer Comp={UnitedDashboard} withDashboard={false} />}
      />
      <Route
        path={`${appRoutes.canvasUnitedSnapshot}/:funnelId`}
        element={<RouteRenderer Comp={ReadOnlyUnitedDashboard} withDashboard={false} />}
      />
      <Route
        path={`${appRoutes.documentation}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Documentation,
              showOnlyDeployedElementsInPills: true,
              route: EnvironmentCanvasPagesRoutes.documentation,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.documentation}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.routing}/:crmOrgId/${ROUTING_TAB}/:routingTab`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Routing,
              route: EnvironmentCanvasPagesRoutes.routing,
            }}
          />
        }
      />
      <Route
        path={`${appRoutes.routing}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Routing,
              route: EnvironmentCanvasPagesRoutes.routing,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.routing}/${ROUTING_TAB}/:routingTab`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={`${appRoutes.routing}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />

      <Route
        path={`${appRoutes.alerts}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Alerts,
              route: EnvironmentCanvasPagesRoutes.alerts,
            }}
          />
        }
      />

      <Route
        path={`${appRoutes.alerts}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route
        path={appRoutes.freeTierOnBoarding}
        element={
          <RouteRenderer
            withDashboard={false}
            Comp={lazyLoader(() => import('./pages/onboarding/SignupEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.questionnaire}
        element={
          <RouteRenderer
            withDashboard={false}
            Comp={lazyLoader(() => import('./pages/questionnaire/QuestionnaireEntryPoint'))}
          />
        }
      />
      <Route
        path={appRoutes.hubspot}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.Hubspot,
              route: EnvironmentCanvasPagesRoutes.hubspot,
              forceProduction: true,
            }}
          />
        }
      />
      <Route
        path={`${appRoutes.dedup}/:crmOrgId`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={AppConfigurationCanvas}
            propsForComp={{
              page: ConfigurationPages.DedupAndMatching,
              route: EnvironmentCanvasPagesRoutes.dedup,
            }}
          />
        }
      />
      <Route
        path={`${appRoutes.dedup}`}
        element={
          <RouteRenderer
            withDashboard={true}
            Comp={ConfigurationCanvasRedirectToDefaultCreationEnv}
          />
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));
