import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useEffect, useMemo } from 'react';
import { AutomationCanvasFilter } from '../pages/canvas-pages/canvasAutomationsTypes';
import {
  CanvasFilterTypes,
  SweepCanvasFiltersMap,
} from '../pages/configuration-canvas-filters/filterTypes';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { AutomationAssignmentsContextProvider } from './AutomationAssignmentsContextProvider';
import { useShouldRequestToDeployFilter } from '../common/deploy-button/useShouldRequestToDeploy';

const assignmentsSortOptions = [
  {
    label: 'Name',
    value: 'ASSIGNMENT_NAME',
    dataAccessor: 'name',
  },

  {
    label: 'Object',
    value: 'OBJECT',
    dataAccessor: 'objectName',
  },
];

export const assignmentRuleFilters: SweepCanvasFiltersMap = {
  [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
  [AutomationCanvasFilter.RECORD_TYPES]: {
    type: CanvasFilterTypes.RECORD_TYPE,
    position: 1,
    objectsFilter: {
      type: 'reference',
      filterRef: AutomationCanvasFilter.OBJECTS,
    },
  },
  [AutomationCanvasFilter.STEPS]: {
    type: CanvasFilterTypes.STEP,
    recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
    position: 2,
  },
};

export type AssignmentsProps = {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  externalFilter?: (assignment: AutomationStructureNew) => boolean;
  disableCanvasTemplates?: boolean;
  selectedFilters: AutomationDialogFilters;
  setSelectedFilters: (filters: Partial<AutomationDialogFilters>) => void;
  setHoveredItem?: (automationId?: string | undefined) => void;
  goToRoutingHome: () => void;
};

const useGetAssignmentFilters = (automations: AutomationStructureNew[] = []) => {
  const requestToDeployFilter = useShouldRequestToDeployFilter(automations, 3);
  const filters = useMemo(() => {
    let assignmentRuleFilters: SweepCanvasFiltersMap = {
      [AutomationCanvasFilter.OBJECTS]: { type: CanvasFilterTypes.OBJECT, position: 0 },
      [AutomationCanvasFilter.RECORD_TYPES]: {
        type: CanvasFilterTypes.RECORD_TYPE,
        position: 1,
        objectsFilter: {
          type: 'reference',
          filterRef: AutomationCanvasFilter.OBJECTS,
        },
      },
      [AutomationCanvasFilter.STEPS]: {
        type: CanvasFilterTypes.STEP,
        recordTypeFilterRef: AutomationCanvasFilter.RECORD_TYPES,
        position: 2,
      },
    };
    if (requestToDeployFilter) {
      assignmentRuleFilters = {
        ...assignmentRuleFilters,
        ...requestToDeployFilter,
      };
    }
    return assignmentRuleFilters;
  }, [requestToDeployFilter]);
  return filters;
};

const Assignments = ({
  recordTypesData,
  funnelsData,
  externalFilter,
  disableCanvasTemplates,
  selectedFilters,
  setSelectedFilters,
  setHoveredItem,
  goToRoutingHome,
}: AssignmentsProps) => {
  const { assignmentsFte } = useFeatureToggle();
  const assignmentRules = useSelector(selectAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.ASSIGNMENT_RULES });

  const assignmentsToDisplay = externalFilter
    ? assignmentRules?.filter(externalFilter)
    : assignmentRules;

  const filters = useGetAssignmentFilters();

  useEffect(() => {
    if (assignmentsFte && assignmentRules?.length === 0) {
      goToRoutingHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRules, assignmentsFte]);

  if (!assignmentRules) return <CenteredCircularProgress />;

  return (
    <>
      <Box pb={2} flex={1}>
        {crmOrg && (
          <AutomationDialogContent
            automations={assignmentsToDisplay ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={assignmentsSortOptions}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
          />
        )}
      </Box>
      <ConfigurationCanvasFilters filters={filters} automations={assignmentRules} />
    </>
  );
};

const AssignmentsWithContext = (assignmentProps: AssignmentsProps) => {
  return (
    <AutomationAssignmentsContextProvider>
      <Assignments {...assignmentProps} />
    </AutomationAssignmentsContextProvider>
  );
};

export default AssignmentsWithContext;
