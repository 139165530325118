import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import {
  RuleBuilderData,
  RuleBuilderRowComponentProps,
} from '../../../../common/rule-builder/rule-builder-types';
import { RuleBuilder } from '../../../../common/rule-builder/RuleBuilder';
import { HubspotFieldSelector } from './HubspotFieldSelector';
import { RuleBuilderSelectionRow } from '../../../../common/rule-builder/RuleBuilderSelectionRow';

import { uniqueId } from '../../../../../lib/uniqueId';
import { HubspotFieldsOperatorSelector } from './HubspotFieldsOperatorSelector';
import { HubspotFieldsValueSelector } from './HubspotFieldValueSelector';
import { HsFilterOperatorsOperators, HsPropertyTypes } from '../hubspot.types';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import { validateSweepCriteria } from '../../../../common/rule-builder/validateSweepCriteria';
import { telemetry } from '../../../../../telemetry';

import { useSelector } from 'react-redux';
import { selectHubspotFieldByName } from '../../../../../reducers/hubspotReducer';
import { hsValueToSweepConditionValue } from './utils';

interface RuleBuilderHubspotConditionRowData {
  id: string;
  criterion: DeepPartial<SweepCriterion>;
}

function RuleBuilderHubspotConditionRow({
  data,
  index,
  onRowChange,
  onRowDelete,
  readOnly,
}: RuleBuilderRowComponentProps<RuleBuilderHubspotConditionRowData>) {
  const { criterion } = data;

  const [_hsPropertyType] = criterion.fieldType ? criterion.fieldType.split(':') : [undefined];
  const hsPropertyType = _hsPropertyType as HsPropertyTypes | undefined;

  const hsFieldName = criterion?._fieldIds?.length
    ? criterion._fieldIds[0] 
    : undefined;

  const options = useSelector(selectHubspotFieldByName(hsFieldName || ''))?.options || [];

  const hubspotFieldSelector = (
    <HubspotFieldSelector
      onChange={(hsField) => {
        if (!hsField.data?.type) {
          telemetry.captureError(new Error('No type found for field'), { hsField });
          return;
        }
        const newData: RuleBuilderHubspotConditionRowData = {
          ...data,
          criterion: {
            ...criterion,
            _fieldIds: [hsField.value],
            fieldType: hsField.data.type,
            operator: '',
            value: '',
          },
        };
        onRowChange(newData);
      }}
      value={hsFieldName}
      disabled={readOnly}
    />
  );

  const operatorsSelector = hsPropertyType ? (
    <HubspotFieldsOperatorSelector
      hsPropertyType={hsPropertyType as HsPropertyTypes}
      operator={criterion.operator as HsFilterOperatorsOperators}
      disabled={readOnly}
      onChange={(operator) => {
        onRowChange({
          ...data,
          criterion: {
            ...criterion,
            operator,
            value: '',
          },
        });
      }}
    />
  ) : null;

  const valueSelector =
    criterion.operator && hsFieldName && hsPropertyType ? (
      <HubspotFieldsValueSelector
        hsPropertyType={hsPropertyType}
        value={criterion.value}
        disabled={readOnly}
        onChange={(value) => {
          onRowChange({
            ...data,
            criterion: {
              ...criterion,
              value: hsValueToSweepConditionValue(value),
            },
          });
        }}
        operator={criterion.operator as HsFilterOperatorsOperators}
        options={options}
      />
    ) : null;

  return (
    <RuleBuilderSelectionRow
      lineNumber={index + 1}
      firstComponent={hubspotFieldSelector}
      secondComponent={operatorsSelector}
      thirdComponent={valueSelector}
      showDelete
      onDelete={onRowDelete}
      readonly={readOnly}
    />
  );
}

interface HubspotRuleBuilderProps {
  sweepCondition?: DeepPartial<SweepCondition>;
  onChange: (newSweepCondition: DeepPartial<SweepCondition>) => any;
  readOnly?: boolean;
  headerRowComponent?: JSX.Element | string;
}

export type HubspotRuleBuilderRef = {
  triggerValidation: () => string[];
};

export const HubspotRuleBuilder = forwardRef<HubspotRuleBuilderRef, HubspotRuleBuilderProps>(
  ({ readOnly, headerRowComponent, sweepCondition, onChange }, ref) => {
    const ruleBuilderData: RuleBuilderData<RuleBuilderHubspotConditionRowData> = useMemo(() => {
      const entries: RuleBuilderHubspotConditionRowData[] = (sweepCondition?.criteria || []).map(
        (criterion) => {
          const id = criterion?.criterionId || uniqueId();
          return {
            id,
            criterion: criterion || {
              criterionId: id,
            },
          };
        },
      );
      return {
        entries,
        logicString: sweepCondition?.criteriaLogic || '',
      };
    }, [sweepCondition?.criteria, sweepCondition?.criteriaLogic]);

    const newRowProvider = useCallback(() => {
      const newId = uniqueId();
      const data: RuleBuilderHubspotConditionRowData = {
        id: newId,
        criterion: {
          criterionId: newId,
          valueType: SelectorValueTypes.LITERAL,
        },
      };
      return data;
    }, []);

    const [errorIds, setErrorIds] = useState<string[]>([]);
    const [displayErrors, setDisplayErrors] = useState(false);

    const triggerValidation = useCallback(() => {
      const criteria = ruleBuilderData.entries.map((c) => c.criterion);
      const _errorIds = validateSweepCriteria(
        criteria,
        /*excludeFirstEntryFromValidationIfEmpty*/ true,
        true,
      );
      setErrorIds(_errorIds);
      if (_errorIds.length) {
        setDisplayErrors(true);
      }
      return _errorIds;
    }, [ruleBuilderData.entries]);

    useImperativeHandle(
      ref,
      () => ({
        triggerValidation,
      }),
      [triggerValidation],
    );

    useEffect(() => {
      triggerValidation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <RuleBuilder
        readonly={readOnly}
        onChange={(data) => {
          if (data.entries.length === 0) {
            onChange({});
            return;
          }
          const newSweepCondition: DeepPartial<SweepCondition> = {
            criteriaLogic: data.logicString,
            criteria: data.entries.length
              ? data.entries.map((entry) => entry.criterion)
              : undefined,
          };

          onChange(newSweepCondition);
        }}
        newRowProvider={newRowProvider}
        ruleBuilderData={ruleBuilderData}
        RowComponent={RuleBuilderHubspotConditionRow}
        errorIds={errorIds}
        displayErrors={displayErrors}
        headerRowComponent={headerRowComponent}
      />
    );
  },
);
