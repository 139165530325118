import { Box, FormLabel, TextField } from '@mui/material';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { RolesDropdown_deprecated } from './RolesDropdown_deprecated';
import { SweepRoles } from '../../../hooks/useUserInfo';
import { Button, Typography } from '@sweep-io/sweep-design';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { validateEmails } from '../../Automations/helper';
import { telemetry } from '../../../telemetry';
import { useFeatureToggle } from '../useFeatureToggle';
import { RolesDropdown } from './RolesDropdown';
import { RoleGroupInterface } from '@server/role-group-interface';

export const inviteMessages = (email: string) => ({
  [SweepNotificationVariant.Success]: `You have invited ${email} to work with you in Sweep`,
  [SweepNotificationVariant.Error]: `Invite to Sweep failed, please try again`,
});

export interface MessageResponse {
  [SweepNotificationVariant.Success]: string;
  [SweepNotificationVariant.Error]: string;
}

interface InviteUserInputProps {
  onSendInvitationEmail: (
    email: string,
    role: string,
    message: string,
  ) => Promise<AxiosResponse<any, any>>;
  afterSendActions: (isError: boolean, messageResponse: MessageResponse) => Promise<void>;
  accountRoles: SweepRoles[];
  roles: RoleGroupInterface[];
  isAccountConnected?: boolean;
  messagePlaceholder?: string;
  disableRoleDropdown?: boolean;
}

export const InviteUserInput = ({
  onSendInvitationEmail,
  afterSendActions,
  accountRoles,
  roles,
  isAccountConnected,
  messagePlaceholder = `E.g. Hi, let's work together on Sweep 🌟`,
  disableRoleDropdown,
}: InviteUserInputProps) => {
  const { userRoles } = useFeatureToggle();
  const [isAllowedBtn] = usePermission(['create:users:invite']);
  const [selectedRole, setSelectedRole] = useState<RoleGroupInterface>();
  const [deprecated_selectedRole, setDeprecatedSelectedRole] = useState<SweepRoles>(
    SweepRoles.Viewer,
  );
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isInvitationSending, setIsInvitationSending] = useState(false);

  const clearState = () => {
    setEmail('');
    setMessage('');
    setIsInvitationSending(false);
  };

  const sendInvitationEmail = async (): Promise<any> => {
    setIsInvitationSending(true);
    try {
      await onSendInvitationEmail(email, deprecated_selectedRole, message);
      afterSendActions(false, inviteMessages(email));
      clearState();
    } catch (error) {
      telemetry.captureError(error);
      afterSendActions(true, inviteMessages(email));
      setIsInvitationSending(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginBottom: '16px',
          gap: '16px',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          '& .MuiInputBase-adornedEnd': {
            width: '100%',
          },
          '& .MuiInputBase-adornedEnd input.MuiInputBase-inputAdornedEnd': {
            flex: '1 0',
          },
        }}
      >
        <Box sx={{ flex: '1 0' }}>
          <TextField
            disabled={!isAllowedBtn}
            sx={{ width: '100%' }}
            placeholder={'Enter email'}
            value={email}
            onChange={(event) => {
              const newValue = event?.target.value;
              setEmail(newValue ?? '');
            }}
            InputProps={{
              endAdornment: (
                <>
                  {userRoles ? (
                    <RolesDropdown
                      onChangeRoleCb={(id, roleId) => {
                        const role = roles.find((_role) => _role.id === roleId);
                        setSelectedRole(role);
                      }}
                      accountRoles={roles}
                      disableRoleDropdown={!isAllowedBtn || disableRoleDropdown}
                      userRole={selectedRole}
                    />
                  ) : (
                    <RolesDropdown_deprecated
                      accountRoles={accountRoles}
                      userRole={deprecated_selectedRole || ''}
                      disableRoleDropdown={!isAllowedBtn}
                      onChangeRoleCb={(id, role) => {
                        setDeprecatedSelectedRole(role);
                      }}
                    />
                  )}
                </>
              ),
            }}
          />
        </Box>

        {isAccountConnected && (
          <RestrictedTooltip
            to={['create:users:invite']}
            notAllowedTitle={'To invite members to your workspace, please contact your admin.'}
          >
            <Button
              disabled={!validateEmails([email])}
              onClick={sendInvitationEmail}
              loading={isInvitationSending}
            >
              Invite
            </Button>
          </RestrictedTooltip>
        )}
      </Box>

      {!!email.length && (
        <Box sx={{ flex: '1 0' }}>
          <FormLabel>
            <Typography variant="caption">Message</Typography>
          </FormLabel>
          <TextField
            multiline
            rows={4}
            disabled={!isAllowedBtn}
            sx={{ width: '100%', height: '133px' }}
            placeholder={messagePlaceholder}
            value={message}
            onChange={(event) => {
              const newValue = event?.target.value;
              setMessage(newValue ?? '');
            }}
          />
        </Box>
      )}
    </>
  );
};
